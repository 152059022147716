<script>
export default {
  name: "LCHIThread",
  props: {
    showAside: {
      type: Boolean,
      default: true,
    },
    showInput: {
      type: Boolean,
      default: true,
    }
  },
}
</script>

<template>
  <div class="
      LCHIThread layout
      w-full
      flex flex-col
      k1:flex-row k1:gap-8
      ">
    <div class="
      flex-grow
      flex flex-col items-center justify-center
    ">
      <div class="
        h-full
        w-full
        max-w-[1100px]
        flex flex-col
      ">
        <div class="w-full">
          <slot name="status"></slot>
        </div>
        <div
            class="
            messages
            overflow-visible
            flex-grow
            flex flex-col gap-2
        ">
          <slot>No items yet.</slot>
        </div>
        <div
            v-if="showInput"
            class="flex flex-col justify-end"
        >
          <div class="w-full py-6">
            <slot name="input"></slot>
          </div>
        </div>
      </div>
    </div>

    <div
        v-if="showAside"
        class="
            toolCalls
            flex-grow
        "
    >
      <div class="
          fixed
          flex flex-col gap-4
      ">
        <slot name="aside"></slot>
      </div>
    </div>

  </div>
</template>
