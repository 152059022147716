import Axios from "axios";
import {getBaseUrl} from "@/store/lib/apiUrls";
import {configureRequests} from "@/plugins/axios/configureRequests";
import {configureResponses} from "@/plugins/axios/configureResponses";

Axios.defaults.withCredentials = true

const axios = Axios.create({
    baseURL: getBaseUrl(),
    withCredentials: true,
    headers: {'X-Requested-With': 'XmlHttpRequest'},
})
configureRequests(axios)
configureResponses(axios)

export default axios

