<script>
import LView from "@/components/layout/LView.vue";
import IconHome from "../../icons/Home.vue";
import '../../../showcase.css'
import Pipe from "@/components/pipe/Pipe.vue";
import IconCheck from "@/components/icons/Check.vue";

const isInDevelopment = process.env.NODE_ENV === 'development'

let debug = {
  pipe: false
}

debug = debug && isInDevelopment

const states = {
  initial: 'Connecting...',
  streaming: 'Streaming',
  error: 'Error',
  disconnected: 'Disconnected',
  done: 'Done',
  exit: 'Exit'
}

export default {
  name: "LHome",
  components: {
    IconCheck,
    Pipe,
    IconHome,
    LView,
  },

}
</script>

<template>
  <div class="lHome showcase">
    <LView>
      <template #default>
        <div class="flex-grow flex flex-col h-full w-full ">
          <router-view></router-view>
        </div>
      </template>
    </LView>
  </div>
</template>
