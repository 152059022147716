<script>
export default {
  name: "RecordsWelcome"
}
</script>

<template>
<div class="RecordsWelcome">
welocme
</div>
</template>

