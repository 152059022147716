<script>
import {computed} from "vue";
import {useSendAssistantStreamMessage} from "../../composables/useSendAssistantStreamMessage";
import CHIStream from "@/components/CHIThread/CHIStream/CHIStream.vue";

/*
        1) spawn assistant run
        2) render system message 'Assistant is ready'
        3) handle stream of data from assistant
          - render pipe message
          - render stream operation message
          - render text message
          - render action stream (Log.vue)
            - render socketError
            - render pipeError
            - render spawnError
            - render programError
            - render logLine

        4) render action library
          - render action library
            - render action plugin
              - render action settings
     */

const states = {
  initial: '',
  streaming: 'Tunneling',
  error: 'Error',
  disconnected: 'Disconnected',
  done: 'Done',
  exit: 'Exit'
}

const debug = true

export default {
  name: "AssistantRunStream",
  emits: [
    'error',
    'exit',
    'stream',
    'thread',
    'socket_state',
    'pipe_state',
    'spawn_state',
  ],
  components: {
    CHIStream,
  },
  props: {
    pipeName: {
      type: String,
      required: true,
    },
    _assistantId: {
      type: String,
      required: true,
    },
    thread_id: {
      type: String,
    },
    logLevel: {
      type: String,
      default: 'error',
      validator: (value) => ['error', 'info'].includes(value),
    },
  },
  setup(props, {emit}) {

    const onError = (err) => {
      console.warn('AssistantRunStream error', err)
      emit('error', err)
    }
    // UI states
    const logLevel = computed(() => {
      let logLevel = props.logLevel
      if (logLevel === 'info' && !debug) logLevel = 'error'
      return logLevel
    })

    // initialization
    const {
      pipeName,
      listenerName,
    } = useSendAssistantStreamMessage({
      pipeName: props.pipeName,
      _assistantId: props._assistantId,
      onError
    })

    return {
      listenerName,
      pipeName,
      logLevel,
    }
  }
}
</script>

<template>
  <div class="assistantRunStream w-full">
    <CHIStream
        :pipeName="pipeName"
        :listener-name="listenerName"
        :staticParams="{_assistantId}"
    >
    </CHIStream>
  </div>
</template>

