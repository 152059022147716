import {capitalize} from "@/lib/typeHelpers";
import * as docOperations from "@/store/operations";

const docTypes = [
    'Free',
    'Shared',
    'Owned',
    'System'
]

const normalizeDocType = type => {
    if (!type) throw new Error('Document type is required')
    type = capitalize(type.toLowerCase())
    if (!docTypes.includes(type)) throw new Error(`Unsupported document type ${docType}. Cannot normalize document operations`)
    return type
}

export const normalizeDocumentOperationKeys = (operations, docType) => {
    docType = normalizeDocType(docType)
    const DOCTYPE = docType.toUpperCase()
    if (operations.list) operations[`list${docType}Documents`] = operations.list ?? docOperations[`LIST_${DOCTYPE}_DOCUMENTS`]
    if (operations.get) operations[`get${docType}Document`] = operations.get ?? docOperations[`LOAD_${DOCTYPE}_DOCUMENT`]
    if (operations.create) operations[`create${docType}Document`] = operations.create ?? docOperations[`CREATE_${DOCTYPE}_DOCUMENT`]
    if (operations.update) operations[`update${docType}Document`] = operations.update ?? docOperations[`PATCH_${DOCTYPE}_DOCUMENT`]
    if (operations.publish) operations[`publish${docType}Document`] = operations.publish ?? docOperations[`PUBLISH_${DOCTYPE}_DOCUMENT`]
    if (operations.retract) operations[`retract${docType}Document`] = operations.retract ?? docOperations[`RETRACT_${DOCTYPE}_DOCUMENT`]
    if (operations.delete) operations[`delete${docType}Document`] = operations.delete ?? docOperations[`DELETE_${DOCTYPE}_DOCUMENT`]

    delete operations.list
    delete operations.get
    delete operations.create
    delete operations.update
    delete operations.publish
    delete operations.retract
    delete operations.delete
}