import {useUserPermissions} from "@/composables/useUserPermissions";
import {OPEN_PIPE_WITH_MESSAGE} from "../operations";
import {useStore} from "vuex";
import {computed, isRef, ref} from "vue";
import {generateRandomString} from "@/lib/typeHelpers/stringFunctions/generateRandomString";
import {getApiUrl} from "@/store/lib/apiUrls";
import {withPrefix} from "@/lib/prefixPostfix";

const streamBaseUrl = `${getApiUrl()}/spawn/assistantRunStream`

export const useSendAssistantStreamMessage = ({
                                                  _assistantId,
                                                  roomName,
                                                  onError,
                                              }) => {
    if (!_assistantId) throw new Error('_assistantId is required.')
    roomName = roomName ? withPrefix(roomName, '_') : ''

    const store = useStore()
    const {user, account, fullName} = useUserPermissions()

    const pipeName = `AssistantRunStream_${_assistantId}${roomName}`
    const listenerName = computed(() =>
        user?.value?._id ||
        'anonymous_' + generateRandomString(16, 'alphanumeric') // todo: use persistent session id
    )

    onError = onError || function (error) {
        console.error('Send Stream Message Error', error)
        throw error
    }

    const isSending = ref(false)

    const getMessageAuthor = () => {
        if (fullName?.value?.length) return fullName.value
        return 'Anonymous'
    }
    const getUserMessageNameTag = () => {
        const {_id = 'anonymous'} = account?.value ?? {}
        const name = getMessageAuthor()
        return [_id, name].join('_')
    }
    const getThreadPipeUrl = (thread_id) => {
        let id = isRef(thread_id) ? thread_id.value : thread_id
        let url = `${streamBaseUrl}/Assistant/${_assistantId}`
        if (id) url += `/${id}`
        return url
    }

    // actions
    const sendMessage = ({
                             thread_id,
                             content,
                             // files
                         }) => {
        if (!content) return
        if (isSending.value) return
        if (!thread_id) throw new Error('thread_id is required.')

        const params = {
            content,
            metadata: {
                name: getUserMessageNameTag(),
            }
        }

        isSending.value = true
        return store.dispatch(OPEN_PIPE_WITH_MESSAGE, {
            url: getThreadPipeUrl(thread_id),
            pipeName: pipeName,
            listenerName: listenerName.value,
            params,
        })
            .catch(err => {
                console.error('Assistant Run Pipe Error', err)
                onError(err)
            })
            .finally(() => {
                isSending.value = false
            })
    }
    return {
        pipeName,
        listenerName,

        isSending,

        getThreadPipeUrl,
        getUserMessageNameTag,
        sendMessage,
    }
}