<script>
import IconInformationBoxOutline from "@/components/icons/InformationBoxOutline.vue";
import AlertStatus from "@/components/AlertStatus.vue";
import {computed} from "vue";
import {capitalize} from "@/lib/typeHelpers";

export default {
  name: "StreamStatus",
  components: {
    AlertStatus,
    IconInformationBoxOutline,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const text = computed(() =>
        capitalize(props.type.split('.').join(' '))
    )
    return {
      text
    }
  }
}
</script>

<template>
  <div class="streamStatus bg-yellow-100">
    <AlertStatus
        name="Spawn"
        :message="text"
    >
      <template #icon>
        <IconInformationBoxOutline></IconInformationBoxOutline>
      </template>
    </AlertStatus>
  </div>
</template>

