import {reactive} from 'vue'
import {theme} from '../../../tailwind.config'
import {isInBrowser} from "../../composables/useInBrowser";

export const widths = theme.screens

const nextBreakpoint = current => Object.keys(widths)[Object.keys(widths).findIndex(current) + 1] ?? Infinity

const h4 = val => widths.h4 <= val && val < nextBreakpoint(h4)
const h6 = val => widths.h6 <= val && val < nextBreakpoint(h6)
const h8 = val => widths.h6 <= val && val < nextBreakpoint(h8)
const k1 = val => widths.k1 <= val && val < nextBreakpoint(k1)
const k11 = val => widths.k11 <= val && val < nextBreakpoint(k11)
const k12 = val => widths.k12 <= val && val < nextBreakpoint(k12)
const k14 = val => widths.k14 <= val && val < nextBreakpoint(k14)
const k15 = val => widths.k15 <= val && val < nextBreakpoint(k15)
const k16 = val => widths.k16 <= val && val < nextBreakpoint(k16)
const k18 = val => widths.k18 <= val && val < nextBreakpoint(k18)
const k2 = val => widths.k2 <= val && val < nextBreakpoint(k2)
const k22 = val => widths.k22 <= val && val < nextBreakpoint(k22)
const k24 = val => widths.k24 <= val && val < nextBreakpoint(k24)
const k25 = val => widths.k25 <= val && val < nextBreakpoint(k25)
const k26 = val => widths.k26 <= val && val < nextBreakpoint(k26)
const k28 = val => widths.k28 <= val && val < nextBreakpoint(k28)
const k3 = val => widths.k3 <= val && val < nextBreakpoint(k3)
const k32 = val => widths.k32 <= val && val < nextBreakpoint(k32)
const k34 = val => widths.k34 <= val && val < nextBreakpoint(k34)
const k35 = val => widths.k35 <= val && val < nextBreakpoint(k35)
const k36 = val => widths.k36 <= val && val < nextBreakpoint(k36)
const k38 = val => widths.k38 <= val && val < nextBreakpoint(k38)
const k4 = val => widths.k4 <= val && val < nextBreakpoint(k4)
const k42 = val => widths.k42 <= val && val < nextBreakpoint(k42)
const k44 = val => widths.k44 <= val && val < nextBreakpoint(k44)

export const availableBreakpoints = [
    'h4',
    'h6',
    'h8',
    'k1',
    'k11',
    'k12',
    'k14',
    'k15',
    'k16',
    'k18',
    'k2',
    'k22',
    'k24',
    'k25',
    'k26',
    'k28',
    'k3',
    'k32',
    'k34',
    'k35',
    'k36',
    'k38',
    'k4',
    'k42',
    'k44'
]

const getBreakpoint = w => {
  if (h4(w)) return 'h4'
  else if (h6(w)) return 'h6'
  else if (h8(w)) return 'h8'
  else if (k1(w)) return 'k1'
  else if (k11(w)) return 'k11'
  else if (k12(w)) return 'k12'
  else if (k14(w)) return 'k14'
  else if (k15(w)) return 'k15'
  else if (k16(w)) return 'k16'
  else if (k18(w)) return 'k18'
  else if (k2(w)) return 'k2'
  else if (k22(w)) return 'k22'
  else if (k24(w)) return 'k24'
  else if (k25(w)) return 'k25'
  else if (k26(w)) return 'k26'
  else if (k28(w)) return 'k28'
  else if (k3(w)) return 'k3'
  else if (k32(w)) return 'k32'
  else if (k34(w)) return 'k34'
  else if (k35(w)) return 'k35'
  else if (k36(w)) return 'k36'
  else if (k38(w)) return 'k38'
  else if (k4(w)) return 'k4'
  else if (k42(w)) return 'k42'
  else if (k44(w)) return 'k44'
  else return ''
}
const debounce = function(func, wait) { // todo: extract
  let timeout
  return () => {
    const later = function() {
      timeout = null
    }
    const callNow = !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func()
  }
}
let breakpoints = reactive({})
const init = () => {
  if (!isInBrowser) return
  breakpoints = reactive({
    w: window.innerWidth,
    h: window.innerHeight,
    is: getBreakpoint(window.innerWidth)
  })

  window.addEventListener(
    'resize',
    debounce(() => {
      breakpoints.w = window.innerWidth
      breakpoints.h = window.innerHeight
      breakpoints.is = getBreakpoint(window.innerWidth)
    }, 200),
    false
  )
}
init()

export default breakpoints
