<script>
import SocketError from "@/components/pipe/spawn/log/SocketError.vue";
import SocketStatus from "@/components/pipe/spawn/log/SocketStatus.vue";
import Run from "./OpenAIThreadRun.vue";
import Step from "./OpenAIThreadRunStep.vue";
import Message from "./OpenAIThreadMessage.vue";

import {computed, ref} from "vue";
import PipeStatus from "@/components/pipe/spawn/log/PipeStatus.vue";
import PipeError from "@/components/pipe/spawn/log/PipeError.vue";
import SpawnStatus from "@/components/pipe/spawn/log/SpawnStatus.vue";
import {isStatusVariable} from "@/store/lib/assets";
import SpawnError from "@/components/pipe/spawn/log/SpawnError.vue";
import StreamStatus from "./StreamStatus.vue";
import ToolResult from "./ToolResult.vue";

export default {
  name: "StreamItem",
  methods: {isStatusVariable},
  components: {
    SocketStatus,
    SocketError,
    StreamStatus,
    SpawnStatus,
    SpawnError,
    PipeStatus,
    PipeError,
    Run,
    Step,
    ToolResult,
    Message,
  },
  props: {
    staticParams: {
      type: Object,
    },
    type: {
      type: String,
      required: true,
    },
    logLevel: {
      type: String,
      default: 'debug',
      validator: (value) => ['error', 'info', 'debug'].includes(value),
    },
    data: {
      type: Object,
      required: false,
    },
    item: {
      type: Object,
      required: false,
    },
    showToolOutput: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isError = computed(() => props.type?.toLowerCase() === 'error')
    const isComponentEvent = computed(() => !!props.data?._dataType)
    const logLevel = computed(() => props.logLevel)

    const attributes = ref({
      ...props.staticParams,
      ...props.data
    })
    const component = computed(() => {
      let component = ''
      const spawnStatusEvents = [
        'thread.run.created',
        'thread.run.queued',
        'thread.run.cancelled',
        'thread.run.in_progress',
        'thread.run.requires_action',
        'thread.run.step.queued',
        'chi.message.created',
        'chi.thread.created',
      ]

      if (isError.value) {
        component = 'SpawnError'
      } else if (isComponentEvent.value) {
        component = props.data?._dataType
        attributes.value = {
          ...props.staticParams,
          ...props.data,
        }
      } else { // infrastructure events
        const type = props.type || props.data.object
        // console.log(555, props.type, 'data', {...props.data}, 'attributes', {...attributes.value})
        if (type === 'socket') component = 'socketStatus'
        else if (type === 'socket.error') component = 'socketError'
        else if (type === 'pipe') component = 'pipeStatus'
        else if (type === 'pipe.error') component = 'pipeError'
        else if (type === 'spawn') component = 'spawnStatus'
        else if (type === 'spawn.error') component = 'spawnError'
        else if (type === 'spawn.exit') component = 'spawnError'
        else if (type === 'thread.run.step.in_progress') component = 'Step'
        else if (spawnStatusEvents.includes(props.type)) component = 'StreamStatus'
        else if (type.endsWith('.error')) {
          const {name, message} = props.data.data
          attributes.value = {
            ...attributes.value,
            name,
            message,
          }
          component = 'spawnError'
        }
        else if (type === 'tool.result') {
          component = ''
          if (props.showToolOutput) component = 'ToolResult'
        } else {
          console.warn(283, 'UNSUPPORTED STREAM ITEM', props.type, {...props.data})
        }
      }
      return component
    })
    const showItem = computed(() => {
      const canBeShown = !!component.value
      const showStatusEvents = logLevel.value === 'info'
      const isStatusEvent = component.value?.endsWith('Status') ?? false

      return canBeShown && (!isStatusEvent ||
          (
              isStatusEvent && showStatusEvents
          )
      )
    })
    return {
      attributes,
      component,
      showItem,
    }
  }
}
</script>

<template>
  <div
      class="streamItem w-full"
      style="{margin-bottom: -2px}"
  >
    <component
        v-if="showItem"
        :is="component"
        :type="type"
        v-bind="attributes"
        :logLevel="logLevel"
    ></component>
    <div v-else-if="logLevel === 'debug'">
      {{ type }} {{attributes}}
    </div>
  </div>
</template>

