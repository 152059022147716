<script>
import TextInput from "@/components/forms/TextInput.vue";
import Button from "@/components/forms/Button.vue";
import LSection from "@/components/layout/LSection.vue";
import {computed, reactive, ref, watchPostEffect} from "vue";
import {ENTER_SESSION_KEY} from "@/store/operations";
import {useStore} from "vuex";
import StatusMessage from "@/components/status/StatusMessage.vue";
import {useRouter} from "vue-router";
import {useStatusMessage} from "@/composables/useStatusMessage";
import {repopulateObject} from "@/lib/typeHelpers/objectFunctions/repopulateObject";

export default {
  name: "EnterSessionToken",
  components: {
    Button,
    LSection,
    StatusMessage,
    TextInput,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const {setMessage, deleteMessage} = useStatusMessage(ENTER_SESSION_KEY);

    const login = ref('')

    const isSent = ref(false);
    const inputError = reactive({
      key: "",
    });

    const isValid = computed(
        () =>
            !isSent.value &&
            Object.values(inputError)
                .every((msg) => msg === "")
    );

    watchPostEffect(() => {
      const {key} = login;

      inputError.key =
          key !== null && !key
              ? "A valid session key is required"
              : "";
    });

    deleteMessage();

    const onKeyChange = (value) => {
      login.key = value.trim()
      isSent.value = false
    }

    const onSubmit = () => {
      repopulateObject(inputError, {})
      deleteMessage();
      const {key} = login;

      inputError.key = !!key ? "" : "A valid key is required";
      if (!isValid.value) return

      isSent.value = true;
      store
          .dispatch(ENTER_SESSION_KEY, key)
          .then(({data}) => {
            const {Error, User, _sessionKey} = data;
            isSent.value = false;
            if (Error) {
              setMessage({
                isError: true,
                body: Error.message,
              });
            }
            if (User) {
              store.state.sessionKey = _sessionKey
              router.push({query: {}})
            }
          })
          .finally(() => {
            isSent.value = false;
          })

      Object.keys(login).forEach((key) => delete login[key]);
    };
    const onCancel = () => router.push('/')

    return {
      isValid,
      login,
      inputError,

      onKeyChange,
      onCancel,
      onSubmit,
    };
  },
};
</script>

<template>
  <div class="enterSessionToken">
    <LSection :noMargin="true">
      <form @submit.prevent="onSubmit" ref="form" class="form">
        <h1 class="heading1">
          Enter Session Key
          <hr class="gradientLine"/>
        </h1>
        <div class="flex flex-wrap">
          <div class="w-full mb-6">
            <label for="email">Key</label>
            <TextInput
                :tabIndex="1"
                name="key"
                placeholder="Session Key"
                required
                @change="onKeyChange"
                @input="onKeyChange"
            ></TextInput>
          </div>
        </div>
        <div class="flex flex-column gap-4">
          <StatusMessage operation="LOGIN"></StatusMessage>
          <div class="w-full flex justify-between">
            <Button
                class="button secondary"
                :tabindex="3"
                type="submit"
                @click="onCancel"
            >
              <template #right>Back</template>
            </Button>
            <Button
                :is-disabled="!isValid"
                :is-primary="isValid"
                @click="onSubmit"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </LSection>
  </div>
</template>

<style scoped></style>
