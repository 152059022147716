import {inject} from "vue";

export const useTimeTexts = (timeFormat) => {
    const dayjs = inject('DAYJS')
    const time = (timestamp) => {
        if (timestamp.toString().length === 10) return dayjs(timestamp *= 1000)
        return dayjs.unix(timestamp)
    }
    const getTimestampText = (timestamp) => {
        return time(timestamp).format(timeFormat)
    }

    const getTimespanText = (at, since) => {
        at = time(at)
        const parts = [
            at.format(timeFormat),
        ]
        const spanLength = at.diff(time(since))
        if (spanLength >= 60000) {
            parts.push(at.from(time(since), true))
        }

        return parts.join(' after ')
    }

    return {
        getTimestampText,
        getTimespanText,
    }
}