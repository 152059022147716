import Chats from "./components/views/Chats.vue";

export default [
    {
        path: '/custom',
        name: 'homeCHIAssistants',
        component: () => import('./components/views/HomeCHIAssistants.vue'),
    },
    {
        path: '/actions',
        name: 'actions',
        component: () => import('./components/views/Action/Actions.vue'),
    },
    {
        path: '/actions/:_actionId',
        name: 'action',
        component: () => import('./components/views/Action/Action.vue'),
        props: true,
    },
    {
        path: '/assistant/new',
        name: 'assistant',
        component: () => import('./components/views/CreateAssistant.vue'),
        props: true,
        meta: {
            backNavigateTo: 'homeCHIAssistants',
        },
    },
    {
        path: '/assistant/:_assistantId',
        name: 'assistant',
        component: () => import('./components/views/Assistant.vue'),
        props: true,
    },
    {
        path: '/assistant/:_assistantId/thread',
        name: 'chats',
        component: Chats,
        props: true,
    },
    {
        path: '/assistant/:_assistantId/thread/:thread_id',
        name: 'chat',
        component: () => import('./components/views/AssistantStream.vue'),
        props: true,
    },
    // workshop
    // {
    //     path: '/workshop',
    //     name: 'workshops',
    //     component: () => import('./components/views/Workshop/Workshops.vue'),
    // },
    // {
    //     path: '/workshop/:_workshopId/assistant/:_assistantId/thread/:thread_id',
    //     name: 'workshopChat',
    //     component: () => import('./components/views/Workshop/WorkshopAssistant.vue'),
    //     props: true,
    // },
    // teaching
    // {
    //     path: '/teaching',
    //     name: 'teachingLanding',
    //     component: () => import('./components/views/Teaching/TeachingAssistantsLanding.vue'),
    // },
    // {
    //     path: '/teaching/:_assistantId',
    //     name: 'teachingAssistant',
    //     props: true,
    //     component: () => import('./components/views/Teaching/TeachingAssistant.vue'),
    // },
    // {
    //     path: '/teaching/:_assistantId/ad',
    //     name: 'teachingAssistantAd',
    //     component: () => import('./components/views/Teaching/TeachingAssistantAd.vue'),
    //     props: true,
    // },
    // {
    //     path: '/teaching/:_assistantId/chat',
    //     name: 'createTeachingAssistantChat',
    //     props: true,
    //     component: () => import('./components/views/Teaching/CreateTeachingAssistantThread.vue'),
    //     meta: {
    //         backNavigateTo: 'teachingLanding'
    //     },
    // },
    // {
    //     path: '/teaching/:_assistantId/chat/:thread_id',
    //     name: 'teachingAssistantChat',
    //     props: true,
    //     component: () => import('./components/views/Teaching/TeachingAssistantStream.vue'),
    // },
    // quantum HR assistant
    {
        name: '/quantumHRAssistant',
        path: '/quantumHRAssistant',
        component: () => import('./components/views/QuantumHRAssistant/QuantumHRAssistant.vue'),
    },
    {
        name: 'batchImportFiles',
        path: '/batchImport/:fileNamePrefix',
        /*
            imports all existing files as OpenAI files, prepends fileNamePrefix
         */
        component: () => import('./components/views/BatchImportAssistantFiles.vue'),
        props: true
    }
]
