<script>
import {useSharedDocument} from "@/composables/document/shared/useSharedDocument";
import {computed} from "vue";
import IconPageNextOutline from "@/components/icons/PageNextOutline.vue";
import IconPaperclip from "@/components/icons/Paperclip.vue";
import IconSigma from "@/components/icons/Sigma.vue";
import IconCash from "@/components/icons/Cash.vue";

export default {
  name: "TokenUsage",
  components: {
    IconPaperclip,
    IconPageNextOutline,
    IconSigma,
    IconCash,
  },
  props: {
    _dataType: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    costPromptToken: {
      type: Number,
      default: 0.07
    },
    costCompletionToken: {
      type: Number,
      default: 0.21
    },
    showBoth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {thread} = useSharedDocument({
      alias: 'thread',
      filter: {
        _dataType: props._dataType,
        _id: props.id,
      },
    })
    const promptTokens = computed(() => thread.value?.usage?.prompt_tokens ?? 0)
    const completionTokens = computed(() => thread.value?.usage?.completion_tokens ?? 0)
    const totalTokens = computed(() => thread.value?.usage?.total_tokens ?? 0)

    const badgeClasses = [
      'bg-red-600',
      'text-white',
      'px-2 py-0',
      'rounded-full',
    ]
    const totalPrice = computed(() => {
      let cost = ''
      if (props.costPromptToken && props.costCompletionToken) {

        const costPromptCents = props.costPromptToken * promptTokens.value / 10 // per 1000 tokens to cents
        const costCompletionCents = props.costCompletionToken * completionTokens.value / 10 // per 1000 tokens to cents
        cost = Math.round(costPromptCents + costCompletionCents)
        cost = '' + cost / 100
      }
      return cost
    })

    const completionTokensTitle = computed(() => `${props._dataType}: ${completionTokens.value} completion tokens used`)
    const promptTokensTitle = computed(() => `${props._dataType}: ${promptTokens.value} prompt tokens used`)
    const totalTokensTitle = computed(() => `${props._dataType}: ${totalTokens.value} tokens used (${promptTokens.value} + ${completionTokens.value})`)
    const costTitle = computed(() => `${props._dataType}: total cost: $${totalPrice.value}`)

    return {
      badgeClasses,

      completionTokensTitle,
      costTitle,
      promptTokensTitle,
      totalTokensTitle,

      promptTokens,
      completionTokens,
      totalTokens,
      totalPrice,
    }
  }
}
</script>

<template>
  <div class="
      threadTokenUsage
      px-1
      flex items-center gap-1
  ">
    <div
        v-if="showBoth"
        class="flex items-center gap-1"
    >
      <IconPaperclip :title="promptTokensTitle"></IconPaperclip>
      <IconPageNextOutline :title="completionTokensTitle"></IconPageNextOutline>
    </div>
    <div
        v-else
        class="flex items-center"
        :title="totalTokensTitle"
    >
      <IconSigma :title="totalTokensTitle"></IconSigma>
    </div>
    <div
        v-if="totalPrice"
        class="flex items-center"
        :title="costTitle"
    >
      <IconCash :title="costTitle"></IconCash>
    </div>
  </div>
</template>

<style scoped>

</style>