<script>
import {computed} from "vue";

export default {
  name: "ProgressBar",
  props: {
    value: {
      type: Number,
      default: 0,
      validator: value => 0 <= value && value <= 100
    }
  },
  setup(props) {
    const valueStyle = computed(() => ({
          width: `${props.value}%`
        })
    )
    return {
      valueStyle
    }
  }
}
</script>

<template>
  <div class="progressBar w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
    <div
        class="bg-blue-600 h-2.5 rounded-full"
        :style="valueStyle"
    ></div>
  </div>
</template>

<style scoped>

</style>
