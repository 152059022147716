<script>
import ListAssistants from "../Assistant/ListAssistants.vue";
import appId from "../../appId";
import {useUserPermissions} from "@/composables/useUserPermissions";
import Button from "@/components/forms/Button.vue";

export default {
  name: "Home",
  components: {
    Button,
    ListAssistants,
  },


  data() {
    const cards = [
      {
        title: 'Custom Assistants',
        route: 'homeCHIAssistants',
      },
      {
        title: 'Teaching Chatbots',
        route: 'teachingLanding',
      },
      {
        title: 'Workshops',
        route: 'workshops',
      },
    ]
    const {isLoggedIn} = useUserPermissions()

    return {
      appId,
      isLoggedIn,
      cards,
    }
  }
}
</script>

<template>
  <div class="home view w-full flex justify-center bg-gradient-to-tl from-white via-white to-blue-900/20">
    <div class="py-4 w-[380px] k1:w-[800px] px-2 min-h-screen ">
      <h1 class="heading1 k1:!text-6xl text-4xl text-center py-12 pb-20">
        Chi Assistants
      </h1>

      <div class=" items-center flex">
        <div class="grid k1:grid-cols-3 grid-cols-1 gap-8 justify-between w-full">
          <div v-for="card in cards"
               class="cursor-pointer bg-slate-50 text-center rounded-2xl shadow-black/20 shadow-md w-full h-full px-3 py-4">
            <p class="font-extrabold text-xl pt-6">
              {{ card.title }}
            </p>
            <p class="py-6 w-full justify-center flex">
              <img src="../../assets/chatAvatar.png" alt="" class="w-24 bg-slate-50 h-24">
              <!--              Maybe an image here-->
            </p>
            <button
                class="blackButton float-right w-full !rounded-full"
                @click="$router.push({name: card.route})"
            >
              View
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style>

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: slategray;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0F172A;
}

</style>