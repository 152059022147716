<script>
import StreamItemState from "./StreamItemState.vue";
import TokenUsage from "./TokenUsage.vue";
import {useSharedDocument} from "@/composables/document/shared/useSharedDocument";
import {ref, watchEffect} from "vue";

export default {
  name: "StreamItemStatusBar",
  components: {
    TokenUsage,
    StreamItemState
  },
  emits: ['cancel'],
  props: {
    _dataType: {
      type: String,
      required: true,
    },
    id: {
      type: String,
    },
  },
  setup(props, {emit}) {
    const id = ref(props.id)
    const item = ref(null)

    watchEffect(() => {
      if (id.value) {
        item.value = useSharedDocument({
          alias: 'item',
          filter: {
            _dataType: props._dataType,
            _id: props.id,
          },
        }).item
      }
    })

    const onCancel = () =>
        emit('cancel', {
          _dataType: props._dataType,
          id: props.id
        })

    return {
      item,
      onCancel
    }
  }
}
</script>

<template>
  <div class="StreamItemStatusBar">
    <div class="flex items-center">
      <StreamItemState :itemState="item"></StreamItemState>
      <TokenUsage
          v-if="id"
          :_dataType="_dataType"
          :id="id"
      ></TokenUsage>
    </div>
  </div>
</template>

