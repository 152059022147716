<script>
import IconExport from "@/components/icons/Export.vue";
import {computed} from "vue";
import {capitalize} from "@/lib/typeHelpers";
import AlertMessage from "@/components/AlertMessage.vue";

export default {
  name: "ToolResult",
  components: {
    AlertMessage,
    IconExport,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      required: true,
    },
  },
  setup(props) {
    const text = computed(() =>
        capitalize(props.type.split('.').join(' '))
    )
    return {
      text
    }
  }
}
</script>

<template>
  <div class="ToolResult">
    <AlertMessage
        class="bg-yellow-100 border-1 border-black"
        name="Tool Result"
    >
      <template #icon>
        <IconExport></IconExport>
      </template>
      <template #message>
        <div class="whitespace-pre-wrap">{{ data }}</div>
      </template>
    </AlertMessage>
  </div>
</template>

