<script>
import LView from "@/components/layout/LView";
import {computed, onBeforeUnmount, ref, shallowRef, watch} from "vue";
import LModal from "@/components/layout/LModal";
import {_PUT_STATUS, RESUME_SESSION, LOGIN, ACTIVATE_ACCOUNT} from "@/store/operations";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {useUserPermissions} from "@/composables/useUserPermissions";
import AssetStatus from "@/components/asset/AssetStatus.vue";
import {withoutPostfix} from "@/lib/prefixPostfix";
import Footer from "@/components/layout/Footer.vue";
import PersonDetailsCard from "@/components/person/PersonDetailsCard.vue";
import ImagePicker from "@/components/image/ImagePicker.vue";
import Login from "@/components/views/authentication/Login.vue";
import RegisterUserAccount from "@/components/views/authentication/RegisterAccount.vue";
import ForgotPassword from "@/components/views/authentication/ForgotPassword.vue";
import {onNavigateBack} from "@/router/router";
import EnterSessionToken from "@/components/views/authentication/EnterSessionToken.vue";
import EnterSessionTokenWithUserId from "@/components/views/authentication/EnterSessionTokenWithUserId.vue";
import StatusMessage from "@/components/status/StatusMessage.vue";

const modalComponentNames = {
  person: PersonDetailsCard,
  register: RegisterUserAccount,
  login: Login,
  forgot: ForgotPassword,
  session: EnterSessionToken,
  enterToken: EnterSessionToken,
  userSession: EnterSessionTokenWithUserId,
  enterTokenWithUserId: EnterSessionTokenWithUserId,
  "signUp": RegisterUserAccount,
  "pickImage": ImagePicker,
};

const appAdminModalComponentNames = {};

const resolveModalComponent = (modal, isAdmin) => {
  let name = modalComponentNames[modal];
  if (!name && isAdmin)
    name = appAdminModalComponentNames[modal]
  name = name ?? ""
  return name
};

export default {
  name: "App",
  components: {
    AssetStatus,
    EnterSessionToken,
    EnterSessionTokenWithUserId,
    Footer,
    LModal,
    Login,
    LView,
    PersonDetailsCard,
    RegisterUserAccount,
    StatusMessage,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const appId = computed(() => store.state.appId);
    const my = useUserPermissions();
    const modalComponent = shallowRef("")
    const modalParams = ref({})
    const clientId = computed(() => store.state.clientId);

    const actionErrors = computed(() =>
        Object.entries(store.state.status)
            .filter(([, value]) => value?.isError === true && value.handled === false)
            .map(([name, value]) => ({
              actionName: name,
              name: withoutPostfix(name, "Error"),
              message: value.body,
            }))
    );

    store.commit(RESUME_SESSION);

    watch(
        router.currentRoute,
        (route) => {

          const isAdmin = my.user.value?.__isAppAdmin
          const {query} = route

          modalParams.value = {...query}

          const {modal} = query
          delete modalParams.value.modal

          modalComponent.value = resolveModalComponent(modal, isAdmin)
        },
        {immediate: true}
    );

    const handlePopstate = (event) => {
      const {state} = event;
      const {current: back} = state;
      onNavigateBack(back);
    }
    window.addEventListener('popstate', handlePopstate)
    onBeforeUnmount(() => window.removeEventListener('popstate', handlePopstate))

    const onCloseModal = () => {
      router.push({query: {}});
    };
    const onAcknowledgeActionError = (action) => {
      const {actionName} = action;
      console.log(93, actionName);
      store.commit(_PUT_STATUS, {
        event: actionName,
        result: {
          _status: {isError: false, body: null},
        },
      });
    };

    return {
      ACTIVATE_ACCOUNT,
      LOGIN,

      actionErrors,
      appId,
      clientId,
      modalComponent,
      modalParams,

      onAcknowledgeActionError,
      onCloseModal,
    };
  },
};
</script>

<template>
  <div class="app flex flex-col min-h-screen">
    <lView class="app relative text-slate-900">
      <LModal
          v-for="(actionError, index) in actionErrors"
          :key="index"
          class="fixed"
          :clickable="false"
          style="z-index: 4002; margin-top: 1rem"
      >
        <AssetStatus
            :contextName="actionError.name"
            :error="actionError"
            @click="() => onAcknowledgeActionError(actionError)"
        ></AssetStatus>
      </LModal>
      <LModal
          v-show="modalComponent"
          class="fixed h-full w-full bg-black/30"
          style="z-index: 4000"
          @close="onCloseModal"
      >
        <div v-if="modalComponent">
          <component
              :is="modalComponent"
              v-bind="modalParams"
              @close="onCloseModal"
              @done="onCloseModal"
          ></component>
        </div>
      </LModal>
      <StatusMessage :operation="LOGIN"></StatusMessage>
      <StatusMessage :operation="ACTIVATE_ACCOUNT"></StatusMessage>
      <div class="relative w-full  flex flex-col items-stretch">
        <router-view :key="appId" class="h-full"></router-view>
      </div>
    </lView>
  </div>

</template>

<style lang="css">
.button.main,
.button.primary,
button.main,
button.primary {
  color: white;
  background-image: linear-gradient(
      to bottom right,
      #2C7CE5,
      #002395
  ) !important;
}

.button.main:hover,
.button.primary:hover,
button.main:hover,
button.primary:hover {
  background-image: linear-gradient(to top left, #002395, #002395, #2C7CE5) !important;
}

.gradientLine {
  background-image: linear-gradient(to bottom right, #002395, #002395, #2C7CE5);
}

.gradientLine:hover {
  background-image: linear-gradient(to bottom left, #002395, #002395, #2C7CE5);
}
</style>
