<script>
import LInfoBox from "./layout/LInfoBox.vue";
import LGamePage from "../layout/LGamePage.vue";
import GamifiedButton from "../GamifiedButton.vue";

export default {
  name: "Welcome",
  components: {GamifiedButton, LGamePage, LInfoBox}
}
</script>

<template>
  <LGamePage>
    <template #title>
      Morning Memo
      <h2 class="gamifiedh2">
        In this game, your task is to record a short clip talking about your morning routine.
      </h2>
    </template>
    <template #default>
      <LInfoBox>
        <template #first>
          Before you begin, ensure that you are in a quiet environment.
        </template>
        <template #second>
          If needed, you can always re-do your recording.
        </template>
        <template #third>
          Click the button to continue to a microphone test.
        </template>
      </LInfoBox>

    </template>

    <template #actions>
      <!--      <GamifiedButton-->
      <!--          label="Continue"-->
      <!--          :disabled="false"-->
      <!--          @click="$router.push({name: 'morning-memo-test'})"-->
      <!--      />-->

      <button
          class="button"
          @click="$router.push({name: 'morning-memo-test'})"
      >
        Continue
      </button>
    </template>
  </LGamePage>
</template>
