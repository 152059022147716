<script>
import LGamePage from "../layout/LGamePage.vue";

export default {
  name: 'IntroScreen',
  components: {
    LGamePage,
  },
};
</script>
<template>
  <LGamePage>
    <template #title>
      Mime mix
      <h2 class="gamifiedh2">
        Get ready to read emotions!
      </h2>
    </template>
    <template #default>
      <div class="grid-cols-4 grid gap-1">
        <div class=" col-span-4 !text-md flex flex-col gap-2">

          <p>A face will appear on your screen, composed of two halves - top and bottom</p>
          <p>Each half will express a different emotion: either <b>sadness</b>, <b>disgust</b>, <b>fear</b>,
            <b>happiness</b>, <b>anger</b>, or <b>surprise</b>.</p>
          <p> Your task is to identify which emotion is expressed in either the upper or lower part of the face.</p>
        </div>
        <!--        <div class="h8:col-span-1 col-span-4">-->
        <!--          <h1 class="text-4xl font-bold text-center">BOTTOM</h1>-->
        <!--          <img src="../../assets/Pr_f_A_H_T.jpg" alt="Face Example" class="object-contain w-full ">-->
        <!--        </div>-->
      </div>
    </template>
    <template #actions>
      <button @click="$router.push({name: 'mime-mix-intro-2'})" class="button">Continue</button>
    </template>
  </LGamePage>
</template>


<style scoped>
</style>
