<script>
import {computed} from "vue";

export default {
  name: "AlertStatus",
  // todo: markdown/html message
  props: {
    name: {
      type: String,
      default: ''
    },
    message: {
      required: true
    },
  },
  setup (props) {
    const statusMessage = computed(() => props.message?.message ?? props.message ?? '')
    const statusName = computed(() => props.message?.name ?? props.name ?? 'Error')
    return {
      statusMessage,
      statusName,
    }
  }
}
</script>
<template>
  <div class="alertStatus">
    <div
        class="
          px-4
          py-1
          rounded-lg
          flex items-center
          text-sm
          text-slate-800
          bg-slate-300
        "
        role="alert"
    >
      <div class="icon flex items-center">

        <slot name="icon"></slot>

      </div>

      <div class="flex flex-wrap px-4">
        <span
            v-if="name"
            class="font-bold pr-4"
        >
          {{ statusName }}
        </span>
        <div>
          {{ statusMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

