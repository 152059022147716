<script>
import {useSharedDocument} from "@/composables/document/shared/useSharedDocument";
import {useRouter} from "vue-router";

export default {
  name: "SideBarListOwnAssistantItem",
  props: {
    _appId: {
      type: String,
      required: true
    },
    _dataType: {
      type: String,
      default: 'Assistant'
    },
    _id: {
      type: String,
      required: true
    },
  },
  setup(props) {
    const router = useRouter()
    const {assistant} = useSharedDocument({
      alias: 'assistant',
      filter: {
        _appId: props._appId,
        _dataType: props._dataType,
        _id: props._id
      },
    })

    const onNavigateToItem = () => {
      router.push({name: 'teachingAssistant', params: {_assistantId: props._id}})
    }

    return {
      assistant,
      onNavigateToItem
    }

  }
}
</script>

<template>
  <div class="SideBarListOwnAssistantItem">
    <div class="
      w-full
      py-2
      rounded
      text-center
      flex flex-col items-center justify-start gap-1
      bg-white/10
      hover:bg-white/20
      shadow
      hover:shadow-lg transition-shadow duration-200
      cursor-pointer
      "
         @click="onNavigateToItem"
    >
      <div class="w-10 h-10 rounded-full bg-blue-500">
        <img src="../../../assets/teachingAssistant.png" alt="assistant"
             class="w-full h-full object-cover rounded-full">
      </div>
      <div class="name">
        {{ assistant.name }}
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>