import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import axios from "@/plugins/axios";
import {getAssistantsUploadUrl} from "@/store/lib/apiUrls";
import {LIST_UPLOADS, UPLOAD} from "@/store/operations";
import {handleResponse} from "@/store/actions/lib/handleResponse";

export default {
    [LIST_UPLOADS](context, {_appId, _id}) {
        return throttleBufferedAction({
            action: axios.get,
            url: getAssistantsUploadUrl({_appId, _id}),
        })
            .then(handleResponse(context, LIST_UPLOADS))
    },
    [UPLOAD](context, {
        _appId,
        _id,
        formData,
        onProgress
    }) {
        return axios.post(
            getAssistantsUploadUrl({_appId, _id}),
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onProgress,
            })
            .then(handleResponse(context, UPLOAD))
    },
}