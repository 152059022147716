<template>
  <button
      class="
        p-1
        rounded-md
        flex items-center justify-center
        border border-gray-300
        hover:!bg-white
      "
      @click="onDelete"
  >
    <IconTrashCanOutline></IconTrashCanOutline>
  </button>
</template>
<script>
import IconTrashCanOutline from "@/components/icons/TrashCanOutline.vue"

export default {
  name: 'DeleteButton',
  components: {IconTrashCanOutline},
  props: {
    onDelete: {}
  }
}
</script>
<style scoped>


</style>