import {_PUT_STATUS, LIST_ASSETS} from "@/store/operations";
import {normalizeRedirect} from "@/lib/normalizeRedirect";

const handleExpiredAuthentication = (response) => {
    const {data}    = response ?? {}
    const {Error}   = data ?? {}
    const {code}    = Error ?? {}
    if (code === 401) {
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set("modal", "login")
        const redirect = searchParams.get('redirect')
        const isAuthRoute = window.location.hostname.includes('app')
        if (!redirect && !isAuthRoute) {
            searchParams.set("redirect", normalizeRedirect(window.location.protocol + '//' + window.location.hostname + window.location.pathname))
            window.location.search = searchParams.toString()
        }
    }
}
export const handleResponse = (context, operation, resetState) => async response => {
    context.commit(LIST_ASSETS, response.data, resetState)
    handleExpiredAuthentication(response)
    context.commit(_PUT_STATUS, {event: operation, result: response.data})
    return response
}