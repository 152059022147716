<script>

import {computed, ref} from "vue";
import Progressbar from "../Progressbar.vue";
import {useRoute} from "vue-router";
import LSection from "@/components/layout/LSection.vue";

export default {
  name: 'ColorCatch',
  components: {
    LSection,
    Progressbar,
  },
  setup() {
    const route = useRoute();
    const level = ref(1)

    const stepsWithIcons = [
      {label: 'Welcome', icon: 'QuestionMarkIcon'},
      {label: 'Tutorial', icon: 'TutorialIcon'},
      {label: 'Level 1', icon: 'PlayIcon'},
      {label: 'Level 2', icon: 'PlayIcon'},
      {label: 'Level 3', icon: 'PlayIcon'},
      {label: 'Level 4', icon: 'PlayIcon'},
      {label: 'Feedback', icon: 'StarIcon'},
    ];

    const steps = stepsWithIcons.map(step => step.label);

    const step = computed(() => {
      let label = route.meta?.stepLabel ?? 'Welcome'
      if (level.value && route.meta?.stepLabel.startsWith("Level")) label = 'Level ' + level.value

      if (label.startsWith('Level')) label = 'Level ' + level.value

      return steps.indexOf(label)
    })

    const onSetLevel = newLevel => level.value = newLevel

    return {
      onSetLevel,
      level,
      step,
      stepsWithIcons,
    }
  }
}
</script>

<template>
  <LSection>
    <div class="
          w-full
          fadeInAnimation
          flex-grow
          flex flex-col items-center
      ">
      <Progressbar
          :current="step"
          :stepsWithIcons="stepsWithIcons"
      />

      <router-view
          @level="onSetLevel"
          @end="$router.push({name: 'color-catch-done'})"
      ></router-view>
    </div>
  </LSection>
</template>

