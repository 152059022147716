<script>
import Pipe from "@/components/pipe/Pipe.vue";
import {generateRandomString} from "@/lib/typeHelpers/stringFunctions/generateRandomString";
import {computed, ref, watch, inject, watchEffect, isRef} from "vue";
import IconListBox from "@/components/icons/ListBox.vue";
import {useStore} from "vuex";
import {APPLY_DELTA, SYNC_ASSETS} from "@/store/operations";
import StreamItem from "./streamItem/StreamItem.vue";
import {normalizeTimestamp} from "@/lib/typeHelpers/dateFunctions/normalizeTimestamp";
import {isObject, isString} from "@/lib/getVariableType";
import {createEvent} from "@/components/CHIThread/CHIStream/lib/createEvent";

/*
    Stream
    - forward infrastructure events as stream items

 */
const states = {
  initial: '',
  streaming: 'Tunneling',
  error: 'Error',
  disconnected: 'Disconnected',
  done: 'Done',
  exit: 'Exit'
}

const debug = true

export default {
  name: "CHIStream",
  emits: [
    'error',
    'exit',
    'stream',
    'thread',
    'socket_state',
    'pipe_state',
    'spawn_state',
  ],
  components: {
    StreamItem,
    IconListBox,
    Pipe,
  },
  props: {
    pipeName: {
      type: String,
      required: true,
    },
    listenerName: {
      type: String,
      default: 'CHIStream',
    },
    staticParams: {
      type: Object,
    },
    logLevel: {
      type: String,
      default: 'error',
      validator: (value) => ['error', 'info'].includes(value),
    },
  },
  setup(props, {emit}) {
    const store = useStore()

    /*
        stream initialization
     */

    const thread_id = ref(null)

    const messages = computed(() => Object.values(store.state.docs?.Message ?? {})
        .filter(item =>
            item.thread_id === thread_id.value
        ))
    const runs = computed(() => Object.values(store.state.docs?.Run ?? {})
        .filter(item =>
            item.thread_id === thread_id.value
        ))
    const steps = computed(() => Object.values(store.state.docs?.Step ?? {})
        .filter(item =>
            item.thread_id === thread_id.value
        ))
    const calls = computed(() => Object.values(store.state.docs?._Call ?? {})
        .filter(item =>
            item.thread_id === thread_id.value
        ))

    const deltas = ref([])
    const statusEvents = ref([])
    const streamItems = ref([])
    const threadIsLocked = ref(false)

    watchEffect(() => {
      streamItems.value = [
        ...messages.value
      ]
          .concat(runs.value)
          .concat(steps.value)
          .concat(calls.value)
          .concat(deltas.value)
          .map(component => {
            const data = {
              created_at: component.started_at || component.created_at || Date.now(),
              _dataType: component._dataType,
            }
            if (component.id) {
              data.id = component.id
            }

            createEvent('Component', data)
          })
          .concat(statusEvents.value.map(event =>
              createEvent(
                  event.type,
                  event.data
              )
          ))
          .filter(v => !!v)
          .map(item => ({
            ...item,
            key: generateRandomString(12, 'alphanumeric'), // for rendered list item component key
          }))

    })

    const sortedItems = computed(() =>
        streamItems.value
            .sort((a, b) =>
                a.timestamp - b.timestamp // ascending timestamp
            )
    )

    let sendPipeMessage

    /*
        stream stack lifecycle handlers
     */

    const socketState = ref('')
    const pipeState = ref('')
    const spawnState = ref(states.initial)

    const onSpawnState = state => { // spawn
      const validState = states[state]
      if (!validState) return
      spawnState.value = validState
    }
    const onSocketState = status => { // socket
      socketState.value = status
      onSpawnState(status)
      emit('socket_state', status)
    }
    const onPipeState = status => { // pipe
      pipeState.value = status
      onSpawnState(status)
      emit('pipe_state', status)
    }

    const onStream = (send) => {
      sendPipeMessage = send
      onSpawnState('stream')
      statusEvents.value.push(
          createEvent('spawn', {message: 'Streaming Assistant Run.'})
      )
      emit('stream')
    }
    const onToolOutput = (data) => {
      store.commit(SYNC_ASSETS, {Call: [data]})
    }
    const onError = (err) => {
      onSpawnState('error')
      statusEvents.value.push({
        message: JSON.stringify(err),
        type: 'spawn.error',
      })
      emit('error', err)
    }
    const onExit = (data) => {
      onSpawnState('exit')
      statusEvents.value.push({
        ...data,
        type: 'spawn.exit',
      })
      emit('exit', data)
    }
    const onDone = () => {
      onSpawnState('done')
      statusEvents.value.push(
          createEvent('spawn', {message: 'Assistant Run Complete.'})
      )
    }

    // helpers
    const getPreCallId = (data, index) => {
      if (!data.step_details?.tool_calls?.[index]) return
      return [
        data.id,
        data.step_details.tool_calls[index].index
      ].join('__')
    }
    const syncCalls = data => {
      const hasToolCalls = data.step_details?.type === 'tool_calls'
      if (hasToolCalls) {
        const calls = data.step_details.tool_calls.map((c, i) => ({
          ...c,
          _id: getPreCallId(c, i),
          _dataType: '_ToolCall',
        }))
        store.commit(SYNC_ASSETS, {_ToolCall: calls})
      }
    }

    // chi sync events
    const onCHIThreadCreated = (data) => {
      thread_id.value = data.id
      emit('thread', data.id)
      store.commit(SYNC_ASSETS, {Thread: [data]})
      statusEvents.value.push(
          createEvent('chi.thread.created', data)
      )
    }
    const onCHIMessageCreated = (data) => {
      store.commit(SYNC_ASSETS, {Message: [data]})
      statusEvents.value.push(
          createEvent('chi.message.created', data)
      )
    }
    const onThreadMessageCreated = (data) => {
      store.commit(SYNC_ASSETS, {Message: [data]})
      statusEvents.value.push(
          createEvent('thread.message.created', data)
      )
    }
    const onStepCreated = (data) => {
      store.commit(SYNC_ASSETS, {Step: [data]})
      statusEvents.value.push(
          createEvent('thread.run.step.in_progress', data)
      )
      syncCalls(data)
    }
    const onThreadMessageDelta = (data) => {
      store.commit(APPLY_DELTA, {
        ...data,
        _dataType: 'Message'
      })
    }
    const onStepDelta = (data) => {
      store.commit(APPLY_DELTA, {
        ...data,
        _dataType: 'Step'
      })
      syncCalls(data)
    }
    const onStepComplete = (data) => {
      store.commit(SYNC_ASSETS, {Step: [data]})
      statusEvents.value.push(
          createEvent('thread.run.step.completed', data)
      )
    }

    const finalizeAsset = (_dataType, data) => {
      if (_dataType === 'Step') {
        const tempIdPrefix = data.id + '__'
        store.commit(SYNC_ASSETS, {_ToolCall: data.step_details.tool_calls})
      }

      store.commit(SYNC_ASSETS, {[_dataType]: [data]})
    }

    const lockThreadForToolCalls = (data) => {
      const hasToolCalls = data.step_details?.type === 'tool_calls'
      threadIsLocked.value = hasToolCalls
    }
    const releaseThread = () => threadIsLocked.value = false

    const onGenericEvent = (eventName, data) => {
      syncStreamedState(eventName, data)
      statusEvents.value.push(
          createEvent(eventName, data)
      )
    }

    /*
        state updates
     */

    const syncStreamedState = (eventName, result) => { // on create/update events
      store.commit(SYNC_ASSETS, result)
    }
    const onMessage = ({data, eventName}) => {
      /*
          intercept and handle spawn events
          forward stream state
          forward component state
       */
      switch (eventName) {
          // router/spawn/assistantRunStream/onStreamEvent.js
        case 'error':
          return onError(data)
        case 'done':
          return onDone(data)
        case 'spawn':
          return onStream(data)
        case 'exit':
          return onExit(data)

        case 'tool.stdout':
          return onToolOutput
        case 'tool.stderr':
          return onToolOutput

          // assistant UI chat integration
        case 'chi.thread.created':
          return onCHIThreadCreated(data)
        case 'chi.message.created':
          return onCHIMessageCreated(data)

          // delta animation
        case 'thread.message.created':
          return // no data, first attributes arrive with 'thread.run.step.in_progress'
        case 'thread.message.in_progress':
          return onThreadMessageCreated(data)
        case 'thread.message.delta':
          return onThreadMessageDelta(data)
        case 'thread.message.completed':

          return finalizeAsset('Message', data)
        case 'thread.run.step.created':
          return lockThreadForToolCalls(data)
        case 'thread.run.step.in_progress':
          return onStepCreated(data)
        case 'thread.run.step.delta':
          return onStepDelta(data)
        case 'thread.run.step.completed':
          return finalizeAsset('Step', data)

        case 'thread.run.completed':
          return releaseThread()

          // openAI assistant stream events (https://platform.openai.com/docs/api-reference/assistants-streaming/events)
          // action lifecycle events
        default:
          onGenericEvent(eventName, data)
      }
    }

    /*
        infrastructure event forwarders
     */
    watch(socketState, (newVal, oldVal) => {
      const isInitialized = !!oldVal
      const hasChanged = newVal !== oldVal
      if (isInitialized && hasChanged && newVal !== undefined) {
        console.log(4287, 'socket event', newVal)
        emit('socket_state', newVal)
        statusEvents.value.push(
            createEvent('socket', newVal)
        )
      }
    }, {immediate: true})
    watch(pipeState, (newVal, oldVal) => {
      const isInitialized = !!oldVal
      const hasChanged = newVal !== oldVal
      if (isInitialized && hasChanged && newVal !== undefined) {
        emit('pipe_state', newVal)
        statusEvents.value.push(
            createEvent('pipe', newVal)
        )
      }
    }, {immediate: true})
    watch(spawnState, (newVal, oldVal) => {
      const isInitialized = !!oldVal
      const hasChanged = newVal !== oldVal
      if (isInitialized && hasChanged && newVal !== undefined) {
        emit('spawn_state', newVal)
        statusEvents.value.push(
            createEvent('spawn', newVal)
        )
      }
    }, {immediate: true})

    // UI states
    const streamTitle = computed(() => `Stream: ${spawnState.value || 'Unavailable'}`)
    const showEvents = ref(false || debug)
    const logLevel = computed(() => {
      let logLevel = props.logLevel
      if (logLevel === 'info' && !showEvents.value) logLevel = 'error'
      return logLevel
    })

    return {
      // stream state
      logLevel,
      showEvents,
      spawnState,
      streamTitle,
      sortedItems,

      // lifecycle events
      onStream,
      onMessage,
      onSocketState,
      onPipeState,
    }
  }
}
</script>

<template>
  <div class="CHIStream w-full">
    <Pipe
        :pipeName="pipeName"
        :listener-name="listenerName"
        @ready="onStream"
        @message="onMessage"
        @socket_status="onSocketState"
        @pipe_status="onPipeState"
    >
      <template #indicators>
        <div
            :class="{
                  'text-green-500': spawnState === 'Streaming',
                  'text-red-500': spawnState === 'Error',
                  'bg-red-500 text-black': spawnState === 'Disconnected',
                  'bg-red-700 text-white': spawnState === 'Exit',
                  'text-slate-200': spawnState === 'Done',
                }"
            class="spawn"
            :title="streamTitle"
        >
          <IconListBox :title="streamTitle"></IconListBox>
        </div>
      </template>
    </Pipe>

    <div class="w-full flex flex-col gap-1">
      <StreamItem
          v-for="(item) in sortedItems"
          :key="item.key"
          :staticParams="staticParams"
          :logLevel="logLevel"
          :type="item.type ?? 'event'"
          :timestamp="item.timestamp"
          :data="item.data"
          :item="item"
      ></StreamItem>
    </div>
  </div>
</template>

