<script>
export default {
  name: "LToolCall"
}
</script>

<template>
<div class="lToolCall layout">
  <slot name="status"></slot>
  <slot name="name"></slot>
  <slot name="output"></slot>
</div>
</template>

<style scoped>

</style>