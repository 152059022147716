import {createApp} from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import './assets/css/tailwind.css'
import './index.css'
import './hljs_github-dark.css'
import dayjs from './plugins/dayjs.js'
import axios from './plugins/axios'

createApp(App)
    .use(store)
    .provide('AXIOS', axios)
    .provide('DAYJS', dayjs)
    .use(router)
    .mount('#app')

