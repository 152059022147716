export const surveyData = [
    {
        question: "How likely are you to engage with our personalized gallery augmented survey, compared to traditional methods like an e-boks survey?",
        options: ["1 (Much less likely)", "2", "3", "4", "5 (Much more likely)"]
    },
    {
        question: "How likely are you to engage with our personalized gallery augmented survey, compared to being stopped on the street for a survey?",
        options: ["1 (Much less likely)", "2", "3", "4", "5 (Much more likely)"]
    },
    {
        question: "How likely are you to engage with our personalized gallery augmented survey compared to receiving a phone call for a survey?",
        options: ["1 (Much less likely)", "2", "3", "4", "5 (Much more likely)"]
    },
    {
        question: "How important to your opinion above was the presence of AI?",
        options: ["1 (Not important at all)", "2", "3", "4", "5 (Very important)"]
    },
    {
        question: "How important to your opinion was the personalized output?",
        options: ["1 (Not important at all)", "2", "3", "4", "5 (Very important)"]
    },
    {
        question: "How important to your opinion was the inclusion of a previous citizen engagement effort?",
        options: ["1 (Not important at all)", "2", "3", "4", "5 (Very important)"]
    },
    {
        question: "What do you find most valuable/problematic about the concept of Human-AI co-created personalized galleries in participatory democracy?",
        options: [],
        answer: ""
    }
];
