import {isArrayOfStrings, isObject} from "@/lib/getVariableType";
import {
    LIST_SHARED_DOCUMENTS,
    CREATE_SHARED_DOCUMENT,
} from "@/store/operations";
import {assertIsSupportedOperation} from "@/store/lib/veriffyOperation";
import {useStore} from "vuex";
import {computed, isRef, onMounted, reactive, ref, toRefs, watch} from "vue";
import {isJSIdentifier} from "@/lib/typeHelpers/stringFunctions/isJSIdentifier";
import {normalizeDocumentOperationKeys} from "@/composables/document/lib/normalizeDocumentOperationKeys";
import {filterDocuments} from "@/composables/document/lib/filterDocuments";

const debug = {
    list: false,
    create: false,
}

const verifyOperations = operations => {
    Object.values(operations)
        .forEach(assertIsSupportedOperation)
}

const ingestOperations = operations => {
    operations = operations || {}
    if (!isObject(operations)) throw new Error('operations must be an object')

    normalizeDocumentOperationKeys(operations, 'shared')

    if (!operations.listSharedDocuments) operations.listSharedDocuments = LIST_SHARED_DOCUMENTS
    if (!operations.createSharedDocument) operations.createSharedDocument = CREATE_SHARED_DOCUMENT

    verifyOperations(operations)
    return operations
}
const ingestGenerators = generators => {
    generators = generators || {}
    if (!isObject(generators)) throw new Error('generators must be an object')
    return generators
}
// const ingestFilter = filter => {
//     if (!filter) throw new Error('filter is required')
//     if (!isObject(filter)) throw new Error('filter must be an object')
//     if (!filter._dataType) throw new Error('filter._dataType is required')
//     return filter
// }
const ingestAlias = alias => {
    if (!isJSIdentifier(alias)) throw new Error('alias must be a valid JavaScript identifier')
    return alias
}
const ingestOptions = options => {
    options = options || {}
    if (!isObject(options)) throw new Error('options must be an object')
    return options
}
const ingestProperties = properties => {
    properties = properties || {}
    if (!isObject(properties)) throw new Error('properties must be an object')
    if (Object.keys(properties).some(key => key.startsWith('_'))) throw new Error('properties must not contain keys that start with an underscore')
    return properties
}
const ingestFilter = filter => {
    if (!filter) throw new Error('filter is required')
    const refFilter = isRef(filter) ? filter : ref(filter)
    if (!isObject(refFilter.value)) throw new Error('filter must be an object')
    if (!refFilter.value._dataType) throw new Error('filter._dataType is required')
    return refFilter
}

const ingestSorting = sortingRef => {
    sortingRef = sortingRef || ref([])
    if (!isRef(sortingRef)) sortingRef = ref(sortingRef)
    if (!isArrayOfStrings(sortingRef.value)) throw new Error('sorting must be an array of strings fx. "_createdAt, desc"')
    sortingRef.value.forEach(sort => {
        const [attributeName, direction = 'desc'] = sort
            .split(',')
            .map(s => s.trim())
        if (!isJSIdentifier(attributeName)) throw new Error(attributeName, 'is not a valid attributeName for sorting. Must be a valid JavaScript identifier.')
    }, true)
    return sortingRef
}

let repository

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export const useSharedDocuments = ({
                                       alias,
                                       operations,
                                       generators,
                                       filter, // object or ref of object
                                       sorting,
                                       offset,
                                       limit,
                                       options,
                                   }) => {
    if (!filter) throw new Error('filter is required')
    offset = offset || 0
    limit = limit || 100
    filter = ingestFilter(filter)
    sorting = ingestSorting(sorting)

    alias = ingestAlias(alias)
    operations = ingestOperations(operations)
    generators = ingestGenerators(generators)
    options = ingestOptions(options)

    const {
        autoLoad = false
    } = options

    const store = useStore();
    const documents = ref([])
    const context = reactive({
        isWaitingForFilter: true,
        isLoaded: false,
        isLoading: false,
        isPristine: false,
        error: null,
    })

    const listSharedDocuments = async (docFilter, offset, pageSize) => {
        const attributes = ingestFilter(docFilter || filter).value
        context.isWaitingForFilter = false
        const {_dataType} = attributes
        try {
            let docs = []
            store.state.docs[_dataType] = store.state.docs[_dataType] || {}

            if (!repository) {
                // setup watch event source
                repository = computed(() => Object.values(store.state.docs[_dataType] ?? {}))
                watch(repository, newV => {
                    filterDocuments(documents, newV, filter.value, sorting.value)
                }, {immediate: true})
                watch(filter, newV => {
                    filterDocuments(documents, repository.value, newV, sorting.value)
                }, {immediate: true})
                watch(sorting, newV => {
                    filterDocuments(documents, repository.value, filter.value, newV)
                }, {immediate: true})
            }

            if (autoLoad) {
                context.isLoading = true
                const {_appId, _dataType} = attributes
                if (!_appId) throw new Error('_appId is required to list documents')
                if (!_dataType) throw new Error('_dataType is required to list documents')

                const params = {
                    _appId,
                    _dataType,
                    filter: {...filter.value},
                }
                if (sorting.value?.length) params.sorting = [...sorting.value]
                if (offset) params.offset = offset
                if (pageSize) params.pageSize = pageSize
                if (limit) params.limit = limit

                if (debug.list) console.log('listSharedDocuments(params)', operations.listSharedDocuments, params)
                return store.dispatch(
                    operations.listSharedDocuments,
                    params
                )
                    .then(result => {
                        filterDocuments(documents, store.state.docs[_dataType], filter.value, sorting.value)
                        if (debug.list) console.log('listSharedDocuments.then(result)', result, documents.value)
                        context.isPristine = true
                        context.isLoaded = true
                        return result
                    })
                    .catch(error => {
                        console.log(487, error)
                    })
            } else {
                if (debug.list) console.log(`noAutoLoad ${_dataType}`, autoLoad)
                filterDocuments(documents, store.state.docs[_dataType], filter.value, sorting.value)
                context.isLoaded = true
            }
            context[alias] = docs
        } catch (error) {
            context.error = error
            console.warn(487, error)
            throw error
        } finally {
            context.isLoading = false
        }
    }

    onMounted(() => {
        const filterAttributes = filter?.value ?? filter ?? null
        listSharedDocuments(filterAttributes)
            .catch(error => context.error = error)
    })

    const createSharedDocument = async (properties, _parentId) => {
        properties = ingestProperties(properties)
        if (!filter.value._appId) throw new Error('filter._appId is required to create new documents')
        if (!filter.value._dataType) throw new Error('filter._dataType is required to create new documents')

        properties._appId = filter?.value?._appId
        properties._dataType = filter?.value?._dataType

        if (_parentId) properties._parentId = _parentId

        try {
            context.isLoading = true
            let generator = generators?.create
            let call
            if (generator) {
                call = generator(properties, _parentId)
            } else {
                call = store.dispatch(operations.createSharedDocument, properties)
            }
            return call
                .then(response => {
                    try {
                        const {data} = response
                        let {Error} = data
                        const documents = data[properties._dataType]
                        if (documents && !Error) {
                            documents.map(document => documents.push(document))
                        }
                        context.isPristine = true
                    } catch (err) {
                        console.warn(err)
                        throw new Error('Server response is malformed')
                    }
                    return response
                })
                .catch(error => {
                    console.log(488, error)
                })
        } catch
            (error) {
            context.error = error
        } finally {
            context.isLoading = false
        }
    }

    return {
        ...toRefs(context),
        documents,
        listSharedDocuments,
        createSharedDocument,
    }
}