import {isObject} from "@/lib/getVariableType";
import {computed, isRef, reactive, ref, toRefs, watch} from "vue";
import {useStore} from "vuex";
import {capitalize} from "@/lib/typeHelpers";
import {assertIsSupportedOperation} from "@/store/lib/veriffyOperation";
import {isJSIdentifier} from "@/lib/typeHelpers/stringFunctions/isJSIdentifier";
import {normalizeDocumentOperationKeys} from "@/composables/document/lib/normalizeDocumentOperationKeys";
import {
    DELETE_SHARED_DOCUMENT,
    GET_SHARED_DOCUMENT,
    PATCH_SHARED_DOCUMENT,
    PUBLISH_SHARED_DOCUMENT,
    RETRACT_SHARED_DOCUMENT,
} from "@/store/operations/sharedDocumentOperations";
import {areSame} from "@/lib/typeHelpers/objectFunctions/areSame";
import {isFunction} from "@/lib/typeHelpers/getVariableType";

const debug = false

const verifyOperations = operations => {
    Object.values(operations)
        .forEach(assertIsSupportedOperation)
}
const ingestOperations = operations => {
    operations = operations || {}
    if (!isObject(operations)) throw new Error('operations must be an object')

    normalizeDocumentOperationKeys(operations, 'shared')

    let {
        getSharedDocument,
        patchSharedDocument,
        publishSharedDocument,
        retractSharedDocument,
        deleteSharedDocument,
    } = operations

    if (!getSharedDocument) operations.getSharedDocument = GET_SHARED_DOCUMENT
    if (!patchSharedDocument) operations.patchSharedDocument = PATCH_SHARED_DOCUMENT
    if (!publishSharedDocument) operations.publishSharedDocument = PUBLISH_SHARED_DOCUMENT
    if (!retractSharedDocument) operations.retractSharedDocument = RETRACT_SHARED_DOCUMENT
    if (!deleteSharedDocument) operations.deleteSharedDocument = DELETE_SHARED_DOCUMENT
    verifyOperations(operations)
    return operations
}
const defaultOptions = {
    loader: null,   // function to call instead of loading
    autoLoad: false,    // load from server if not found in store
    noAutoSync: false,    // do not synchronize with server
    forceRefresh: false,  // force load from server, even for immutable dataTypes
}
const ingestOptions = options => {
    options = options || {}
    const {decode} = options
    if (!isObject(options)) throw new Error('options must be an object')
    if (decode && !isFunction(decode)) throw new Error('options.decode must be a function')
    Object.assign({}, defaultOptions, options)
    return options
}
const ingestFilter = filter => {
    if (!filter) throw new Error('filter is required')
    filter = isRef(filter) ? filter : ref(filter)
    const target = filter.value
    if (!isObject(target)) throw new Error('filter must be (ref of) an object')
    if (!target._dataType) throw new Error('filter._dataType is required')
    if (!target._id) throw new Error('filter._id is required')
    return filter
}
const ingestAlias = alias => {
    if (!alias) throw new Error('alias is required')
    if (!isJSIdentifier(alias)) throw new Error('alias must be a valid JavaScript identifier')
    return alias
}
const determineIsImmutable = filter => {
    const dataType = filter?._dataType
    let isImmutable = true
    if (!dataType) return isImmutable
    if (dataType.startsWith('_')) return isImmutable
    return false
}

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
export const useSharedDocument = ({
                                      alias, // _dataType may not yet be resolved
                                      operations,
                                      filter,
                                      options
                                  }) => {
    if (!filter) throw new Error('filter is required')

    options = ingestOptions(options)
    filter = ingestFilter(filter)
    operations = ingestOperations(operations)
    alias = ingestAlias(alias)

    const Alias = capitalize(alias)
    const {
        loader,
        autoLoad,
        forceRefresh,
    } = options

    const isImmutable = ref(alias.startsWith('_'))

    const store = useStore();
    const value = computed(() => {
        if (!filter.value) return null
        const {_dataType, _id} = filter.value ?? {}
        return  store.state.docs[_dataType]?.[_id]
    })

    const context = reactive({
        [alias]: null,
        [`${alias}_isWaitingForFilter`]: true,
        [`${alias}_isLoading`]: false,
        [`${alias}_isLoaded`]: false,
        [`${alias}_isPristine`]: false,
        [`${alias}_error`]: null,
    })

    const loadSharedDocument = async (filter, refresh=false) => {
        filter = ingestFilter(filter)
        context[`${alias}_isWaitingForFilter`] = false

        try {
            const {_appId, _dataType, _id} = filter.value
            if (!_appId || !_dataType || !_id) {
                console.warn(4022, alias, filter.value, 'filter must have _appId, _dataType and _id. to load document.')
            }
            const isLoaded = !!store.state.docs[_dataType]?.[_id]?._id
            const canLoad = !!(_appId && _dataType && _id)
            const shouldLoad = canLoad && refresh || (!isLoaded && autoLoad)
            if (shouldLoad) {
                context[`${alias}_isLoading`] = true
                if (options.loader) {
                    const params = {...filter.value}
                    await loader(params)
                        .finally(() => {
                            context[`${alias}_isLoading`] = false
                        })
                } else {
                    await store.dispatch(operations.getSharedDocument, filter.value)
                        .finally(() => {
                            context[`${alias}_isLoading`] = false
                        })
                }
            }
        } catch (error) {
            console.log(4024, error, operations.getSharedDocument, {...filter?.value})
            context[`${alias}_error`] = error
        }
    }

    watch(filter,
        (newVal, oldVal) => {
            isImmutable.value = determineIsImmutable(newVal) && !forceRefresh // expect immutable dataTypes to be loadable on forceRefresh
            const shouldLoad =
                !isImmutable.value &&
                autoLoad &&
                newVal._dataType &&
                newVal._dataType !== oldVal?._dataType &&
                newVal._id &&
                newVal._id !== oldVal?._id

            if (shouldLoad) {
                context[`${alias}_isWaitingForFilter`] = false
                if (debug) console.log(2398, 'loading document', alias)
                if (loader) loader(filter)
                    .catch(err => {
                        console.log(4023, err)
                        context[`${alias}_error`] = err
                    })
                    .finally(() => {
                        context[`${alias}_isLoading`] = false
                    })
                else {
                    loadSharedDocument(filter)
                        .catch(err => {
                            console.log(4023, err)
                            context[`${alias}_error`] = err
                        })
                        .finally(() => {
                            context[`${alias}_isLoading`] = false
                        })
                }
            }
        },
        {immediate: true}
    );

    watch( // sync with store state changes
        value,
        (newValue, oldValue) => {
            if (newValue !== undefined && !areSame(newValue, oldValue)) {
                if (debug) console.log(2399, 'syncing shared document', alias, newValue)

                let value = newValue
                if (options.decode) value = options.decode(value)

                context[alias] = value

                context[`${alias}_isPristine`] = true
                context[`${alias}_isLoaded`] = !!context[alias]
            }
        }
        , {immediate: true})

    const updateSharedDocument = async (update) => {
        if (isImmutable.value) {
            console.warn(filter, update)
            throw new Error('Cannot update immutable shared document')
        }
        context[`${alias}_isPristine`] = false
        try {
            context[`${alias}_isLoading`] = true
            await store.dispatch(operations.patchSharedDocument, {...filter, update})
            context[`${alias}_isPristine`] = true
        } catch (error) {
            context[`${alias}_error`] = error
        } finally {
            context[`${alias}_isLoading`] = false
        }
    }
    const publishSharedDocument = async () => {
        if (isImmutable.value) {
            throw new Error('Cannot publish immutable shared document')
        }
        context[`${alias}_isPristine`] = false
        try {
            context[`${alias}_isLoading`] = true
            await store.dispatch(operations.publishSharedDocument, filter.value)
            context[`${alias}_isPristine`] = true
        } catch (error) {
            context[`${alias}_error`] = error
        } finally {
            context[`${alias}_isLoading`] = false
        }
    }

    const retractSharedDocument = async () => {
        if (isImmutable.value) {
            throw new Error('Cannot retract immutable shared document')
        }
        context[`${alias}_isPristine`] = false
        try {
            context[`${alias}_isLoading`] = true
            await store.dispatch(operations.retractSharedDocument, filter.value)
            context[`${alias}_isPristine`] = true
        } catch (error) {
            context[`${alias}_error`] = error
        } finally {
            context[`${alias}_isLoading`] = false
        }
    }
    const deleteSharedDocument = async () => {
        if (isImmutable.value) {
            console.warn(filter)
            throw new Error('Cannot delete immutable document')
        }
        context[`${alias}_isPristine`] = false
        try {
            context[`${alias}_isLoading`] = true
            await store.dispatch(operations.deleteSharedDocument, filter.value)
            context[`${alias}_isPristine`] = true
        } catch (error) {
            context[`${alias}_error`] = error
        } finally {
            context[`${alias}_isLoading`] = false
        }
    }

    return {
        ...toRefs(context),
        [`load${Alias}`]: loadSharedDocument,
        [`update${Alias}`]: updateSharedDocument,
        [`publish${Alias}`]: publishSharedDocument,
        [`retract${Alias}`]: retractSharedDocument,
        [`delete${Alias}`]: deleteSharedDocument,
    }
}