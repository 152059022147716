<script>
import appId from "../../appId";
import LSection from "@/components/layout/LSection.vue";
import OpenAIThread from "../../../chi-assistants/components/AssistantRunStream/items/openAIThread/OpenAIThread.vue";

export default {
  name: "Home",
  components: {
    OpenAIThread,
    LSection,
  },
  data() {
    return {
      appId,
    }
  },
}
</script>

<template>
  <div class="
      home view
      h-full flex-grow
      pt-20
      flex flex-col
    ">
    <LSection class="h-full">
      <div class="
          flex-grow
          flex flex-col items-center justify-center
      ">
        <div class="flex flex-col items-center">
          <div class="heading1">CHI App | CHI</div>
          <p>
            This is the common landing view for the application service of the Center for Hybrid Intelligence.
          </p>
        </div>
        <!--        max dev -->
        <OpenAIThread
            _assistantId="asst_CjZ79cCRcFBcPNJOmgczr6st"
        ></OpenAIThread>
      </div>
    </LSection>
  </div>
</template>

<style scoped>

</style>