<script>
import {computed, ref, watch} from "vue";
import Progressbar from "../Progressbar.vue";
import {state as game} from '../quizTime/lib/game.js'

export default {
  name: "QuizTime",
  components: {
    Progressbar,
  },
  setup() {
    const stepsWithIcons = [
      {label: 'Welcome', icon: 'QuestionMarkIcon'},
      {label: 'Social Science', icon: 'PlayIcon'},
      {label: 'Natural Science', icon: 'PlayIcon'},
      {label: 'Humanities', icon: 'PlayIcon'},
      {label: 'Feedback', icon: 'StarIcon'},
    ];

    const steps = stepsWithIcons.map(step => step.label);

    const currentViewIndex = ref(0);
    const category = computed(() => game.currentCategory)

    const onProgress = (label) => {
      currentViewIndex.value = steps.indexOf(label);
    };

    watch(category, category => {
      if (steps.includes(category)) {
        onProgress(category)
      }
    })

    return {
      stepsWithIcons,
      currentViewIndex,
      onProgress,
    };
  },
};
</script>

<template>
  <div class="quizTime view w-full !h-full flex-grow flex flex-col">
    <div
        class="
            max-w-3xl
            text-center
            self-center
            px-3
            w-full
            h-full
            flex-grow flex flex-col
    ">
      <Progressbar
          :current="currentViewIndex"
          :stepsWithIcons="stepsWithIcons"
      />
      <div class="flex-grow h-full flex ">
        <router-view @progress="onProgress"></router-view>
      </div>

    </div>
  </div>
</template>



