<script>
import IconPowershell from "@/components/icons/Powershell.vue";
import AlertError from "@/components/AlertError.vue";

export default {
  name: "SpawnError",
  components: {
    AlertError,
    IconPowershell
  },
  props: {
    name: {
      type: String,
    },
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <div class="spawnError bg-yellow-300">
    <AlertError
        :name="name || 'Spawn Error'"
        :message="message"
    >
      <template #icon>
        <IconPowershell></IconPowershell>
      </template>
    </AlertError>
  </div>
</template>

