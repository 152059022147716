export const unpackPipeMessage = (data) => {
    const message = JSON.parse(data)
    const [pipeName, payload] = Object.entries(message)[0]
    const eventName = message.event

    return {
        pipeName,
        eventName,
        payload
    }
}