const inProduction = process.env.NODE_ENV !== 'development'
const USING_TLS = inProduction
const PROTOCOL = USING_TLS ? 'https:' : 'http:'
const PORT = process.env.PORT || 3059

const settings = {
  appId: 'app',
  appTitle: 'Center for Hybrid Intelligence',

  apiPath: '/api/v1',
  basePath: inProduction ? '/chi-static' : '/',
  protocol: PROTOCOL,
  appPort: PORT,

  debugLevels: false,

  inProduction,
  USING_TLS,
}
module.exports = settings
