
export default [
    /*
        This file configures the /app/[app-name] route and its children

        - do not lead paths with a slash. all paths are (nested) child-paths of the /app path
        - the home view is generated by the chi-client-framework and uses /components/views/HomeView.vue

     */
    {
        name: 'activateAccount',
        path: "/activateAccount/:activateAccountToken",
        meta: {type: 'authentication'},
        props: true,
        component: () =>
            import("@/../chi-app/components/authentication/ActivateAccountWithToken.vue"),
    },
    {
        name: 'activateAcademicAccount',
        path: "/activateAcademicAccount/:activateAccountToken",
        meta: {type: 'authentication'},
        props: true,
        component: () =>
            import("@/../chi-app/components/authentication/ActivateAcademicAccountWithToken.vue"),
    },
    {
        name: 'resetPassword',
        path: "/resetPassword/:resetPasswordToken",
        meta: {type: 'authentication'},
        props: true,
        component: () => import("@/../chi-app/components/authentication/ResetPasswordWithToken.vue"),
    },
    {
        name: 'changeAccountEmail',
        path: "/changeAccountEmail/:changeAccountEmailToken",
        meta: {type: 'authentication'},
        props: true,
        component: () =>
            import("@/../chi-app//components/authentication/ChangeAccountEmailWithToken.vue"),
    },
    {
        name: 'activateNewEmail',
        path: "/activateNewEmail/:activateNewEmailToken",
        meta: {type: 'authentication'},
        props: true,
        component: () =>
            import("@/../chi-app/components/authentication/ActivateNewEmailWithToken.vue"),
    },
    {
        name: 'downloadMyData',
        path: "/downloadMyData/:downloadMyDataToken",
        meta: {type: 'authentication'},
        props: true,
        component: () =>
            import("@/../chi-app/components/authentication/DownloadMyDataWithToken.vue"),
    },
    {
        name: 'forgetAboutMe',
        path: "/forgetAboutMe/:forgetAboutMeToken",
        meta: {type: 'authentication'},
        props: true,
        component: () =>
            import("@/../chi-app/components/authentication/ForgetAboutMeWithToken.vue"),
    },
    {
        name: 'reviewAppRoleRequest',
        path: "/reviewAppRoleRequest/:roleRequestToken",
        meta: {
            requireAppAdmin: true,
        },
        props: true,
        component: () =>
            import("@/../chi-app/components/authentication/ReviewAppRoleRequest.vue"),
    },
    {
        name: 'reviewRoleRequest',
        path: "/reviewRoleRequest/:roleRequestToken",
        props: true,
        component: () =>
            import("@/../chi-app/components/authentication/ReviewRoleRequest.vue"),
        meta: {
            requireAppAdmin: true,
        }
    },
    // cloud
    {
        name: 'cloud',
        path: '/cloud',
        component: () => import('@/../chi-app/components/views/cloud/CloudHome.vue'),
    },
]