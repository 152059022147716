import {useFormField} from "@/composables/form/useFormField";
import {isEmailAddress} from "@/lib/typeHelpers/stringFunctions/isEmailAddress";
import {toRefs} from "vue";

export const useEmailField = (name, document, options) => {
    options = options || {};
    options.name = name;
    options.isOptional = !options.isRequired || false;
    options.tests = options.tests ?? [
        {
            title: 'Should be an email address',
            validator: v => isEmailAddress(v) ? '' : `${name} should be an email address`
        },
    ];
    const {revertValue, ...refs} = useFormField(document, name, options)
    return {
        ...toRefs(refs),
        revertValue
    }
}

