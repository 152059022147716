<script>
import MenuBar from "../../MenuBar.vue";
import LView from "@/components/layout/LView.vue";
import "../../../app.css";
import LSection from "@/components/layout/LSection.vue";

export default {
  name: "LHome",
  components: {
    LView,
    LSection,
    MenuBar,
  }
}
</script>

<template>
  <div class="lHome chi">
    <LView>
      <template #menubar>

        <MenuBar
            class="fixed w-full h-20 px-2 top-0"
            style="z-index: 1000;"
        >
          <template #logo>
            <div class="flex items-center gap-4">
              <img src="../../../../src/assets/images/Logo_CHI.png" alt="CHI Logo" class="cover h-20">
            </div>
          </template>
          <template #name>
            <h1 class="heading1 !mb-0">
              CHI Application Services
            </h1>
          </template>
        </MenuBar>

      </template>
      <template #default>

        <LSection class="mx-2">
          <div class="h-full flex-grow flex flex-col pt-20">
            <router-view></router-view>
          </div>
        </LSection>

      </template>
    </LView>
  </div>
</template>

<style scoped>

</style>