<script>
import {computed, ref, watchEffect} from "vue";
import AssistantRunStream from "../../AssistantRunStream.vue";
import LOpenAIThread from "../layout/LOpenAIThread.vue";
import LoadingSpinner from "../../../Workshop/LoadingSpinner.vue";
import StatusMessage from "@/components/status/StatusMessage.vue";
import DeleteButton from "../../../Button/DeleteButton.vue";
import {
  DELETE_CHI_ASSISTANT_THREAD,
  LOAD_CHI_ASSISTANT_THREAD,
} from "../../../../operations";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import LThreadMessage from "../../../layout/LThreadMessage.vue";
import ThreadMessageInput from "@/components/CHIThread/CHIStream/ThreadMessageInput.vue";
import {useSendAssistantStreamMessage} from "../../../../composables/useSendAssistantStreamMessage";
import ToolCall from "../../../../../src/components/CHIThread/CHIStream/streamItem/toolCalls/ToolCall.vue";
import StreamItem from "@/components/CHIThread/CHIStream/streamItem/StreamItem.vue";
import {generateRandomString} from "@/lib/typeHelpers/stringFunctions/generateRandomString";
import {createEvent} from "@/components/CHIThread/CHIStream/lib/createEvent";

export default {
  name: "OpenAIThread", // todo: extract CHI Thread (no assistant knowledge)
  emits: [
    'created'
  ],
  components: {
    StreamItem,
    AssistantRunStream,
    DeleteButton,
    LoadingSpinner,
    LOpenAIThread,
    LThreadMessage,
    StatusMessage,
    ThreadMessageInput,
    ToolCall,
  },
  props: {
    _assistantId: {
      type: String,
    },
    thread_id: {
      type: String,
    },
    showToolsAside: {
      type: Boolean,
      default: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    const threadId = ref(props.thread_id)
    const isLoadingMessages = ref(!!props.thread_id)
    const isRequesting = ref(false)
    const isInputFocused = ref(false);

    // reconstruct previous stream
    const messages = computed(() => Object.values(store.state.docs.Message ?? {})
        .filter(item =>
            item.thread_id === props.thread_id
        ))
    const runs = computed(() => Object.values(store.state.docs.Run ?? {})
        .filter(item =>
            item.thread_id === props.thread_id
        ))
    const steps = computed(() => Object.values(store.state.docs.Step ?? {})
        .filter(item =>
            item.thread_id === props.thread_id
        ))
    const calls = computed(() => Object.values(store.state.docs._Call ?? {})
        .filter(item =>
            item.thread_id === props.thread_id
        ))

    const deltas = ref([])
    const statusEvents = ref([])
    const streamItems = ref([])
    const startTime = Date.now()

    watchEffect(() => {
      streamItems.value = [
        ...messages.value
      ]
          .concat(runs.value)
          .concat(steps.value)
          .concat(calls.value)
          .concat(deltas.value)
          .map(component => {
            const data = {
              created_at: component.started_at || component.created_at || Date.now(),
              _dataType: component._dataType,
            }
            if (component.id) {
              data.id = component.id
            }

            return createEvent('Component', data)
          })
          .concat(statusEvents.value.map(event =>
              createEvent(
                  event.type,
                  event.data
              )
          ))
          .filter(v => !!v)
          .map(item => ({
            ...item,
            key: generateRandomString(12, 'alphanumeric'), // for rendered list item component key
          }))
    })

    const previousItems = computed(() =>
        streamItems.value
            .filter(item => item.timestamp < startTime)
            .sort((a, b) =>
                a.timestamp - b.timestamp // ascending timestamp
            )
    )

    const message = ref('')
    const assistant = computed(() => store.state.docs.Assistant?.[props._assistantId])
    const name = computed(() => assistant.value?.name)
    const toolCalls = computed(() => Object.values(store.state.docs?._ToolCall ?? {})
        .reduce((callIds, s) => {
          const stepId = s.id
          s.step_details?.tool_calls
              .forEach(call => callIds.push([stepId, call.id]))
          return callIds
        }, [])
    )

    // user actions
    const onDeleteThread = () => {
      store.dispatch(DELETE_CHI_ASSISTANT_THREAD, {
        _assistantId: props._assistantId,
        thread_id: props.thread_id,
      })
          .then(result => {
            console.log(3, props._assistantId, result)
            router.push({name: 'assistant', params: {_assistantId: props._assistantId}})
          })
    }

    const {
      pipeName,
      sendMessage
    } = useSendAssistantStreamMessage({
      _assistantId: props._assistantId,
      thread_id: props.thread_id,
      onError: (err) => {
        console.warn('Could not send stream message', err)
        throw err
      },
    })

    const onSubmit = (payload) =>
        sendMessage(payload)

    if (!!props.thread_id) {
      store.dispatch(LOAD_CHI_ASSISTANT_THREAD, {
        _appId: 'assistants',
        thread_id: props.thread_id,
      })
          .finally(() => {
            isLoadingMessages.value = false
          })
    }

    const onThread = thread_id => {
      console.log(123, thread_id)
      threadId.value = thread_id
    }
    const onBusy = (value) => {
      isRequesting.value = value
    }

    return {
      DELETE_CHI_ASSISTANT_THREAD,
      LOAD_CHI_ASSISTANT_THREAD,

      isInputFocused,
      isLoadingMessages,
      isRequesting,
      message,
      name,
      toolCalls,
      previousItems,

      pipeName,
      threadId,

      onBusy,
      onDeleteThread,
      onSubmit,
      onThread,
    }
  }
}
</script>

<template>
  <div class="openAIThread w-full">
    <LOpenAIThread
        :show-message-input="true"
        :showToolsAside="showToolsAside"
    >

      <template #status>
        <StatusMessage class="LOAD_CHI_ASSISTANT_THREAD" :operation="LOAD_CHI_ASSISTANT_THREAD"></StatusMessage>
        <StatusMessage class="DELETE_CHI_ASSISTANT_THREAD" :operation="DELETE_CHI_ASSISTANT_THREAD"></StatusMessage>
      </template>

      <template #default>
        <StreamItem
            v-for="(item) in previousItems"
            :key="item.key"
            :staticParams="{_assistantId}"
            logLevel="info"
            :type="item.type ?? 'event'"
            :timestamp="item.timestamp"
            :data="item.data"
            :item="item"
        ></StreamItem>
        <AssistantRunStream
            v-if="!isLoadingMessages"
            :pipeName="pipeName"
            :_assistantId="_assistantId"
            :thread_id="thread_id"
            @busy="onBusy"
            @thread="onThread"
        ></AssistantRunStream>

      </template>

      <template #input>
        <ThreadMessageInput
            :thread_id="threadId"
            :isBusy="isRequesting"
            @submit="onSubmit"
        ></ThreadMessageInput>
      </template>

      <template #toolCalls>
        <ToolCall
            v-for="[stepId, id] in toolCalls"
            :key="id"
            :stepId="stepId"
            :id="id"
        ></ToolCall>
      </template>
    </LOpenAIThread>

  </div>
</template>

