<script>
import LSection from "@/components/layout/LSection.vue";
import {computed} from "vue";
import {useStore} from "vuex";
import {LOAD_CHI_ASSISTANT} from "../../operations";

export default {
  name: "Chats",
  props: {
    _assistantId: {
      type: String,
      required: true
    },
  },
  components: {
    LSection,
  },
  setup(props) {
    const store = useStore()

    store.dispatch(LOAD_CHI_ASSISTANT, {
      _id: props._assistantId
    })

    const isLoading = computed(() => !store.state.docs.Assistant?.[props._assistantId]?._id ?? true)
    const assistantName = computed(() => store.state.docs.Assistant?.[props._assistantId]?._name ?? '')
    const assistantAvatarSrc = computed(() => store.state.docs.Assistant?.[props._assistantId]?.avatarSrc ?? '')

    const threads = computed(() => Object.values(store.state.docs.Thread ?? {})
        .filter(thread => thread._assistantId === props._assistantId)
        .map(thread => ({
          ...thread,
          avatar: thread.avatarSrc ?? '',
        }))
    )

    const hostName = computed(() => {
      const hostId = store.state.docs.Assistant?.[props._assistantId]?._createdBy ?? ''
      const host = store.state.docs.Account?.[hostId]
      return host.organisationName ?? host?.name ?? ''
    })

    return {
      isLoading,
      assistantAvatarSrc,
      assistantName,
      hostName,

      threads,
    }
  }
}
</script>

<template>
  <div class="view chats pt-20">
    <LSection>
      <div v-if="!isLoading">
        <div class="heading1">Loading chats...</div>
      </div>
      <div v-else>
        <div class="heading1">
          Your chats with {{ assistantName }} by {{ hostName }}
        </div>
        <Threads
            :_assistantId="_assistantId"
        ></Threads>
      </div>
    </LSection>
  </div>
</template>

<style scoped>

</style>