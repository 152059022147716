<script>
import {computed, inject, ref} from "vue";
import IconPlay from "@/components/icons/Play.vue";
import IconStop from "@/components/icons/Stop.vue";
import IconCheck from "@/components/icons/Check.vue";
import IconTimerCancel from "@/components/icons/TimerCancel.vue";
import IconCancel from "@/components/icons/Cancel.vue";
import IconCloseThick from "@/components/icons/CloseThick.vue";
import IconBroadcastOff from "@/components/icons/BroadcastOff.vue";

const timeFormat = 'HH:mm:ss'

export default {
  name: "StreamItemState",
  emits: ['cancel'],
  components: {
    IconPlay,
    IconStop,
    IconCheck,
    IconTimerCancel,
    IconCancel,
    IconCloseThick,
    IconBroadcastOff,
  },
  props: {
    itemState: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props, {emit}) {
    const dayjs = inject('DAYJS')

    const showCancelButton = ref(false)

    const status = computed(() => props.itemState?.status ?? '')
    const component = computed(() => {
      switch (status.value) {
        case 'running':
          return 'IconPlay'
        case 'completed':
          return 'IconCheck'
        case 'failed':
          return 'IconCloseThick'
        case 'cancelled':
          return 'IconCancel'
        case 'expired':
          return 'IconTimerCancel'
        default:
          return 'IconBroadcastOff'
      }
    })

    const title = computed(() => {
      let title = 'no data'

      const {
        status,
        created_at,
        started_at,
        completed_at,
        failed_at,
        cancelled_at,
        expired_at,
      } = props.itemState ?? {}
      const start_timestamp = started_at || created_at

      if (status === 'running') {
        title = `running for ${dayjs(start_timestamp).fromNow()}.`
        if (showCancelButton.value) title += ' Click to cancel.'
      } else if (completed_at) {
        title = `completed at ${dayjs(completed_at).format(timeFormat)} after ${dayjs(completed_at).from(start_timestamp, true)}.`
      } else if (failed_at) {
        title = `failed at ${dayjs(failed_at).format(timeFormat)} after ${dayjs(failed_at).from(start_timestamp, true)}.`
      } else if (cancelled_at) {
        title = `cancelled at ${dayjs(cancelled_at).format(timeFormat)} after ${dayjs(cancelled_at).from(start_timestamp, true)}.`
      } else if (expired_at) {
        title = `expired at ${dayjs(expired_at).format(timeFormat)} after ${dayjs(expired_at).from(start_timestamp, true)}.`
      }

      return title
    })

    const onMouseOver = () => {
      showCancelButton.value = status.value === 'running'
    }
    const onMouseOut = () => {
      showCancelButton.value = false
    }
    const onCancel = () => {
      if (status.value === 'running') emit('cancel')
    }

    return {
      component,
      onCancel,
      onMouseOut,
      onMouseOver,
      showCancelButton,
      status,
      title,
    }
  }
}
</script>

<template>
  <div
      class="
          StreamItemState
          p-1
          flex items-center justify-center
      "
      :class="{
        'bg-green-400': status === 'completed',
        'bg-red-500 text-white': status === 'failed',
        'bg-orange-400': status === 'expired' || showCancelButton,
        'bg-slate-400': status === 'cancelled',
        'bg-yellow-400': status === 'running',
      }"
      :title="title"
      @mouseover="onMouseOver"
      @mouseout="onMouseOut"
      @click="onCancel"
  >
    <component :is="component"></component>
  </div>
</template>
