<script>
import LView from "@/components/layout/LView.vue";
import IconHome from "../../icons/Home.vue";
import "../../../creavisions.css";
import {
  GET_CREA_VISIONS_SESSION,
  LOG_CREA_VISIONS_ENTRY,
} from "../../../operations";
import { useStore } from "vuex";
import { computed, onBeforeUnmount, ref } from "vue";
import MenuBar from "../../CreaVisionsMenuBar.vue";

const isInDevelopment = process.env.NODE_ENV === "development";

let debug = true;

debug = debug && isInDevelopment;

export default {
  name: "LHome",
  components: {
    MenuBar,
    IconHome,
    LView,
  },
  setup() {
    const store = useStore();
    store.state.eventName = "DOKK1 - Vilde Teknologier 2024";
    store.state.UI.showEngagementSurvey = false;
    store.state.hasParticipated = false;
    store.state.UI.hasSubmittedPriorities = false;

    const hasAnswered = ref(false);

    const hasSubmittedPriorities = computed(
      () => store.state.UI.hasSubmittedPriorities
    );

    store
      .dispatch(GET_CREA_VISIONS_SESSION, {
        eventName: store.state.eventName,
      })
      .then(({ data }) => {
        const { User, CVSession } = data;
        store.state.user = User[0];
        store.state.session = CVSession[0];
      });

    onBeforeUnmount(() => {
      store.dispatch(LOG_CREA_VISIONS_ENTRY, {
        action: "end session",
        eventName: store.state.eventName,
      });
    });

    const onEndParticipation = () => {
      if (hasAnswered.value) return;
      store
        .dispatch(LOG_CREA_VISIONS_ENTRY, {
          action: "start participation survey",
          eventName: store.state.eventName,
        })
        .then(() => (hasAnswered.value = true));
      store.state.UI.showEngagementSurvey = true;
    };
    return {
      hasSubmittedPriorities,
      onEndParticipation,
    };
  },
};
</script>

<template>
  <div class="lHome creavisions">
    <LView :use-margins="false">
      <template v-if="hasSubmittedPriorities" #menubar>
        <MenuBar
          class="sticky top-0 p-4 drop-shadow-md h-22"
          style="z-index: 1000"
        >
          <template #logo>
            <div class="flex items-center gap-4">
              <img
                src="../../../../src/assets/images/Logo_CHI.png"
                alt="CHI Logo"
                class="cover h-20"
              />
            </div>
          </template>
          <template #name>
            <h1 class="heading1 !mb-0">CREA Visions</h1>
          </template>
        </MenuBar>
      </template>
      <template #default>
        <div
            class="flex flex-col h-full w-full relative"
        >
          <router-view></router-view>
        </div>
      </template>
    </LView>
  </div>
</template>
