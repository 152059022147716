import {PATCH_CHI_FILE} from "../operations";
import {_RESET_STATUS} from "@/store/operations";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import axios from "@/plugins/axios";
import {handleResponse} from "@/store/actions/lib/handleResponse";

export default {
    [PATCH_CHI_FILE] (context, {id, attributes}) {
        context.commit(_RESET_STATUS, PATCH_CHI_FILE)
        const url = `/api/v1/File/${id}`
        return throttleBufferedAction({
            action: axios.patch,
            url,
            params: {
                attributes
            }
        })
            .then(handleResponse(context, PATCH_CHI_FILE))
    }
}