<script>
export default {
  name: "GamifiedButton",
  emits: ["click"],
  props: {
    label: {
      type: String,
      default: 'Click Me',
    },
    type: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, {emit}) {
    const onClick = e => emit("click", e);

    return {
      onClick,
    };
  },
};
</script>

<template>
  <button
      :type="type"
      :disabled="disabled"
      class="gamifiedButton"
      @click="onClick"
  >
    <span class="reflection_1"></span>
    <span class="reflection_2"></span>
    <span class="buttonContent">

    {{ label }}
    </span>
  </button>
</template>
