import {
    _PUT_STATUS,
    ACTIVATE_ACCOUNT,
    ACTIVATE_NEW_EMAIL,
    CHANGE_PASSWORD,
    CONFIRM_CHANGE_EMAIL,
    CONFIRM_DOWNLOAD_MY_DATA,
    CONFIRM_FORGET_ABOUT_ME,
    RESET_PASSWORD,
    GET_USER,
    LIST_USERS,
    LOGIN,
    LOGOUT,
    REQUEST_ACCOUNT,
    REQUEST_CHANGE_EMAIL,
    REQUEST_FORGET_USER,
    REQUEST_PASSWORD_RESET,
    _RESET_ASSETS,
    GRANT_ROLE,
    REVOKE_ROLE,
    VERIFY_ROLE_REQUEST,
    DENY_ROLE,
    VERIFY_APP_ROLE_REQUEST,
    VERIFY_ACTIVATE_ACCOUNT_TOKEN,
    VERIFY_RESET_PASSWORD_TOKEN,
    GET_ACCOUNT_ROLES, ENTER_SESSION_KEY, ENTER_SESSION_KEY_WITH_USER_ID
} from "@/store/operations";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import axios from "@/plugins/axios";
import {
    getAssetActionUrl, getAssetUrl,
    getUserAccountActionUrl,
    getUserAccountConfirmationUrl,
    getUserAccountsUrl,
    getUserAccountUrl, getUserAccountVerificationUrl
} from "@/store/lib/apiUrls";
import {state} from "@/store/store";
import {capitalize} from "@/lib/typeHelpers";
import {handleResponse} from "@/store/actions/lib/handleResponse";

export default {

    [LIST_USERS](context, {offset, pageSize, filter}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getUserAccountsUrl(),
            params: {
                offset,
                pageSize,
                filter: JSON.stringify(filter)
            }
        })
            .then(handleResponse(context, LIST_USERS))
    },
    [GET_USER](context, _id) {
        return throttleBufferedAction({
            action: axios.get,
            url: getUserAccountUrl(_id)
        })
            .then(handleResponse(context, GET_USER))
    },
    [LOGIN](context, {email, password}) {
        return axios.post(
            getUserAccountActionUrl(email, 'login'),
            {
                password
            },
        )
            .then(async response => {
                context.commit(
                    LOGIN,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: LOGIN, result: response.data})

                return response
            })
    },
    [ENTER_SESSION_KEY](context, {key}) {
        return axios.post(
            getUserAccountActionUrl(key, 'getAnonymousSession'),
        )
            .then(async response => {
                context.commit(
                    LOGIN,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: LOGIN, result: response.data})

                return response
            })
    },
    [ENTER_SESSION_KEY_WITH_USER_ID](context, {key}) {
        return axios.post(
            getUserAccountActionUrl(key, 'getSingleUseSession'),
        )
            .then(async response => {
                context.commit(
                    LOGIN,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: LOGIN, result: response.data})

                return response
            })
    },
    [LOGOUT](context) {
        context.commit(_RESET_ASSETS)
        context.commit(LOGOUT)
        return axios.put(
            getUserAccountUrl('logout'),
        )
            .then(() => {
                context.commit(_PUT_STATUS, {event: LOGOUT})
            })
            .finally(() =>
                context.commit(LOGOUT)
            )
    },
    [CHANGE_PASSWORD](context, {_id, passphrase, newPassphrase}) {
        return axios.put(
            getUserAccountActionUrl({
                _id,
                actionName: 'password',
            }),
            {
                passphrase,
                newPassphrase
            }
        )
            .then(handleResponse(context, CHANGE_PASSWORD))
    },
    [REQUEST_ACCOUNT](context, attributes) {
        return throttleBufferedAction({
            action: axios.post,
            url: getUserAccountUrl('requestAccount'),
            params: {
                ...attributes
            }
        })
            .then(handleResponse(context, REQUEST_ACCOUNT))
    },
    [REQUEST_FORGET_USER](context, _id) {
        return axios.post(
            getUserAccountActionUrl(_id, 'forgetMe'),
        )
            .then(handleResponse(context, REQUEST_FORGET_USER))
    },
    [REQUEST_PASSWORD_RESET](context, {_email, _redirectTo}) {
        return axios.post(
            getUserAccountActionUrl(_email, 'resetPassword'),
            {
                _redirectTo
            }
        )
            .then(handleResponse(context, REQUEST_PASSWORD_RESET))
    },
    [REQUEST_CHANGE_EMAIL](context, {_id, newEmail}) {
        return axios.post(
            getUserAccountActionUrl(_id, 'changeEmail'),
            {
                newEmail
            }
        )
            .then(handleResponse(context, REQUEST_CHANGE_EMAIL))
    },

    // with token
    [ACTIVATE_ACCOUNT](
        context,
        {
            activateAccountToken,
            firstName,
            lastName,
            title,
            password,
        }
    ) {
        return axios.post(
            getUserAccountConfirmationUrl(
                'activateAccount',
                activateAccountToken
            ),
            {
                title,
                firstName,
                lastName,
                password,
            }
        )
            .then(handleResponse(context, ACTIVATE_ACCOUNT))
    }
    ,
    [VERIFY_ACTIVATE_ACCOUNT_TOKEN](
        context,
        {activateAccountToken},
    ) {
        return axios.get(
            getUserAccountVerificationUrl(
                'activateAccountToken',
                activateAccountToken
            )
        )
            .then(handleResponse(context, VERIFY_ACTIVATE_ACCOUNT_TOKEN))
    }
    ,
    [VERIFY_RESET_PASSWORD_TOKEN](
        context,
        {resetPasswordToken},
    ) {
        return axios.get(
            getUserAccountVerificationUrl(
                'resetPasswordToken',
                resetPasswordToken
            )
        )
            .then(handleResponse(context, VERIFY_RESET_PASSWORD_TOKEN))
    }
    ,
    [VERIFY_ROLE_REQUEST](
        context,
        verifyRoleRequestToken,
    ) {
        return axios.post(
            getUserAccountConfirmationUrl(
                'verifyRoleRequest',
                verifyRoleRequestToken
            ),
        )
            .then(handleResponse(context, VERIFY_ROLE_REQUEST))
    }
    ,
    [VERIFY_APP_ROLE_REQUEST](
        context,
        verifyRoleRequestToken,
    ) {
        return axios.post(
            getUserAccountConfirmationUrl(
                'verifyAppRoleRequest',
                verifyRoleRequestToken
            ),
        )
            .then(handleResponse(context, VERIFY_ROLE_REQUEST))
    },
    [RESET_PASSWORD](context, {resetPasswordToken, newPassword}) {
        return axios.post(
            getUserAccountConfirmationUrl('resetPassword', resetPasswordToken),
            {
                newPassword
            },
        )
            .then(handleResponse(context, RESET_PASSWORD))
    }
    ,
    [CONFIRM_DOWNLOAD_MY_DATA](context, {downloadMyDataToken}) {
        return axios.post(
            getUserAccountConfirmationUrl('downloadMyData', downloadMyDataToken),
        )
            .then(handleResponse(context, CONFIRM_DOWNLOAD_MY_DATA))
    }
    ,
    [CONFIRM_FORGET_ABOUT_ME](context, {forgetAboutMeToken}) {
        return axios.post(
            getUserAccountConfirmationUrl('forgetAboutMe', forgetAboutMeToken),
        )
            .then(handleResponse(context, CONFIRM_FORGET_ABOUT_ME))
    }
    ,
    [CONFIRM_CHANGE_EMAIL](context, {changeEmailToken}) {
        return axios.post(
            getUserAccountConfirmationUrl('changeEmail', changeEmailToken),
        )
            .then(handleResponse(context, CONFIRM_CHANGE_EMAIL))
    }
    ,
    [ACTIVATE_NEW_EMAIL](context, {newEmailToken}) {
        return axios.post(
            getUserAccountConfirmationUrl('activateNewEmail', newEmailToken),
        )
            .then(handleResponse(context, ACTIVATE_NEW_EMAIL))
    }
    ,
// [REQUEST_ROLE](context, {role}) {
//     return axios.post(
//             getUserAccountUrl('requestRole') + `/${role}`,
//     )
//             .then(async response => {
//                 context.commit(
//                         GET_ASSET,
//                         response.data
//                 )
//                 context.commit(_PUT_STATUS, {event: REQUEST_ROLE, result: response.data})
//                 return response
//             })
// },
    [GRANT_ROLE](context, {_appId, roleEntry, userId}) {
        return axios.post(
            getAssetActionUrl({
                _appId,
                _dataType: 'Account/grantRole',
                postfix: userId,
                actionName: roleEntry
            }),
        )
            .then(handleResponse(context, GRANT_ROLE))
    }
    ,
    [DENY_ROLE](context, {roleEntry, userId, reason = ''}) {
        const roleName = capitalize(roleEntry.split('_')[2])
        return axios.delete(
            getAssetUrl({
                dataType: `deny${roleName}RoleRequest`,
                postfix: userId,
            }),
            {
                reason
            }
        )
            .then(handleResponse(context, DENY_ROLE))
    }
    ,
    [REVOKE_ROLE](context, {roleEntry, userId}) {
        return axios.delete(
            getUserAccountUrl('revokeRole') + `/${userId}/${roleEntry}`,
        )
            .then(handleResponse(context, REVOKE_ROLE))
    }
    ,
    [GET_ACCOUNT_ROLES](context) {
        if (state.roleDebounce && state.roleDebounce > Date.now()) {
            return new Promise(resolve => resolve())
        }
        state.roleDebounce = Date.now() + (1000 * 60 * 20)
        return axios.post(
            getUserAccountUrl("getAccountRoles"),
        )
            .then(handleResponse(context, GET_ACCOUNT_ROLES))
    }
    ,
}