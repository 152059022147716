<script>
import IconWebSocket from "@/components/icons/WebSocket.vue";
import AlertError from "@/components/AlertError.vue";

export default {
  name: "SocketError",
  components: {
    AlertError,
    IconWebSocket
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <div class="socketError">
    <AlertError
        name="Web Socket Error"
        :message="message"
    >
      <template #icon>
        <IconWebSocket></IconWebSocket>
      </template>
    </AlertError>
  </div>
</template>

