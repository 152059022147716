import { isObject } from '../getVariableType.js'
import { deepCompare } from './deepCompare.js'

export const areSame = (a, b) => {
  let same = true
  if (isObject(a) && isObject(b)) {
    same = deepCompare(a, b)
  } else {
    same = a === b
  }
  return same
}
