<script>
import {onPlayGame} from "./lib/game";
import {useRouter} from "vue-router";
import {state as game} from './lib/game.js'
import {computed} from "vue";
import LGamePage from "../layout/LGamePage.vue";
import LSection from "@/components/layout/LSection.vue";

export default {
  name: "Welcome",
  components: {
    LSection,
    LGamePage,
  },
  setup() {
    const router = useRouter()
    const totalQuestions = computed(() => game.totalQuestions)
    const onStart = () => {
      router.push({name: 'quiz-time-game'})
    }
    return {
      onStart,
      totalQuestions,
    };
  },
};
</script>
<template>
  <LSection>

    <LGamePage>
      <template #title>
        Quiz Time
        <h2 class="gamifiedh2">
          It's time to put your knowledge to the test
        </h2>
      </template>
      <template #default>
        <p class="leading-relaxed text-md ">
          We prepared
          <strong>{{ totalQuestions }}</strong> questions divided into three interesting categories.
          <br>
          Your task is simple: pick the correct answer from the multiple choices provided for each question.
          <br>
        </p>
        <p>
          Let's see how many you can get right!
        </p>
      </template>
      <template #actions>
        <button
            class="button text-xl "
            @click="onStart"
        >
          Start Quiz
        </button>
      </template>
    </LGamePage>
  </LSection>

</template>

