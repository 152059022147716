
<script>
import {computed, onMounted, reactive, ref, watchPostEffect} from "vue";
import Button from "@/components/forms/Button.vue";
import {isEmailAddress} from "@/lib/typeHelpers/stringFunctions/isEmailAddress";
import LFieldError from "@/components/layout/LFieldError.vue";
import {useStore} from "vuex";
import {REQUEST_ACCOUNT} from "@/store/operations";
import StatusMessage from "@/components/status/StatusMessage.vue";
import {useStatusMessage} from "@/composables/useStatusMessage";
import TextInput from "@/components/forms/TextInput.vue";
import {useRoute} from "vue-router";

export default {
  name: "RegisterAccount",
  components: {
    TextInput,
    Button,
    LFieldError,
    StatusMessage,
  },
  setup() {
    const route = useRoute();
    const store = useStore()
    const form = ref(null);
    const altKey = ref(false);
    const actionError = ref("");
    // isSent is used to show a different message after the user has submitted the form
    const isSent = ref(false);
    const isSubmitted = ref(false);
    const hasSucceeded = ref(false);
    const inputError = reactive({});

    const withAcademicTitle = ref(route.query.academic === "true")

    const isValidAccount = computed(
        () =>
            !isSent.value &&
            account.firstName &&
            account.lastName &&
            account.email &&
            route.query.redirect && // todo: show error for this missing
            Object.values(inputError).every((msg) => msg === null)
    );

    const canSubmit = computed(() => isValidAccount.value)

    const account = reactive({
      title: '',
      firstName: null,
      lastName: null,
      email: null,
    });

    const {setMessage, deleteMessage} = useStatusMessage(REQUEST_ACCOUNT)

    const onUpdateAccount = (property, value, required) => {
      if (value) value = value.trim()
      Object.assign(account, {[property]: value});
      if (required && !value) {
        inputError[property] = "This field is required";
      } else {
        inputError[property] = null;
      }
    };

    watchPostEffect(() => {

      const {
        email
      } = account;

      inputError.email =
          (email && !isEmailAddress(email))
              ? "Invalid email address"
              : null

      // todo: do not remove this log, hack for resolving digestion race condition
      console.log(3, email, email && !isEmailAddress(email), inputError.email)

    });

    onMounted(() => {
      window.scroll(0, 0)
      deleteMessage()
    })

    const submitUserAccountRequest = () => {

      if (!isValidAccount.value) return;

      actionError.value = "";

      console.log("Requesting user account", account);
      store.dispatch(REQUEST_ACCOUNT, {
        _email: account.email,
        _firstName: account.firstName,
        _lastName: account.lastName,
        title: account.title,
        _redirectTo: route.query.redirect,
        // accountAttributes
      })
          .then(result => {
            isSubmitted.value = true
            hasSucceeded.value = true
            return result
          })
          .catch(err => {
            console.log("Error requesting user account", err);
            setMessage({isSuccess: false, body: err.message})
          })
          .finally(() => {
            isSent.value = false;
          });

      // reset form
      Object.keys(account).forEach(key => delete account[key])
      isSent.value = true

    };

    const onSubmit = () => submitUserAccountRequest()

    return {
      account,
      actionError,
      altKey,
      canSubmit,
      hasSucceeded,
      inputError,
      isSent,
      isSubmitted,
      isValidAccount,
      form,
      onSubmit,
      onUpdateAccount,
      withAcademicTitle,
    };
  },
};
</script>
<template>
  <div class="registerAccount">
    <div class="flex justify-center">
      <div>
        <form
            @submit.prevent=""
            @keydown.enter.prevent=""
            ref="form"
            class="form"
        >
          <div v-if="!isSent">
            <h1 class="heading1">
              Register an account
              <hr class="gradientLine"/>
            </h1>

            <StatusMessage operation="REQUEST_ACCOUNT"></StatusMessage>

            <div class="flex flex-col justify-center align-center">
              <div
                  v-if="!isSubmitted"
                  class="flex flex-col"
              >
                <div class="w-full mb-6">
                  <label for="firstName">First Name</label>
                  <TextInput
                      autofocus
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      :value="account.firstName"
                      @change="value => onUpdateAccount('firstName', value, true)"
                      @input="value => onUpdateAccount('firstName', value, true)"
                  ></TextInput>
                  <LFieldError :message="inputError.firstName" class="w-full">
                  </LFieldError>
                </div>
                <div class="w-full mb-6">
                  <label for="lastName">Last Name</label>
                  <TextInput
                      autofocus
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      :value="account.lastName"
                      @change="value => onUpdateAccount('lastName', value, true)"
                      @input="value => onUpdateAccount('lastName', value, true)"
                  ></TextInput>
                  <LFieldError :message="inputError.lastName" class="w-full">
                  </LFieldError>
                </div>
                <div
                    v-if="withAcademicTitle"
                    class="w-full mb-6"
                >
                  <label for="title">Academic Title</label>
                  <TextInput
                      autofocus
                      id="title"
                      name="title"
                      placeholder="Your Academic Title"
                      :value="account.title"
                      @change="value => onUpdateAccount('title', value)"
                  ></TextInput>
                  <LFieldError :message="inputError.name" class="w-full">
                  </LFieldError>
                </div>
                <div class="w-full mb-6">
                  <label for="email">Email address</label>
                  <TextInput
                      id="email"
                      name="email"
                      :value="account.email"
                      placeholder="Email address"
                      @input="value => onUpdateAccount('email', value, true)"
                      @change="value => onUpdateAccount('email', value, true)"
                      @submit="onSubmit"
                  ></TextInput>
                  <LFieldError :message="inputError.email"></LFieldError>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isSent">Please wait...</div>
          <div v-if="hasSucceeded">
            <div class="w-full mb-6">
              <p>
                Your request has been submitted. You will receive an email with
                further instructions.
              </p>
            </div>
          </div>
          <div
              v-if="isSubmitted"
              class="items-center justify-start flex flex-row"
          >
            <Button
                type="submit"
                :tabindex="13"
                :is-primary="true"
                @click="$router.push({query: {}})"
            >
              <template #right>Close</template>
            </Button>

          </div>
          <div
              v-if="!isSubmitted"
              class="items-center justify-between flex flex-row"
          >
            <Button
                class="button secondary"
                @click="$router.go(-1)"
                type="submit"
            >
              <template #right>Cancel</template>
            </Button>
            <Button
                type="submit"
                :is-primary="canSubmit && !isSubmitted"
                :is-disabled="!canSubmit || isSubmitted"
                @click="onSubmit"
                @keydown.enter="onSubmit"
            >
              <template #right>Register</template>
            </Button>

          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<style scoped></style>
