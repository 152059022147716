import {getSharedDocumentUrl} from "@/store/lib/apiUrls";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import axios from "@/plugins/axios";
import {handleResponse} from "@/store/actions/lib/handleResponse";
import {
    CREATE_SHARED_DOCUMENT,
    DELETE_SHARED_DOCUMENT,
    GET_SHARED_DOCUMENT,
    LIST_SHARED_DOCUMENTS,
    PATCH_SHARED_DOCUMENT,
    PUBLISH_SHARED_DOCUMENT,
    RETRACT_SHARED_DOCUMENT,
} from "@/store/operations/sharedDocumentOperations";

export default {
    [LIST_SHARED_DOCUMENTS](context,
                  {
                      _appId,
                      _dataType,
                      filter,
                      offset,
                      limit,
                      sorting,
                  }) {
        const options = {
            _appId,
            _dataType,
            offset,
            limit,
            sorting,
            filter
        }
        let url = getSharedDocumentUrl({
            _appId,
            _dataType,
            postfix: 'list'
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: options,
        })
            .then(handleResponse(context, LIST_SHARED_DOCUMENTS))
    },
    [CREATE_SHARED_DOCUMENT](context, {
        _appId,
        _dataType,
        _permissionHostId,
        attributes
    }) {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')

        const url = getSharedDocumentUrl({
            _appId,
            _dataType,
        })
        const params = {
            ...attributes,
        }

        if (_permissionHostId) params._permissionHostId = _permissionHostId

        return throttleBufferedAction({
            action: axios.post,
            url,
            params
        })
            .then(handleResponse(context, CREATE_SHARED_DOCUMENT))
    },
    [GET_SHARED_DOCUMENT](context,
                {
                    _appId,
                    _dataType,
                    _id,
                    _permissionHostId
                }) {
        const params = {}
        if (_permissionHostId) params._permissionHostId = _permissionHostId
        return throttleBufferedAction({
            action: axios.get,
            url: getSharedDocumentUrl({_appId, _dataType, postfix: _id}),
            params
        })
            .then(handleResponse(context, GET_SHARED_DOCUMENT))
    },
    [PATCH_SHARED_DOCUMENT](context,
                 {
                     _appId,
                     _dataType,
                     _id,
                     _permissionHostId,
                     attributes,
                 }) {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')
        if (!_id) throw new Error('_id is required')

        const url = getSharedDocumentUrl({
            _appId,
            _dataType,
            postfix: _id
        })
        const params = {
            attributes,
        }
        if (_permissionHostId) params._permissionHostId = _permissionHostId

        return throttleBufferedAction({
            action: axios.patch,
            url,
            params,
        })
            .then(handleResponse(context, PATCH_SHARED_DOCUMENT))
    },
    [PUBLISH_SHARED_DOCUMENT](context,
                           {
                               _appId,
                               _dataType,
                               _id,
                               _permissionHostId,
                               attributes,
                           }) {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')
        if (!_id) throw new Error('_id is required')

        const url = getSharedDocumentUrl({
            _appId,
            _dataType,
            postfix: `${_id}/publish`
        })
        const params = {
            ...attributes,
        }
        if (_permissionHostId) params._permissionHostId = _permissionHostId

        return throttleBufferedAction({
            action: axios.put,
            url,
            params,
        })
            .then(handleResponse(context, PUBLISH_SHARED_DOCUMENT))
    },
    [RETRACT_SHARED_DOCUMENT](context,
                           {
                               _appId,
                               _dataType,
                               _id,
                               _permissionHostId,
                               attributes,
                           }) {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')
        if (!_id) throw new Error('_id is required')

        const url = getSharedDocumentUrl({
            _appId,
            _dataType,
            postfix: `${_id}/retract`
        })
        const params = {
            ...attributes,
        }
        if (_permissionHostId) params._permissionHostId = _permissionHostId

        return throttleBufferedAction({
            action: axios.put,
            url,
            params,
        })
            .then(handleResponse(context, RETRACT_SHARED_DOCUMENT))
    },
    [DELETE_SHARED_DOCUMENT](context, {_appId, _dataType, _id}) {
        return throttleBufferedAction({
            action: axios.delete,
            url: getSharedDocumentUrl({
                _appId,
                _dataType,
                postfix: _id
            })
        })
            .then(handleResponse(context, DELETE_SHARED_DOCUMENT))
    },
}
