import {rigSinglePageAppEntryRoute} from "@/router/lib/rigSinglePageAppEntryRoute";

import appRoutes from "../../../chi-app/routes";
import apiRoutes from "../routes/apiRoutes";
import delphiAppRoutes from "../../../chi-delphi/routes";
import assistantsAppRoutes from "../../../chi-assistants/routes";
import creavisionsAppRoutes from "../../../chi-creavisions/routes";
import ccfAppRoutes from "../../../chi-ccf/routes";
import showcaseRoutes from "../../../chi-showcase/routes";

export const getAppRoutes = (appId) => {
    switch (appId) {
        case 'app': return rigSinglePageAppEntryRoute(appRoutes, appId)
        case 'api': return rigSinglePageAppEntryRoute(apiRoutes, appId)
        case 'delphi': return rigSinglePageAppEntryRoute(delphiAppRoutes, appId)
        case 'assistants': return rigSinglePageAppEntryRoute(assistantsAppRoutes, appId)
        case 'creavisions': return rigSinglePageAppEntryRoute(creavisionsAppRoutes, appId)
        case 'ccf': return rigSinglePageAppEntryRoute(ccfAppRoutes, appId)
        case 'showcase': return rigSinglePageAppEntryRoute(showcaseRoutes, appId)
        default: throw new Error(`No app entry route for ${appId}`)
    }
}
