import {LAV_LINKET_KORT} from "@/store/operations";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import {getApiUrl} from "@/store/lib/apiUrls";
import axios from "@/plugins/axios";
import {handleResponse} from "@/store/actions/lib/handleResponse";

export default {
    [LAV_LINKET_KORT](context, {url}) {
        return throttleBufferedAction({
            action: axios.post,
            url: `${getApiUrl()}/kortlink`,
            params: {url}
        })
            .then(handleResponse(context, LAV_LINKET_KORT))
    }
}