import {isStringWithLength} from "@/lib/typeHelpers/stringFunctions/isStringWithLength";

export const isEmailAddress = to => {
  if (!to) {
    return false
  }
  to = to.trim()
  let pass = isStringWithLength(to)
  return pass && /^[^@\s]+@[^\s@]+\.[^\s@]+$/.test(to)
}
