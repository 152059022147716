import {
    BATCH_IMPORT_ASSISTANT_FILES,
    CREATE_CHI_ASSISTANT,
    CREATE_CHI_ASSISTANT_THREAD,
    DELETE_CHI_ASSISTANT,
    DELETE_CHI_ASSISTANT_FILE,
    DELETE_CHI_ASSISTANT_THREAD,
    LIST_CHI_ASSISTANT_THREAD_MESSAGES,
    LOAD_CHI_ASSISTANT, LOAD_CHI_ASSISTANT_THREAD,
    PATCH_CHI_ASSISTANT,
    PUBLISH_CHI_ASSISTANT,
    PUBLISH_CHI_ASSISTANT_THREAD,
    RETRACT_CHI_ASSISTANT,
    RETRACT_CHI_ASSISTANT_THREAD,
    DEPRECATED_RUN_CHI_ASSISTANT, STREAM_CHI_ASSISTANT_RUN, SYNC_SPAWN_STREAMED_DOCUMENTS, LIST_CHI_ASSISTANT_THREADS
} from "../operations";
import {_RESET_STATUS} from "@/store/operations";
import {getApiUrl, getAssistantsUrl} from "@/store/lib/apiUrls";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import axios from "@/plugins/axios";
import {handleResponse} from "@/store/actions/lib/handleResponse";

export default {
    [CREATE_CHI_ASSISTANT](context, attributes) {
        context.commit(_RESET_STATUS, CREATE_CHI_ASSISTANT)
        let url = getAssistantsUrl('')
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                ...attributes,
            },
        })
            .then(handleResponse(context, CREATE_CHI_ASSISTANT))
    },
    [LOAD_CHI_ASSISTANT](context, {_id}) {
        context.commit(_RESET_STATUS, LOAD_CHI_ASSISTANT)
        let url = getAssistantsUrl(_id)
        return throttleBufferedAction({
            action: axios.get,
            url,
        })
            .then(handleResponse(context, LOAD_CHI_ASSISTANT))
    },
    [PATCH_CHI_ASSISTANT](context, {_id, attributes}) {
        context.commit(_RESET_STATUS, PATCH_CHI_ASSISTANT)
        let url = getAssistantsUrl(_id)
        return throttleBufferedAction({
            action: axios.patch,
            url,
            params: {
                attributes,
            },
        })
            .then(handleResponse(context, PATCH_CHI_ASSISTANT))
    },
    [PUBLISH_CHI_ASSISTANT](context, {_id}) {
        context.commit(_RESET_STATUS, PUBLISH_CHI_ASSISTANT)
        let url = getAssistantsUrl(_id) + '/publish'
        return throttleBufferedAction({
            action: axios.put,
            url,
        })
            .then(handleResponse(context, PUBLISH_CHI_ASSISTANT))
    },
    [RETRACT_CHI_ASSISTANT](context, {_id}) {
        context.commit(_RESET_STATUS, RETRACT_CHI_ASSISTANT)
        let url = getAssistantsUrl(_id) + '/retract'
        return throttleBufferedAction({
            action: axios.delete,
            url,
        })
            .then(handleResponse(context, RETRACT_CHI_ASSISTANT))
    },
    [DELETE_CHI_ASSISTANT](context, {_id}) {
        context.commit(_RESET_STATUS, DELETE_CHI_ASSISTANT)
        let url = getAssistantsUrl(_id)
        return throttleBufferedAction({
            action: axios.delete,
            url,
        })
            .then(handleResponse(context, DELETE_CHI_ASSISTANT))
    },
    [LIST_CHI_ASSISTANT_THREADS](context, {_assistantId}) {
        context.commit(_RESET_STATUS, LIST_CHI_ASSISTANT_THREADS)
        let url = getAssistantsUrl(`${_assistantId}/threads/list`)
        return throttleBufferedAction({
            action: axios.post,
            url
        })
            .then(handleResponse(context, LIST_CHI_ASSISTANT_THREADS))
    },
    [PUBLISH_CHI_ASSISTANT_THREAD](context, {_assistantId, thread_id}) {
        context.commit(_RESET_STATUS, PUBLISH_CHI_ASSISTANT_THREAD)
        let url = getAssistantsUrl(_assistantId) + `/threads/${thread_id}/publish`
        return throttleBufferedAction({
            action: axios.put,
            url,
        })
            .then(handleResponse(context, PUBLISH_CHI_ASSISTANT_THREAD))
    },
    [RETRACT_CHI_ASSISTANT_THREAD](context, {_assistantId, thread_id}) {
        context.commit(_RESET_STATUS, RETRACT_CHI_ASSISTANT_THREAD)
        let url = getAssistantsUrl(_assistantId) + `/threads/${thread_id}/retract`
        return throttleBufferedAction({
            action: axios.delete,
            url,
        })
            .then(handleResponse(context, RETRACT_CHI_ASSISTANT_THREAD))
    },
    [CREATE_CHI_ASSISTANT_THREAD](context, {_assistantId}) {
        context.commit(_RESET_STATUS, CREATE_CHI_ASSISTANT_THREAD)
        let url = getAssistantsUrl(`${_assistantId}/threads`)
        return throttleBufferedAction({
            action: axios.post,
            url,
        })
            .then(handleResponse(context, CREATE_CHI_ASSISTANT_THREAD))
    },
    [DELETE_CHI_ASSISTANT_THREAD](context, {_assistantId, thread_id}) {
        context.commit(_RESET_STATUS, DELETE_CHI_ASSISTANT_THREAD)
        let url = getAssistantsUrl(`${_assistantId}/threads/${thread_id}`)
        return throttleBufferedAction({
            action: axios.delete,
            url,
        })
            .then(handleResponse(context, DELETE_CHI_ASSISTANT_THREAD))
    },
    [DELETE_CHI_ASSISTANT_FILE](context, {_assistantId, _fileId}) {
        context.commit(_RESET_STATUS, DELETE_CHI_ASSISTANT_FILE)
        let url = getAssistantsUrl(`${_assistantId}/files/${_fileId}`)
        return throttleBufferedAction({
            action: axios.delete,
            url,
        })
            .then(handleResponse(context, DELETE_CHI_ASSISTANT_FILE))
    },
    [DEPRECATED_RUN_CHI_ASSISTANT](context, {_assistantId, thread_id, content, lastMessageId, metadata}) {
        context.commit(_RESET_STATUS, DEPRECATED_RUN_CHI_ASSISTANT)
        let url = getAssistantsUrl(`${_assistantId}/threads/${thread_id}/messages`)
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                content,
                lastMessageId,
                metadata,
            }
        })
            .then(handleResponse(context, DEPRECATED_RUN_CHI_ASSISTANT))
    },
    [STREAM_CHI_ASSISTANT_RUN](context, {
        _dataType,
        _assistantId,
        thread_id,
        content,
        lastMessageId,
        metadata,
        file_ids
    }) {
        const params = {
            _dataType: _dataType ?? 'Assistant',
            assistantId: _assistantId,
            thread_id,
            lastMessageId,
            content,
            metadata,
            pipeName: `${_assistantId}__${thread_id}`
        }
        if (file_ids) params.file_ids = file_ids
        context.commit(_RESET_STATUS, STREAM_CHI_ASSISTANT_RUN)
        let url = getAssistantsUrl(`/stream/${_dataType}/${_assistantId}/${thread_id}`)
        return throttleBufferedAction({
            action: axios.post,
            url,
            params
        })
            .then(handleResponse(context, STREAM_CHI_ASSISTANT_RUN))
    },
    [LIST_CHI_ASSISTANT_THREAD_MESSAGES](context, {_assistantId, thread_id}) {
        context.commit(_RESET_STATUS, LIST_CHI_ASSISTANT_THREAD_MESSAGES)
        let url = getAssistantsUrl(`${_assistantId}/threads/${thread_id}/messages/list`)
        return axios.post(url)
            .then(handleResponse(context, LIST_CHI_ASSISTANT_THREAD_MESSAGES))
    },
    [LOAD_CHI_ASSISTANT_THREAD](context, {_appId, thread_id}) {
        context.commit(_RESET_STATUS, LOAD_CHI_ASSISTANT_THREAD)
        let url = getAssistantsUrl(`/threads/${thread_id}/listRuns`)
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                _appId,
            }
        })
            .then(handleResponse(context, LOAD_CHI_ASSISTANT_THREAD))
    },
    [BATCH_IMPORT_ASSISTANT_FILES](context, {entryPath, fileNamePrefix}) {
        context.commit(_RESET_STATUS, BATCH_IMPORT_ASSISTANT_FILES)
        let url = getApiUrl() + '/batchImportAssistantFiles/'
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                entryPath,
                fileNamePrefix,
            }
        })
            .then(handleResponse(context, BATCH_IMPORT_ASSISTANT_FILES))
    }
}