import {isRef} from "vue";
import {isObject, isString} from "@/lib/getVariableType";
import {normalizeTimestamp} from "@/lib/typeHelpers/dateFunctions/normalizeTimestamp";

export const createEvent = (eventName, data={}) => {
    if (isRef(data)) data = {...data.value}
    if (isString(data)) data = {message: data}
    if (!isObject(data)) {
        console.log(666.2, eventName, data)
        console.warn('Event data must be an object')
    }
    return {
        type: eventName,
        timestamp: normalizeTimestamp(data.started_at || data.created_at || Date.now()),
        data
    }
}