<script>
export default {
  name: "Arrows",
  props: {
    title: {
      type: String,
      default: "Graph icon"
    },
    decorative: {
      type: Boolean,
      default: false
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 24
    }
  }
}
</script>
<template>
  <span :aria-hidden="decorative"
        v-bind="$attrs"
        :aria-label="title"
        class="material-design-icon graph-icon"
        role="img">
<svg xmlns="http://www.w3.org/2000/svg"
     :fill="fillColor"
     class="material-design-icon__svg"
     :width="size"
     :height="size"
     viewBox="0 0 24 24"
>
   <defs>
    <radialGradient id="arrows" cx="50%" cy="100%" r="100%" fx="50%" fy="100%">
      <stop offset="0%" stop-color="#001438"/> <!-- Start color -->
      <stop offset="0%" stop-color="#d1fae5"/> <!-- Middle color -->
      <stop offset="100%" stop-color="#A9C2FF"/> <!-- End color -->
    </radialGradient>
  </defs>

  <path fill="url(#arrows)"
        d="M21,6H3A2,2 0 0,0 1,8V16A2,2 0 0,0 3,18H21A2,2 0 0,0 23,16V8A2,2 0 0,0 21,6M21,16H3V8H21M6,15H8V13H10V11H8V9H6V11H4V13H6M14.5,12A1.5,1.5 0 0,1 16,13.5A1.5,1.5 0 0,1 14.5,15A1.5,1.5 0 0,1 13,13.5A1.5,1.5 0 0,1 14.5,12M18.5,9A1.5,1.5 0 0,1 20,10.5A1.5,1.5 0 0,1 18.5,12A1.5,1.5 0 0,1 17,10.5A1.5,1.5 0 0,1 18.5,9Z">

 <title>{{ title }}</title>
  </path>
</svg>
  </span>
</template>


