<script>
import LCHIThread from "@/components/CHIThread/layout/LCHIThread.vue";

export default {
  name: "LOpenAIThread",
  components: {
    LCHIThread,
  },
  props: {
    avatarSrc: {
      type: String,
      default: require('../../../../assets/chatAvatar.png'),
    },
    showMessageInput: {
      type: Boolean,
      default: false,
    },
    showToolsAside: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <div class="
      lOpenAIThread layout
      w-full
      flex flex-col
      k1:flex-row k1:gap-8
      "
  >

    <div class="
      flex-grow
      flex flex-col items-center justify-center
      ">
      <LCHIThread :showAside="showToolsAside">
        <template #status>
          <slot name="status"></slot>
        </template>
        <template #default>
          <slot>No messages yet.</slot>
        </template>
        <template #input>
          <div v-if="showMessageInput">
            <slot name="input"></slot>
          </div>
        </template>
        <template #aside>
          <slot name="toolCalls"></slot>
        </template>
      </LCHIThread>
    </div>
  </div>
</template>
