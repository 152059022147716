import {withPostfix} from "@/lib/prefixPostfix";

export const getHostUrl = (override) => {
    override = override || {}
    if (override.protocol) override.protocol = withPostfix(override.protocol, ':')

    let {hostname, port, protocol} = location

    protocol = override?.protocol || protocol
    port = override?.port || port
    if (protocol === 'http:' && port === '80') port = ''
    if (protocol === 'https:' && port === '443') port = ''
    if (port) port = ':' + port

    return `${protocol}//${hostname}${port}`
}
