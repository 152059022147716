<script>
export default {
  name: "GraphIcon",
  props: {
    title: {
      type: String,
      default: "Graph icon"
    },
    decorative: {
      type: Boolean,
      default: false
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 24
    }
  }
}
</script>
<template>
  <span :aria-hidden="decorative"
        v-bind="$attrs"
        :aria-label="title"
        class="material-design-icon graph-icon"
        role="img">
<svg xmlns="http://www.w3.org/2000/svg"
     :fill="fillColor"
     class="material-design-icon__svg -rotate-90"
     :width="size"
     :height="size"
     viewBox="0 0 24 24"
>

  <defs>
    <radialGradient id="circle" cx="100%" cy="50%" r="100%" fx="100%" fy="50%">
      <stop offset="0%" stop-color="#001438"/> <!-- Start color -->
      <stop offset="0%" stop-color="#d1fae5"/> <!-- Middle color -->
      <stop offset="100%" stop-color="#A9C2FF"/> <!-- End color -->

    </radialGradient>
  </defs>
  <path fill="url(#circle)"
        d="M12 2A10 10 0 0 0 2 12A10 10 0 0 0 12 22A10 10 0 0 0 22 12A10 10 0 0 0 12 2M12 4A8 8 0 0 1 20 12A8 8 0 0 1 12 20V4Z">
 <title>{{ title }}</title>
  </path>
</svg>
  </span>
</template>


