import {useStore} from "vuex";
import {useRoute} from "vue-router";

export const useSidebarPanel = () => {
    const store = useStore()
    const {path} = useRoute()

    return {
        updateSidebarPanels: (panels) => {
            /*
            {
              '^\/teaching': {
                component: 'SideBarListOwnAssistants',
                params: {
                  _appId: 'assistants',
                  _dataType: 'TeachingAssistant'
                }
              }
            }
             */
            panels = panels || {}
            delete store.state.UI.sideBarPanel
            Object.entries(panels)
                .forEach(([pathPattern, state]) => {
                    const rxPath = new RegExp(pathPattern)
                    if (rxPath.test(path)) {
                        store.state.UI.sideBarPanel = state
                    }
                })
        }
    }
}