import {isJSIdentifier} from "@/lib/typeHelpers/stringFunctions/isJSIdentifier";
import {isObject} from "@/lib/getVariableType";
import {useStore} from "vuex";
import {isRef, reactive, ref, toRefs, watch} from "vue";
import {PATCH_SHARED_DOCUMENT} from "@/store/operations";
import {capitalize} from "@/lib/typeHelpers";

const debug = {
    init: false,
    sync: true,
    update: false
}

const ingestName = alias => {
    if (!alias) throw new Error('name is required')
    if (!isJSIdentifier(alias)) throw new Error('name must be a valid JavaScript identifier')
    return alias
}

const defaultOptions = {
    operation: PATCH_SHARED_DOCUMENT,
    onSyncHook: null,
    tests: [],
    __isFormField: false,
}
const ingestOptions = options => {
    options = options || {}
    if (!isObject(options)) throw new Error('options must be an object')
    options = Object.assign({}, defaultOptions, options)
    return options
}
const ingestDocument = document => {
    if (!document) throw new Error('document is required')
    document = isRef(document) ? document : ref(document)
    const target = document.value
    const isInitialized = target !== null
    if (isInitialized) {
        if (!isObject(target)) throw new Error('document must be (ref of) an object')
        if (!target?._dataType) throw new Error('document._dataType is required')
        if (!target?._id) throw new Error('document._id is required')
    }
    return document
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const useSharedDocumentAttribute = ({document, attributeName, options}) => {
    if (!document) throw new Error('not initialized. document is still outstanding.')
    document = ingestDocument(document)
    const name = ingestName(attributeName)
    options = ingestOptions(options)
    const _isNew = ref(document?.value?._id !== null)

    if (debug.init) console.log(1.01, name, document)

    const Name = capitalize(name)
    const {
        defaultValue,
        operation,
        onSyncHook,
        __isFormField,
        // tests = [],
    } = options

    if (debug.init && defaultValue) console.log(1.02, defaultValue, typeof defaultValue)

    const store = useStore();
    const context = reactive({
        [name]: defaultValue ?? null,
        [`${name}IsWaitingForDocument`]: true,
        [`${name}Error`]: null,
    })
    const syncValue = value => { // does not save locally, pushes to server
        if (context[`${name}IsWaitingForDocument`]) {
            console.warn(`${name}IsWaitingForDocument`)
            return
        }
        if (__isFormField) return
        if (_isNew.value) {
            console.warn('create document before updating attributes. Set options.__isFormField = true to hide this warning.')
            return
        }
        const {_appId, _dataType, _id} = document.value
        if (!_appId || !_dataType || !_id) {
            console.warn('invalid document. missing _appId, _dataType, and/or _id', document.value)
            return
        }
        const attributes = {
            //...store.state.docs[_dataType][_id],
            [name]: value,
        }
        if (debug.sync) console.log(498, operation, _id, attributes)

        if (onSyncHook) {
            onSyncHook({_appId, _dataType, _id, attributes})
        } else {
            store.dispatch(operation, {
                _appId,
                _dataType,
                _id,
                attributes,
            })
                .catch(err => {
                    console.log(28.3, operation, {
                        _id,
                        attributes,
                    })
                    context[`${name}Error`] = err
                })
        }
    }

    // manual value update
    const updateValue = (value, synchronize) => { // updates local value, only pushes if autoSync
        const oldValue = context[name]
        if (value === undefined || (value === oldValue && !synchronize)) return
        if (debug.update) console.log(983, 'sync/update', value, synchronize)
        context[name] = value

        const isInitialized = document !== undefined

        const shouldSync =
            !context[`${name}IsWaitingForDocument`] &&
            isInitialized &&
            synchronize

        if (debug.update) console.log('shouldSync', !context[`${name}IsWaitingForDocument`], isInitialized, synchronize)

        if (shouldSync) {
            syncValue(value)
        }
    }

    // syncs with (store state) doc ref changes?
    watch(document, (newValue, oldValue) => {
        _isNew.value = newValue?._id === null
        const isNullChange = newValue === undefined || (!_isNew.value && JSON.stringify(newValue) === JSON.stringify(oldValue))
        if (isNullChange) return
        updateValue(newValue?.[name], false)
        context[`${name}IsWaitingForDocument`] = false
    }, {immediate: true})

    return {
        ...toRefs(context),
        [`sync${Name}`]: value => updateValue(value, true),
        [`update${Name}`]: updateValue,
        [`save${Name}`]: syncValue,
    }
}