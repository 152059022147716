
<script>
export default {
  name: "PlayIcon",
  props: {
    title: {
      type: String,
      default: "Play icon"
    },
    decorative: {
      type: Boolean,
      default: false
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 20
    }
  }
}
</script>
<template>
  <span :aria-hidden="decorative"
        v-bind="$attrs"
        :aria-label="title"
        class="material-design-icon play-icon"
        role="img">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :fill="fillColor"
        class="material-design-icon__svg"
        :width="size"
        :height="size"
        viewBox="0 0 24 24"
    >
      <path d="M8,5.14V19.14L19,12.14L8,5.14Z" >
        <title>{{ title }}</title>
      </path>
    </svg>
  </span>
</template>
