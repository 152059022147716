<script>
import LGamePage from "../layout/LGamePage.vue";

export default {
  name: 'WelcomePage',
  components: {
    LGamePage,
  },
}
</script>
<template>
  <LGamePage>
    <template #title>
      Flash Match!
      <h2 class="gamifiedh2">
        Get ready for a fast-paced challenge!
      </h2>
    </template>
    <template #default>

      <p class="text-center text-md">
        In Flash Match, your task is to quickly assess a series of icons and decide if they match the target icon.
      </p>
      <p>
        The game consists of 2 minigames <em>Search</em> and <em>Comparison</em>.
      </p>
      <p>

        Speed and accuracy are key. Let's see how sharp you are!
      </p>

      <img src="../../assets/mental.png" height="150" width="150" class="mt-8"/>
    </template>
    <template #actions>
      <button
          class="button mt-12"
          @click="$router.push({name: 'flash-match-search-intro'})"
      >
        Continue
      </button>
    </template>
  </LGamePage>
</template>
