
<script>
import LStatusMessage from "@/components/status/LStatusMessage.vue";
import {useStatusMessage} from "@/composables/useStatusMessage";
import * as operations from "@/store/operations";
import IconClose from "@/components/icons/CloseThick.vue";
import IconAlertOctagon from "@/components/icons/AlertOctagon.vue";
import IconCheck from "@/components/icons/Check.vue";
import {computed, onBeforeUnmount, ref, watch} from "vue";
import {isString} from "@/lib/getVariableType";

export default {
  name: "StatusMessage",
  components: {
    IconAlertOctagon,
    IconCheck,
    IconClose,
    LStatusMessage,
  },
  props: {
    operation: {
      type: String,
      required: true,
      validator: value => Object.values(operations).includes(value)
    },
    isVerbose: {
      type: Boolean,
      default: false
    },
    isPersistent: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {message, setMessage, deleteMessage} = useStatusMessage(props.operation)

    const body = computed(() => {
      if (isString(message.value)) setMessage({body: message.value})
      if (props.isVerbose) return message.value?.body
      return message.value?.body
    })
    const timeoutRef = ref(null)

    const shownMessage = computed(() => {
      const {isSuccess} = message?.value ?? {}
      if (isSuccess && !props.isVerbose) return null

      return {
        ...message?.value,
        body: body.value
      }
    })

    watch(shownMessage, () => {
      if (props.isPersistent) return
      let timeout = shownMessage.value?.timeout ?? 3000
      clearTimeout(timeoutRef.value)
      if (shownMessage.value?.isError) timeout = 10000
      if (timeout) {
        timeoutRef.value = setTimeout(() => {
          deleteMessage()
        }, timeout)
      }
    }, {deep: true})

    onBeforeUnmount(() => {
      clearTimeout(timeoutRef.value)
    })

    return {
      shownMessage
    }
  }
}
</script>
<template>
  <div
      class="statusMessage"
      v-if="shownMessage"
      :class="{
          'h-0': !shownMessage?.body,
          'h-auto py-8 m-4': !!shownMessage?.body,
          isError: shownMessage?.isError,
          isWarning: shownMessage?.isWarning,
          isSuccess: shownMessage?.isSuccess
      }"
  >
    <LStatusMessage v-if="shownMessage">
      <template #icon>
        <IconClose
            v-if="shownMessage.isError"
            :size="48"
        ></IconClose>
        <IconAlertOctagon
            v-if="shownMessage.isWarning"
            :size="48"
        ></IconAlertOctagon>
        <IconCheck
            v-if="shownMessage.isSuccess"
            :size="48"
        ></IconCheck>
      </template>
      <template #body>
        <div
            class="min-h-6"
            v-html="shownMessage.body"
        ></div>
      </template>
    </LStatusMessage>
  </div>
</template>

<style scoped>

</style>