<script>
import ListItemAssistant from "./ListItemAssistant.vue";
import Button from "@/components/forms/Button.vue";
import StatusMessage from "@/components/status/StatusMessage.vue";
import {
  CREATE_CHI_ASSISTANT,
} from "../../operations";
import {useUserPermissions} from "@/composables/useUserPermissions";
import {useRoute, useRouter} from "vue-router";
import {useSharedDocuments} from "@/composables/document/shared/useSharedDocuments";
import {useStatusMessage} from "@/composables/useStatusMessage";
import {useStore} from "vuex";
import {LIST_SHARED_DOCUMENTS} from "@/store/operations/sharedDocumentOperations";
import {computed} from "vue";
import {capitalize} from "@/lib/typeHelpers";

export default {
  name: "ListAssistants",
  components: {
    StatusMessage,
    Button,
    ListItemAssistant,
  },
  setup: function () {
    const {isLoggedIn} = useUserPermissions()
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const listItemComponent = computed(() => `ListItem${capitalize(route.query?.UI ?? 'Assistant')}`)

    const {setMessage} = useStatusMessage(CREATE_CHI_ASSISTANT)
    const {
      documents,
      isLoaded,
    } = useSharedDocuments({
      alias: 'assistant',
      filter: {
        _appId: 'assistants',
        _dataType: 'Assistant',
        // _none: {
        //   _attributes: ['_workshopId']
        // }
      },
      options: {
        autoLoad: true
      }
    })

    const onCreateAssistant = () => store.dispatch(CREATE_CHI_ASSISTANT, {
      name: 'New Assistant',
      instructions: 'You are an assistant. You can help people with their tasks.',
      model: 'gpt-3.5-turbo-0125'
    })
        .then(response => {
          const {Assistant} = response?.data ?? {}
          const {_id} = Assistant?.[0] ?? {}
          if (!_id) throw new Error('Assistant could not be created at this time. Please try again later.')
          router.push({name: 'assistant', params: {_assistantId: _id}})
        })
        .catch(err => {
          console.error(44, err)
          setMessage({
            isError: true,
            body: err.message
          })
        })

    return {
      CREATE_CHI_ASSISTANT,
      LIST_SHARED_DOCUMENTS,

      documents,
      isLoggedIn,
      isLoaded,
      listItemComponent,
      onCreateAssistant,
    }
  }
}
</script>

<template>
  <div class="listAssistants">
    <div
        class=" "
    >
      <StatusMessage :operation="CREATE_CHI_ASSISTANT"></StatusMessage>
      <StatusMessage :operation="LIST_SHARED_DOCUMENTS"></StatusMessage>
      <div class="p-2">
        <div class="heading1 text-center">
          CHI Assistants
          <h2 class="text-gray-400 text-base font-normal text-center">
            Here is a List of all assistants available to you
          </h2>
        </div>
      </div>
      <div v-if="isLoaded && documents" class="grid grid-cols-1 k1:grid-cols-2 gap-4 ">
        <ListItemAssistant
            v-for="assistant in documents"
            :key="assistant._id"
            _appId="assistants"
            :_id="assistant._id"
        ></ListItemAssistant>
<!--        <component-->
<!--            :is="listItemComponent"-->
<!--            v-for="assistant in documents"-->
<!--            :key="assistant._id"-->
<!--            _appId="assistants"-->
<!--            :_id="assistant._id"-->
<!--            @click="$router.push({name: 'assistant', params: {_assistantId: assistant._id}})"-->
<!--        ></component>-->
      </div>
      <p v-else>No assistants yet.</p>
    </div>
    <div class="mt-8 w-full flex sticky bottom-4 items-center justify-center">
      <Button
          v-if="isLoggedIn"
          class="blackButton  "
          @click="onCreateAssistant"
      >
        <svg class="w-6 h-6 text-white pr-1 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
             fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 12h14m-7 7V5"/>
        </svg>
        Create Assistant
      </Button>
    </div>
  </div>
</template>
