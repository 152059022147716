import {state} from "@/store/store";
import {getAppRoutes} from "@/router/lib/getAppRoutes";
import {capitalize} from "@/lib/typeHelpers";
import {areSame} from "@/lib/typeHelpers/objectFunctions/areSame";
import {ref} from "vue";
import FourOhFour from "@/components/views/FourOhFour.vue";

const removeRoutes = (router, routes) =>
    routes.forEach(route => router.removeRoute(route.name))

const currentSubdomain = ref(state.appId)
const commonRoutes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: FourOhFour
    }
]

export const selectAppRoutes = (router, newSubdomain) => {

    if (currentSubdomain.value === newSubdomain) return
    state.appId = newSubdomain

    let currentRoutes = [...router.getRoutes()]
    removeRoutes(router, [...currentRoutes])

    const entryRoute = getAppRoutes(newSubdomain)
    router.addRoute(entryRoute)

    commonRoutes.map(route => router.addRoute(route))

    return router.getRoutes().find(r => r.name === 'home')
}
const setDocumentTitle = (destination) =>
    document.title = capitalize(destination.meta?.title ?? destination.name ?? destination.path)

const showLoginModal = (destination, redirectTo) =>
    destination.query = {modal: 'login', redirect: redirectTo?.path ?? destination.path}

const enforceLogin = (router, destination) => {
    if (destination.meta.requireLogin && !state.user) {
        destination = router.getRoutes().find(r => r.name === 'home')
        showLoginModal(destination)
    }
    return destination
}

export const getNextRoute = (router, to) => {
    let destination = {
        ...to,
    }
    destination = enforceLogin(router, destination)
    setDocumentTitle(destination)
    if (!areSame(destination, to)) return destination
}
