<script>
import LGamePage from "../layout/LGamePage.vue";

export default {
  name: "Welcome",
  components: {
    LGamePage,
  },
}
</script>

<template>
  <LGamePage>
    <template #title>
      Color Catch
      <h2 class="gamifiedh2">
        Get ready for a colorful challenge!
      </h2>
    </template>
    <template #default>
      <p>Different colorful squares will appear on a grid on your screen.</p>
      <p>Your task is to remember where each color last appeared.</p>
      <p>There are four levels, each adding an additional color to keep track of.</p>
      <p>There will be a practice round before the first level.</p>
      <p>Let's see how good you are at remembering!</p>

    </template>
    <template #actions>
      <button @click="$router.push({name:'color-catch-tutorial'})"
              class="button text-xl ">
        Continue
      </button>
    </template>
  </LGamePage>
</template>

<style scoped>
@media (min-width: 1000px) {
  .listing {
    font-size: 1rem; /* Equivalent to k1:text-md */
  }
}
</style>