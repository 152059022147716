import {decapitalize} from "@/lib/typeHelpers/stringFunctions/capitalization";

const checkAppRoute = (route, appId) => {
    if (!route.path?.length) throw new Error(`The '' app route path (${appId}) is reserved for your HomeView.vue`)

    const reservedRouteNames = [
        'api',
        `layout`,
        `home`
    ]

    if (!route.name) throw new Error(`App route path ${route.path} must have a name (appId: ${appId}).`)
    if (route.name !== decapitalize(route.name)) throw new Error(`App route name ${route.name} must be decapitalized (appId: ${appId}).`)
    if (reservedRouteNames.includes(route.name?.toLowerCase())) throw new Error(`App route name ${route.name} cannot use the name of a root route (appId: ${appId}).`)
}

export const rigSinglePageAppEntryRoute = (routes, appId) => {
    const {default: LHome} = require(`@/../chi-${appId}/components/views/layout/LHome.vue`)
    const {default: Home} = require(`@/../chi-${appId}/components/views/Home.vue`)

    routes.forEach(route => checkAppRoute(route, appId))

    const homeRoute = {
        path: '/',
        name: 'layout',
        component: LHome,
        children: [
            {
                path: '',
                name: 'home',
                component: Home,
            },
            ...routes
        ]
    }
    return homeRoute
}
