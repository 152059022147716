<script>
import {useSharedDocument} from "@/composables/document/shared/useSharedDocument";
import {computed, ref, watchEffect} from "vue";
import StreamItemStatusBar from "./statusBar/StreamItemStatusBar.vue";
import {useTimeTexts} from "../../../../../chi-assistants/composables/useTimeTexts";

/*
  {
    "id": "run_abc123",
    "object": "thread.run",
    "created_at": 1699063290,
    "assistant_id": "asst_abc123",
    "thread_id": "thread_abc123",
    "status": "queued",
    "started_at": 1699063290,
    "expires_at": null,
    "cancelled_at": null,
    "failed_at": null,
    "completed_at": 1699063291,
    "last_error": null,
    "model": "gpt-4-turbo",
    "instructions": null,
    "incomplete_details": null,
    "tools": [
      {
        "type": "code_interpreter"
      }
    ],
    "metadata": {},
    "usage": null,
    "temperature": 1.0,
    "top_p": 1.0,
    "max_prompt_tokens": 1000,
    "max_completion_tokens": 1000,
    "truncation_strategy": {
      "type": "auto",
      "last_messages": null
    },
    "response_format": "auto",
    "tool_choice": "auto"
  }
 */

const timeFormat = 'HH:mm:ss'

export default {
  name: "OpenAIThreadRun",
  components: {
    StreamItemStatusBar
  },
  props: {
    id: {
      type: String,
    },
  },
  setup(props) {
    const {getTimespanText, getTimestampText} = useTimeTexts(timeFormat)
    let id = computed(() => props.id)
    let status = ref('')
    let statusText = ref('')

    watchEffect(() => {
      if (id.value) {
        const {run} = useSharedDocument({
          alias: 'run',
          filter: {
            _dataType: 'Run',
            _id: props.id,
          },
        })
        status.value = run.value?.status

        const {
          status,
          started_at,
          completed_at,
          failed_at,
          cancelled_at,
          expired_at,
        } = run.value ?? {}

        switch (status) {
          case 'running':
            statusText.value = `Run running for ${getTimestampText(started_at)}`
            break;
          case 'completed':
            statusText.value = `Run completed at ${getTimespanText(completed_at, started_at)}`
            break;
          case 'failed':
            statusText.value = `Run failed at ${getTimespanText(failed_at, started_at)}`
            break;
          case 'cancelled':
            statusText.value = `Run cancelled at ${getTimespanText(cancelled_at, started_at)}`
            break;
          case 'expired':
            statusText.value = `Run expired at ${getTimespanText(expired_at, started_at)}`
            break;
          default:
            statusText.value = 'Starting...'
        }
      }
    })

    return {
      status,
      statusText,
    }
  }
}
</script>

<template>
  <div class="OpenAIThreadRun w-full">
    <div
        class="
            w-full
            flex items-center justify-between
        "
        :title="id"
    >
      <StreamItemStatusBar
          _dataType="Run"
          :id="id"
      ></StreamItemStatusBar>
      <div
          class="flex-grow px-1"
          :class="{
            'bg-green-400': status === 'completed',
            'bg-red-500 text-white': status === 'failed',
            'bg-orange-400': status === 'expired',
            'bg-slate-400': status === 'cancelled',
            'bg-yellow-400': status === 'running',
          }"
      >
        {{ statusText }}
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>