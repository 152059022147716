<script>
import {computed} from "vue";
import {GET_SHARED_DOCUMENT} from "@/store/operations/sharedDocumentOperations";
import {useStore} from "vuex";

export default {
  name: "FileReference",
  props: {
    text: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const file = computed(() => store.state.docs.File?.[props.id])

    if (!file) {
      store.dispatch(GET_SHARED_DOCUMENT, {
        _appId: 'assistants',
        _dataType: 'File',
        _id: props.id,
      })
    }

    const alias = computed(() => file.value?.filename ?? props.id)
    return {
      alias,
    }
  }
}
</script>

<template>
  <span class="fileReference bg-yellow-50 " :title="`file_id: ${id}`">【{{ alias }}】</span>
</template>
