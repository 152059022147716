<script>
import {computed, ref} from "vue";
import LoadingSpinner from "../../../../chi-assistants/components/Workshop/LoadingSpinner.vue";

export default {
  name: "ThreadMessageInput",
  components: {LoadingSpinner},
  emits: ['submit'],
  props: {
    thread_id: {
      type: String,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, {emit}) {
    // states
    const isInputFocused = ref(false)

    const message = ref('')
    const canSubmit = computed(() => props.thread_id && message.value?.length && !props.isBusy)

    // actions
    const onMessageChange = (event) => message.value = event.target.value;

    const onSubmit = () => {
      if (!canSubmit.value) return

      const payload = {
        thread_id: props.thread_id,
        content: message.value,
        // todo: file_ids: [],
      }

      message.value = ''

      emit('submit', payload)

    }
    return {
      canSubmit,
      isInputFocused,
      message,

      onMessageChange,
      onSubmit
    }
  }
}
</script>

<template>
  <div class="threadMessageInput">
    <div class="flex mt-2 overflow-hidden">
      <div
          class="flex flex-col w-full flex-grow relative  shadow-sm "
          @keyup.enter="onSubmit"
      >
        <input type="text"
               autofocus
               id="message"
               name="message"
               placeholder="Write a message..."
               class="w-full resize-none outline-none bg-transparent border !border-r-0 !rounded-r-none border-blue-300 hover:border-blue-300 focus:!border-blue-600 rounded-l-xl p-2 px-4 "
               @input="onMessageChange"
               @focus="isInputFocused = true"
               @blur="isInputFocused = false"
               v-model="message">
      </div>

      <button
          class=" px-2 w-fit rounded-r-xl touch-manipulation active:scale-[99%] border border-l-0 border-blue-600 hover:bg-blue-700"
          :class="[isInputFocused ? '!border-blue-600' : '', canSubmit && !isBusy ? 'bg-blue-500 ' : '!bg-blue-500/50 !border-blue-500/10']"
          :disabled="!canSubmit || isBusy"
          @click="onSubmit"
      >
        <div class="w-8 h-8 grid place-items-center">
          <div v-if="isBusy">
            <LoadingSpinner/>
          </div>
          <div v-else>
            <svg class="w-5 h-5 inline-flex rotate-90 object-contain text-white dark:text-white"
                 aria-hidden="true"
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"/>
            </svg>
          </div>
        </div>
      </button>
    </div>
  </div>

</template>

