import ColorCatchWelcome from './components/colorCatch/Welcome.vue'
import ColorCatch from './components/views/ColorCatch.vue'
import IntroScreen from "./components/mimeMix/IntroScreen.vue";
import MimeMix from "./components/views/MimeMix.vue";
import FlashMatch from "./components/views/FlashMatch.vue";
import FlashMatchWelcome from "./components/flashMatch/Welcome.vue";
import MorningMemo from "./components/views/MorningMemo.vue";
import MorningMemoWelcome from "./components/morningMemo/Welcome.vue";
import QuizTime from "./components/views/QuizTime.vue";
import QuizTimeWelcome from "./components/quizTime/Welcome.vue";


export default [
    {
        path: '/GamesUnavailable',
        name: 'unvalidatedUser',
        component: () => import('./components/views/UnvalidatedUser.vue'),
    },
    {
        path: '/Data',
        name: 'data',
        component: () => import('./components/data/DownloadTabularData.vue'),
        meta: {
            stepLabel: 'Data',
            requireAuthentication: true
        }
    },
    {
        name: 'flashMatchLayout',
        path: '/FlashMatch',
        component: FlashMatch,
        children: [
            {
                name: 'flash-match',
                path: '',
                component: FlashMatchWelcome,
                meta: {
                    stepLabel: 'Welcome',
                }
            },
            {
                name: 'flash-match-search-intro',
                path: 'SearchIntro',
                component: () => import('./components/flashMatch/SearchIntro.vue'),
                meta: {
                    stepLabel: 'Search: Round 1',
                }
            },
            {
                name: 'flash-match-search',
                path: 'Search',
                component: () => import('./components/flashMatch/Search.vue'),
                meta: {
                    stepLabel: 'Search: Round 1'
                }
            },
            {
                name: 'flash-match-search-break',
                path: 'SearchBreak',
                component: () => import('./components/flashMatch/SearchBreak.vue'),
                meta: {
                    stepLabel: 'Search: Round 2'
                }
            },
            {
                name: 'flash-match-search-end',
                path: 'SearchEnd',
                component: () => import('./components/flashMatch/SearchEnd.vue'),
                meta: {
                    stepLabel: 'Search: Round 2'
                }
            },
            {
                name: 'flash-match-comparison-intro',
                path: 'ComparisonIntro',
                component: () => import('./components/flashMatch/ComparisonIntro.vue'),
                meta: {
                    stepLabel: 'Comparison: Round 1'
                }
            },
            {
                name: 'flash-match-comparison',
                path: 'Comparison',
                component: () => import('./components/flashMatch/Comparison.vue'),
                meta: {
                    stepLabel: 'Comparison: Round 1'
                }
            },
            {
                name: 'flash-match-comparison-break',
                path: 'ComparisonBreak',
                component: () => import('./components/flashMatch/ComparisonBreak.vue'),
                meta: {
                    stepLabel: 'Comparison: Round 2'
                }
            },
            {
                name: 'flash-match-feedback',
                path: 'Feedback',
                component: () => import('./components/flashMatch/Feedback.vue'),
                meta: {
                    stepLabel: 'Feedback'
                }
            }
        ]
    },

    {
        name: 'linksMatchLayout',
        path: '/Links',
        component: () => import('./components/links/views/HomeView.vue'),
        children: [
            {
                name: 'links',
                path: '',
                component: () => import('./components/links/views/Level1Introduction.vue'),
                meta: {
                    stepLabel: 'Welcome'
                }
            },
            {
                name: 'level1',
                path: 'Level1',
                component: () => import('./components/links/views/Level1View.vue'),
                meta: {
                    stepLabel: 'Level 1'
                }
            },
            {
                name: 'level1results',
                path: 'Level1/results',
                component: () => import('./components/links/views/Level1ResultsView.vue'),
                meta: {
                    stepLabel: 'Level 1 results'
                }
            },
            {
                name: 'level2Intro',
                path: 'Level2Intro',
                component: () => import('./components/links/views/Level2Introduction.vue'),
                meta: {
                    stepLabel: 'Level 2 Introduction'
                }
            },
            {
                name: 'level2',
                path: 'Level2',
                component: () => import('./components/links/views/Level2View.vue'),
                meta: {
                    stepLabel: 'Level 2'
                }
            },
            {
                name: 'gameresults',
                path: 'Done',
                component: () => import('./components/links/views/FinishedView.vue'),
                meta: {
                    stepLabel: 'Feedback'
                }
            },
        ],
    },
    {
        name: 'colorCatchLayout',
        path: '/ColorCatch',
        component: ColorCatch,
        children: [
            {
                name: 'color-catch',
                path: '',
                component: ColorCatchWelcome,
                meta: {
                    stepLabel: 'Welcome'
                }
            },
            {
                name: 'color-catch-tutorial',
                path: 'Tutorial',
                component: () => import('./components/colorCatch/Game.vue'),
                meta: {
                    stepLabel: 'Tutorial'
                }
            },
            {
                name: 'color-catch-splash',
                path: 'GetReady',
                component: () => import('./components/colorCatch/SplashScreen.vue'),
                meta: {
                    stepLabel: 'Level 1'
                }
            },
            {
                name: 'color-catch-game',
                path: 'Game',
                component: () => import('./components/colorCatch/Game.vue'),
                meta: {
                    stepLabel: 'Level X'
                }
            },
            {
                name: 'color-catch-done',
                path: 'Done',
                component: () => import('./components/colorCatch/Done.vue'),
                meta: {
                    stepLabel: 'Feedback'
                }
            }
        ]
    },
    {
        path: '/MorningMemo',
        name: 'morningMemoLayout',
        component: MorningMemo,
        children: [
            {
                name: 'morning-memo',
                path: '',
                component: MorningMemoWelcome,
                meta: {
                    stepLabel: 'Welcome'
                }
            },
            {
                name: 'morning-memo-test',
                path: 'Test',
                component: () => import('./components/morningMemo/Test.vue'),
                meta: {
                    stepLabel: 'Test'
                }
            },
            {
                name: 'morning-memo-background-noise',
                path: 'BackgroundNoise',
                component: () => import('./components/morningMemo/BackgroundNoise.vue'),
                meta: {
                    stepLabel: 'Background Noise'
                }
            },
            {
                name: 'morning-memo-talk',
                path: 'Talk',
                component: () => import('./components/morningMemo/Talk.vue'),
                meta: {
                    stepLabel: 'Talk'
                }
            },
            {
                name: 'morning-memo-affect-grid',
                path: 'AffectGrid',
                component: () => import('./components/morningMemo/AffectGrid.vue'),
                meta: {
                    stepLabel: 'How Do You Feel'
                }
            },
            {
                name: 'morning-memo-affect-grid-examples',
                path: 'AffectGridExamples',
                component: () => import('./components/morningMemo/AffectGridExamples.vue'),
                meta: {
                    stepLabel: 'How Do You Feel'
                }
            },
            {
                name: 'morning-memo-how-do-you-feel',
                path: 'HowDoYouFeel',
                component: () => import('./components/morningMemo/HowDoYouFeel.vue'),
                meta: {
                    stepLabel: 'How Do You Feel'
                }
            },
            {
                name: 'morning-memo-feedback',
                path: 'Feedback',
                component: () => import('./components/morningMemo/Feedback.vue'),
                meta: {
                    stepLabel: 'Feedback'
                }
            }

        ]
    },
    {
        path: '/QuizTime',
        name: 'quizTimeLayout',
        component: QuizTime,
        children: [
            {
                name: 'quiz-time',
                path: '',
                component: QuizTimeWelcome,
                meta: {
                    stepLabel: 'Welcome'
                }
            },
            {
                name: 'quiz-time-game',
                path: 'Game',
                component: () => import('./components/quizTime/Game.vue'),
                meta: {
                    stepLabel: 'Game'
                }
            },
            {
                name: 'quiz-time-feedback',
                path: 'Feedback',
                component: () => import('./components/quizTime/Feedback.vue'),
                meta: {
                    stepLabel: 'Feedback'
                }
            },
        ]
    },
    {
        path: '/MimeMix',
        name: 'mimeMixLayout',
        component: MimeMix,
        children: [
            {
                name: 'mime-mix',
                path: '',
                component: IntroScreen,
                meta: {
                    stepLabel: 'Welcome'
                }
            },
            {
                name: 'mime-mix-intro-2',
                path: 'TutorialInfo2',
                component: () => import('./components/mimeMix/IntroScreen2.vue'),
                meta: {
                    stepLabel: 'Welcome'
                }
            },
            {
                name: 'mime-mix-tutorial-start',
                path: 'TutorialStart',
                component: () => import('./components/mimeMix/PracticeIntroScreen.vue'),
                meta: {
                    stepLabel: 'Tutorial'
                }
            },
            {
                name: 'mime-mix-tutorial',
                path: 'Tutorial',
                component: () => import('./components/mimeMix/PracticeGame.vue'),
                meta: {
                    stepLabel: 'Tutorial'
                }
            },
            {
                name: 'mime-mix-tutorial-done',
                path: 'TutorialDone',
                component: () => import('./components/mimeMix/PracticeEndScreen.vue'),
                meta: {
                    stepLabel: 'Tutorial'
                }
            },
            // {
            //     name: 'mime-mix-game-start',
            //     path: 'GameStart',
            //     component: () => import('./components/mimeMix/GameStartScreen.vue'),
            //     meta: {
            //         stepLabel: 'Game'
            //     }
            // },
            {
                name: 'mime-mix-game',
                path: 'Game',
                component: () => import('./components/mimeMix/MainGame.vue'),
                meta: {
                    stepLabel: 'Game'
                }
            },
            {
                name: 'mime-mix-game-done',
                path: 'GameEnd',
                component: () => import('./components/mimeMix/GameEndScreen.vue'),
                meta: {
                    stepLabel: 'Feedback'
                }
            }
        ]

    },

]