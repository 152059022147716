<script>
import Button from "@/components/forms/Button.vue";
import {useUserPermissions} from "@/composables/useUserPermissions";
import BurgerMenu from "../../Button/BurgerMenu.vue";
import CloseButton from "../../Button/CloseButton.vue";
import {computed, onBeforeUnmount, onMounted, onUpdated, ref} from "vue";
import "../../../assistants.css";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import SideBarListOwnAssistants from "../../Teaching/sidebar/SideBarListOwnAssistants.vue";
import {useSidebarPanel} from "../../../composables/useSidebarPanel";

export default {
  name: "LHome",
  components: {
    CloseButton,
    BurgerMenu,
    Button,
    SideBarListOwnAssistants,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const {updateSidebarPanels} = useSidebarPanel()
    const sidebarPanels = {
      '^\/teaching': {
        component: 'SideBarListOwnAssistants',
        params: {
          _appId: 'assistants',
          _dataType: 'TeachingAssistant'
        }
      }
    }

    const {
      isLoggedIn,
      onLogout
    } = useUserPermissions()

    const isSidebarOpen = ref(true)

    const panelComponent = computed(() => store.state.UI.sideBarPanel?.component)
    const panelParams = computed(() => store.state.UI.sideBarPanel?.params)

    const onToggleSidebar = () => {
      if (window.window.innerWidth <= 800) {
        isSidebarOpen.value = !isSidebarOpen.value;
      }
    };

    const onCloseSideBarIfNarrow = () => {
      if (window.window.innerWidth <= 800) {
        isSidebarOpen.value = false
      }
    }

    onMounted(() => {
      updateSidebarPanels(sidebarPanels)
      updateSidebarVisibility(); // Set the initial state based on the window size
      window.addEventListener('resize', updateSidebarVisibility);
    });

    onUpdated(() => {
      updateSidebarPanels(sidebarPanels)
    })
    const updateSidebarVisibility = () => {
      isSidebarOpen.value = window.innerWidth > 800;
    };


    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateSidebarVisibility);
    });

    const onLogin = () => router.push({query: {modal: 'login', redirect: location.host}})
    const onRegister = () => router.push({query: {modal: 'register', redirect: location.host}})

    return {
      isLoggedIn,
      isSidebarOpen,

      panelComponent,
      panelParams,

      onCloseSideBarIfNarrow,
      onLogin,
      onLogout,
      onRegister,
      onToggleSidebar,
    }
  }
}
</script>

<template>
  <div class="lHome assistants layout w-full h-screen">
    <BurgerMenu
        @click="onToggleSidebar"
        class="top-2 left-2 fixed h8:hidden z-50 bg-slate-100 flex"
        :class="{'!hidden ': isSidebarOpen}"
    />
    <div class="flex min-h-screen border-black relative h-full ">
      <div class="z-20">
        <div
            class="w-60 fixed h-screen flex flex-col bg-slate-900 text-white transform transition-all duration-300 ease-in-out"
            :class="{'-translate-x-60': !isSidebarOpen, '!translate-x-0': isSidebarOpen}"
        >
          <CloseButton class="absolute z-50 top-1 right-1 h8:hidden flex" @click="onToggleSidebar"/>
          <div class="flex items-center gap-2 p-3 justify-center cursor-pointer"
               @click="$router.push('/')">
            <div class="flex items-center gap-4 rounded-full w-8 h-8 p-2 bg-white">
              <img src="../../../../src/assets/images/Logo_CHI.png" alt="CHI Logo"
                   class="object-contain">
            </div>
            <h1 class=" text-sm">
              CHI Assistants
            </h1>
          </div>
          <div class="flex-grow w-full py-4 px-2 h-full">
            <div
                v-if="panelComponent"
                class="appSidebarPanel flex flex-col gap-1"
            >
              <component :is="panelComponent" v-bind="panelParams"></component>
            </div>
          </div>
          <div class="flex p-4 my-2 sticky bottom-2">
            <div
                v-if="!isLoggedIn"
                class="flex flex-col gap-4 justify-center w-full"
            >
              <Button
                  class="whiteButton !w-full"
                  @click="onLogin"
              >
                Login
              </Button>
              <Button
                  class="blackButton"
                  @click="onRegister"
              >
                Register
              </Button>
            </div>
            <div
                v-else
                class="flex gap-4 justify-center w-full"
            >
              <Button
                  @click="onLogout"
                  class="whiteButton !w-full"
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>


      <div
          class="flex-grow"
          :class="{'h8:ml-60 h8:opacity-100 opacity-30 transition duration-200 ': isSidebarOpen}"
          @click="onCloseSideBarIfNarrow()"
      >
        <div
            class="pointer-events-auto h-full"
            :class="{'h8:!pointer-events-auto !pointer-events-none': isSidebarOpen}"
        >
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
