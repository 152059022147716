<script>
import {ref, computed, onMounted, onBeforeUnmount} from "vue";
import {isString} from "@/lib/getVariableType";
import dropdown from "@/components/forms/Dropdown.vue";

export default {
  name: "DropdownSingle",
  computed: {
    dropdown() { // ?
      return dropdown;
    },
  },
  emits: ["select"],
  props: {
    title: {
      default: ''
    },
    items: {
      // first commitment is the 'all' option
      type: Array,
      required: true,
      validator: (v) =>
          Array.isArray(v) &&
          v.reduce((pass, item) => pass && isString(item), true),
    },
    disable: {
      type: Boolean,
      default: false
    },
    showSearch: {
      type: Boolean,
      default: true,
    }
  },
  setup(props, {emit}) {
    const options = computed(() => props.items);
    const show = ref(false);
    const searchInput = ref(null)
    const search = ref("");
    const label = computed(() => props.title ?? 'Select an item')

    const onClick = (e) => {
      e.preventDefault(); // do not bubble to button
      show.value = false;
      emit("select", e.target.id);
    };
    const closeDropdown = () => {
      show.value = false;
    };
    const onClickOutside = (event) => {
      const dropdownElement = event.target.closest(".dropdown.relative");
      const isSearchInput = event.target === searchInput.value;

      if (!dropdownElement && !isSearchInput) {
        closeDropdown();
      }
    };
    onMounted(() => {
      document.addEventListener("click", onClickOutside);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("click", onClickOutside);
    });
    const toggle = () => {
      if (show.value) {
        show.value = !show.value;
        search.value = ''
        selectFocused()
      } else {
        show.value = !show.value;
      }

    };
    const filteredItems = computed(() =>
        options.value.filter((item) =>
            item.toLowerCase()
                .includes(search.value.toLowerCase())
        )
    );
    const focusedIndex = ref(-1);
    const focusNext = () => {
      focusedIndex.value =
          focusedIndex.value === filteredItems.value.length - 1
              ? 0
              : focusedIndex.value + 1;
    };
    const focusPrev = () => {
      focusedIndex.value =
          focusedIndex.value === 0
              ? filteredItems.value.length - 1
              : focusedIndex.value - 1;
    };
    const selectFocused = () => {
      if (focusedIndex.value !== -1) {
        show.value = false;
        emit("select", filteredItems.value[focusedIndex.value]);
      }
    };

    const onFocusNextEl = e => {
      if (show.value && props.showSearch && document.activeElement !== searchInput.value) {
        searchInput.value.focus()
        e.stopPropagation()
        e.preventDefault()
      } else {
        show.value = false
      }
    }
    return {
      onClick,
      label,
      show,
      toggle,
      search,
      searchInput,
      filteredItems,
      focusedIndex,
      focusNext,
      focusPrev,
      onFocusNextEl,
      selectFocused,
    };
  },
};
</script>

<template>
  <div
      class="dropdownSingle  relative w-fit "
      @keydown.enter.stop="toggle"
      @keydown.space.prevent="toggle"
      @keydown.up.prevent="focusPrev"
      @keydown.down.prevent="focusNext"
      @keydown.tab="onFocusNextEl"
  >
    <button
        :class="['button', 'dropdown', disable ? 'opacity-70' : '']"
        type="button"
        :key="dropdown"
        class="bg-gray-100 !shadow-none hover:bg-white"
        v-bind="$attrs"
        tabindex="0"
        @click.stop="toggle()"
    >
      <div class="font-medium">
        {{ label }}
      </div>
      <svg
          class="ml-2 w-4 h-4 transition-transform duration-200"
          :class="{'rotate-180': show}"
          aria-hidden="true"
          fill="none"

          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </button>
    <!-- Dropdown menu -->
    <div
        v-show="show"
        class="z-10 w-full bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700"
        style="
        position: absolute; inset: -6px auto auto 0px;
        margin: 0px;
        transform: translate3d(0px, 44px, 0px);
      "
    >
      <div
          v-if="showSearch"
          class="px-1 py-1"
      >
        <input
            type="text"
            v-model="search"
            :tabindex="$attrs.tabindex || 1"
            autofocus
            class="w-full !py-1.5 px-2 text-sm text-gray-700 !rounded-md dark:text-gray-200"
            placeholder="Search items..."
            ref="searchInput"
            @keydown.enter.prevent.stop="selectFocused"
            @keydown.down.stop="focusNext"
            @keydown.up.stop="focusPrev"
        />
      </div>
      <ul
          class=" text-sm text-gray-700 dark:text-gray-200"
          @keydown.down.prevent="focusNext"
          @keydown.up.prevent="focusPrev"
          @keydown.enter.stop="selectFocused"
          @keydown.space.prevent.stop="selectFocused"
      >
        <li
            v-for="(item, index) in filteredItems"
            :key="item"
            @click.stop="onClick"
        >
          <div
              :id="item"
              :class="[
              'block py-2 px-4',
              index === focusedIndex ? 'bg-gray-200 dark:text-gray-700' : '',
              'hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
            ]"
              @mouseover="focusedIndex = index"
          >

            {{ item }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped></style>