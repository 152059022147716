<script>

import Markdown from "@/components/forms/Markdown.vue";
import {capitalize} from "../../../../server/lib/typeHelpers/stringFunctions/capitalization";
import IconCreation from "@/components/icons/Creation.vue";
import {computed, ref} from "vue";

export default {
  name: "CreaImage",
  methods: {capitalize},
  components: {
    IconCreation,
    Markdown,
  },
  props: {
    id: {
      type: String,
      required: true
    },
    submittedAt: {
      type: Number,
    },
    eventName: {
      type: String,
    },
    imageUrl: {
      type: String,
      required: true
    },
    title: {
      type: String,
    },
    prompt: {
      type: String,
    },
    Description: {
      type: String,
      default: ''
    },
    sourceUrl: {
      type: String,
    },
    similarities: {
      type: Number,
    },
  },
  setup(props) {
    const isExpanded = ref(false)
    const description = computed(() => props.Description ?? 'No Description Provided')
    const caption = computed(() => props.title ?? 'Unnamed Image')
    const onToggleEllipsis = () => {
      isExpanded.value = !isExpanded.value
    }
    return {
      caption,
      description,
      isExpanded,

      onToggleEllipsis,
    }
  }
}
</script>

<template>
  <div class="CreaImage">
    <div class="card cursor-pointer">
      <div class="card-image">
        <img :src="imageUrl" alt="image">
      </div>
      <div class="card-content flex flex-col gap-4 my-4">
        <label>Title</label>
        <em class="font-semibold">{{ capitalize(caption) }}</em>
        <div class="flex items-center gap-3">
          <IconCreation></IconCreation>
          <label>AI Image Description</label>
        </div>
        <Markdown
            :modelValue="description"
            :ellipsis-lines="6"
            :isExpanded="isExpanded"
            @toggle:ellipsis="onToggleEllipsis"
        ></Markdown>
      </div>
    </div>
  </div>
</template>
