<script>
export default {
  name: "GridIcon",
  props: {
    title: {
      type: String,
      default: "Graph icon"
    },
    decorative: {
      type: Boolean,
      default: false
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 24
    }
  }
}
</script>
<template>
  <span :aria-hidden="decorative"
        v-bind="$attrs"
        :aria-label="title"
        class="material-design-icon graph-icon"
        role="img">
  <svg xmlns="http://www.w3.org/2000/svg"
       :fill="fillColor"
       class="material-design-icon__svg"
       :width="size"
       :height="size"
       viewBox="0 0 24 24"
  >
  <defs>
      <linearGradient id="grid" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stop-color="#D3E2F2" /> <!-- Start color -->
        <stop offset="0%" stop-color="#001438" /> <!-- Start color -->
        <stop offset="50%" stop-color="#A9C2FF" /> <!-- Middle color -->
        <stop offset="100%" stop-color="#d1fae5" /> <!-- End color -->
      </linearGradient>
    </defs>
  <path fill="url(#grid)" d="M10,4V8H14V4H10M16,4V8H20V4H16M16,10V14H20V10H16M16,16V20H20V16H16M14,20V16H10V20H14M8,20V16H4V20H8M8,14V10H4V14H8M8,8V4H4V8H8M10,14H14V10H10V14M4,2H20A2,2 0 0,1 22,4V20A2,2 0 0,1 20,22H4C2.92,22 2,21.1 2,20V4A2,2 0 0,1 4,2Z">
    <title>{{ title }}</title>
    </path>
  </svg>
  </span>
</template>
