module.exports = {
    content: ["./index.html", "./chi-*/**/*.{vue,js,ts}", "./src/**/*.{vue,js,ts}"],
    safelist: [
        'bg-',
        'text-',
        'border-'
    ],
    darkMode: "class", // or 'media' or 'class'
    theme: {
        fontFamily: {
            header: ["Merriweather Light", "Merriweather Regular", 'serif'],
            body: ["Inter-600", "Inter-Semibold", 'sans-serif'],
            comic: ["SuperComic", 'sans']
        },
        screens: {
            h4: "400px",
            h6: "600px",
            h8: "800px",
            k1: "1000px",
            k11: "1100px",
            k12: "1200px",
            k13: "1300px",
            k14: "1400px",
            k15: "1500px",
            k16: "1600px",
            k18: "1800px",
            k2: "2000px",
            k22: "2200px",
            k24: "2400px",
            k25: "2500px",
            k26: "2600px",
            k28: "2800px",
            k3: "3000px",
            k32: "3200px",
            k34: "3400px",
            k35: "3500px",
            k36: "3600px",
            k38: "3800px",
            k4: "4000px",
            k42: "4200px",
            k44: "4400px",
        },
        extend: {
            colors: {
                wai: {
                    50: "#f2f7fb",
                    100: "#f7f0f8",
                    200: "#d3e2f2",
                    300: "#b9cfe8",
                    400: "#9cb6dd",
                    500: "#839dd1",
                    600: "#6a7fc1",
                    700: "#6374ae",
                    800: "#4a5989",
                    900: "#414e6e",
                    950: "#262c40",
                },
                cognikitBlue: "#6a7fc1"
            },
            gridTemplateColumns: {
                auto: "repeat( auto-fit, minmax(10px, 1fr) )",
            },
            keyframes: {
                wiggle: {
                    "0%": {transform: "translateX(0px)"},
                    "33%": {transform: "translateX(10px)"},
                    "66%": {transform: "translateX(-4px)"},
                    "100%": {transform: "translateX(0px)"},
                },
            },
            animation: {
                wiggle: "wiggle 1s ease-in-out",
            },
            dropShadowUtil: {
                '3xl': '38px 35px 35px rgba(255, 0, 0, 1)',
                '7xl': '38px 35px 35px rgba(255, 0, 0, 1)',
                '4xl': [
                    '0 35px 35px rgba(0, 0, 0, 0.25)',
                    '0 45px 65px rgba(0, 0, 0, 0.15)'
                ]
            },
            fontFamily: {
                custom: ['Arial', 'sans'],
            },
        },
    },
    plugins: [],
};
