<script>
import {computed} from "vue";

export default {
  name: "LListItemAssistant",
  props: {
    imageSrc: {
      type: String,
      default: '@/assets/images/Logo_CHI.png',
    },
    isPrivate: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const avatarSrc = computed(() => props.imageSrc || require('@/assets/images/Logo_CHI.png'))

    return {
      avatarSrc
    }
  }
}
</script>

<template>
  <div class="lListItemAssistant h-full group col-span-1 relative">
    <div
        class=" bg-gray-100 hover:bg-gray-200 rounded-md py-4 px-3 h-full hover:shadow-md shadow-sm shadow-gray-400 cursor-pointer">
      <div class="h-full flex gap-2 items-center flex-row align-middle">
        <div class="w-28 h-28 aspect-square bg-white rounded-full grid place-items-center ">
          <img
              :src="avatarSrc"
              alt="avatar"
              class="object-contain w-14 h-14"
          />
        </div>
        <div class="h-full w-fit flex-grow py-0.5">
          <div class="h-full w-full flex flex-col gap-4">
            <div class="font-extrabold text-lg leading-none">
              <slot name="name">Assistant Name</slot>
              <p v-if="isPrivate" class="text-red-700 inline-block">(private)</p>
            </div>

            <div class="
                h-full
                flex-grow
                flex flex-col
            ">
              <div
                  class="
                      flex-grow
                      overflow-hidden
                      text-sm text-gray-600 leading-tight
              ">
                <slot>Assistant instructions, maybe add an ellipsis after a couple of lines</slot>
              </div>
            </div>
            <div
                class="
                    absolute -bottom-1 right-2
                    py-2
                    flex justify-between
                    flex-col items-center gap-2
                    opacity-0
                    group-hover:opacity-100
                    transition-all duration-200
            ">
              <slot name="publicActions"></slot>
              <div class="
                  w-full
                  flex flex-col items-stretch
              ">
                <slot name="actions"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>