<script>
import LSection from "@/components/layout/LSection.vue";
import {ref, onMounted, onBeforeUnmount, computed} from "vue";
import {useUserPermissions} from "@/composables/useUserPermissions";
import {useStore} from "vuex";
import {LOGOUT} from "@/store/operations";
import {capitalize} from "@/lib/typeHelpers";
import {isArray, isObject} from "@/lib/getVariableType";
import {availableBreakpoints} from "@/lib/tailwind/tailwindBreakpoints";

const isValidLink = link =>
    !link ||
    isArray(link) &&
    link.reduce((pass, item) => {
      pass = pass && isObject(item) && item.name && item.route
      if (item.breakpoint) pass = pass && availableBreakpoints.includes(item.breakpoint)
      return pass
    }, true)

export default {
  name: 'MenuBar',
  components: {
    LSection,
  },
  props: {
    links: {
      type: Array,
      default: () => [{
        name: "Home",
        route: "/",
        breakpoint: 'h4'
      }],
      validator: isValidLink,
    },
  },
  setup() {
    const {isLoggedIn, account, user} = useUserPermissions();
    const showMenu = ref(false);
    const store = useStore();
    const onLogout = () => {
      store.dispatch(LOGOUT)
    };
    // if the screen width is less than 1000px, show the menu
    const checkScreenWidth = () => {
      return window.innerWidth < 1000;
    }
    // Add closeMenu method
    const closeMenu = () => {
      showMenu.value = false;
    };
    // Split first word of account.name, but only when account.name exists
    const splitName = computed(() => capitalize(account.value?.name?.split(" ")[0] ?? ""));

    // Add onClickOutside method
    const onClickOutside = (event) => {
      const menuElement = event.target.closest(".menuBar");
      if (!menuElement) {
        closeMenu();
      }
    };

    // Add and remove the onClickOutside listener
    onMounted(() => {
      document.addEventListener("click", onClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", onClickOutside);
    });


    return {
      checkScreenWidth,
      isLoggedIn,
      onLogout,
      showMenu,
      account,
      splitName,
      user,
    };
  },
};
</script>

<template>
  <LSection class="menuBar grid justify-start ">
    <nav
        :key="showMenu"
        class="
              menuBar
              w-fit
              text-gray-700
              text-base font-medium
          "
    >
      <div class="flex flex-wrap justify-between mt-1 ml-1 items-center z-10 w-fit">
        <div
            class="flex gap-1 items-center w-fit bg-cognikitBlue container-shadow rounded-full text-white p-2 py-1 "
            @click="$router.push({name: 'home'})"
        >
          <slot name="logo"></slot>
          <slot name="name"></slot>
        </div>

      </div>
    </nav>
  </LSection>
</template>

<style scoped></style>
