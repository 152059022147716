import {createRouter, createWebHistory} from "vue-router"
import config from '../../config.js'
import {getRouteSubdomain} from "@/router/lib/getRouteSubdomain";
import {selectAppRoutes, getNextRoute} from "@/router/lib/appRouter";
import {state} from "@/store/store";
import {getAppRoutes} from "@/router/lib/getAppRoutes";
import {nextTick} from "vue";

const debug = {
    appRoutes: true,
}

const {basePath} = config
const defaultRoutes = []

const scrollBehavior = (to, from, savedPosition) => {
    let position = {x: 0, y: 0}
    if (savedPosition && to.query.maintainScroll) position = savedPosition
    return position
}

const router = createRouter({
        history: createWebHistory('/'),
        base: basePath,
        routes: defaultRoutes,
        scrollBehavior
    },
)
router.addRoute(getAppRoutes('app'))

const detectNewSubdomain = to => {
    const newSubdomain = getRouteSubdomain(new URL(to.fullPath, window.location.origin).host)
    if (newSubdomain !== state.appId) return newSubdomain
}
router.beforeEach(async (to, from, next) => {
    const newSubdomain = detectNewSubdomain(to)

    let nextRoute = null
    if (newSubdomain) {
        selectAppRoutes(router, newSubdomain)
        await nextTick()
        if (debug.appRoutes) debug.subdomainInitialization = true

        nextRoute = router.getRoutes().find(r => r.path === to.path)
        nextRoute = ({
            ...to,
            params: to.params,
            query: to.query,
            meta: to.meta,
            replace: true,
        })
    } else {
        nextRoute = getNextRoute(router, to)
    }

    if (nextRoute) {
        return next(nextRoute)
    }

    if (router.hasRoute(to.name)) {
        next()
    } else {
        console.log(66, 'no route found', to.fullPath)
        next('/')
    }

})

export default router
const resolveForwardOnlyRoute = path => {
    let {backNavigateTo} = router.resolve(path)?.meta || {}
    if (backNavigateTo) return router.resolve({name: backNavigateTo})

    return path
}
export const onNavigateBack = back => {
    const backPath = back || '/'
    const nextRoute = resolveForwardOnlyRoute(backPath)

    router.push(nextRoute)
        .catch(err => {
            console.log(3232, 'navigation error', nextRoute)
            console.error(err)
        })
}
