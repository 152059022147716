import kortlinkActions from './kortlinkActions'
import showcaseActions from '../../../chi-showcase/actions'
import assistantActions from '../../../chi-assistants/actions/actions'
import delphiActions from '../../../chi-delphi/actions'
import creaVisionsActions from '../../../chi-creavisions/actions'
import CCFActions from '../../../chi-ccf/actions'
import appActions from '../../../chi-app/actions/actions'
import uploadActions from './uploadActions'
import ownedDocumentActions from './ownedDocumentActions'
import sharedDocumentActions from './sharedDocumentActions'
import freeDocumentActions from './freeDocumentActions'
import systemDocumentActions from './systemDocumentActions'
import documentActions from './documentActions'

const actions = {}

const addAction = (name, action) => {
    if (actions[name]) throw new Error(`action ${name} already exists`)
    actions[name] = action
}

for (const name in creaVisionsActions) { addAction(name, creaVisionsActions[name]) }
for (const name in CCFActions) { addAction(name, CCFActions[name]) }
for (const name in kortlinkActions) { addAction(name, kortlinkActions[name]) }
for (const name in showcaseActions) { addAction(name, showcaseActions[name]) }
for (const name in delphiActions) { addAction(name, delphiActions[name]) }
for (const name in assistantActions) { addAction(name, assistantActions[name]) }
for (const name in appActions) { addAction(name, appActions[name]) }
for (const name in uploadActions) { addAction(name, uploadActions[name]) }
for (const name in ownedDocumentActions) { addAction(name, ownedDocumentActions[name]) }
for (const name in sharedDocumentActions) { addAction(name, sharedDocumentActions[name]) }
for (const name in freeDocumentActions) { addAction(name, freeDocumentActions[name]) }
for (const name in systemDocumentActions) { addAction(name, systemDocumentActions[name]) }
for (const name in documentActions) { addAction(name, documentActions[name]) }

export default actions