<script setup>

</script>

<template>
  <div class="closeButton">
    <button class="p-1 rounded-md hover:!bg-white bg-slate-100">

      <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
           fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M6 18 18 6m0 12L6 6"/>
      </svg>
    </button>
  </div>
</template>

<style scoped>

</style>