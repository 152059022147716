import {
    CREATE_CHI_LIBRARY_COMPONENT,
    START_CHI_LIBRARY_DEVELOPMENT_STREAM,
} from "./operations";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import {handleResponse} from "@/store/actions/lib/handleResponse";
import axios from "@/plugins/axios";
import appId from './appId'
import {getApiUrl} from "@/store/lib/apiUrls";

const getAppUrl = (path) => `${getApiUrl()}/${appId}/${path}`

export default {
    [CREATE_CHI_LIBRARY_COMPONENT](context, {listenerName}) {
        const url = getAppUrl('create')
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                listenerName
            }
        })
            .then(handleResponse(context, CREATE_CHI_LIBRARY_COMPONENT))
    },
    [START_CHI_LIBRARY_DEVELOPMENT_STREAM](context, {
        listenerName,
        pipeName,
        componentId
    }) {
        const url = getAppUrl(`develop/${componentId}`)
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                listenerName,
                pipeName
            }
        })
    }
}
