<template>
  <button
      v-if="canEdit"
      class="
          p-1
          rounded-md
          flex items-center justify-center
          border border-gray-300
          hover:!bg-white
      "
      @click="$router.push({name: 'assistant', params: {_assistantId: assistant._id}})">

    <EditIcon/>
  </button>
</template>
<script>
import EditIcon from "./EditIcon.vue";

export default {
  name: 'EditButton',
  components: {EditIcon},
  props: {
    assistant: {},
    canEdit: {}
  }
}
</script>