<script>
import {computed} from "vue";

export default {
  name: "AlertMessage",
  props: {
    name: {
      type: String,
      default: ''
    },
  },
}
</script>
<template>
  <div class="alertStatus">
    <div
        class="
          px-4
          py-1
          rounded-lg
          flex items-center
          text-sm
          text-slate-800
          bg-slate-300
        "
        :bind="$attrs"
        role="alert"
    >
      <div class="icon flex items-center">

        <slot name="icon"></slot>

      </div>

      <div class="flex flex-wrap px-4">
        <span
            v-if="name"
            class="font-bold pr-4"
        >
          {{ name }}
        </span>
        <slot name="message"></slot>
      </div>
    </div>
  </div>
</template>

