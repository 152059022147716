import {useWebSocket} from "@/composables/pipe/useWebSocket";
import {ref} from "vue";
import {capitalize} from "@/lib/typeHelpers";

const debug = false

const states = {
    // state              socket          pipe
    initial:            ['',              ''            ],
    socketDisconnected: ['Disconnected',  ''            ],
    socketError:        ['Error',         ''            ],
    socketReady:        ['Ready',         ''            ],
    pipeReady:          ['Connected',     'Ready'       ],
    pipeError:          ['Connected',     'Error'       ],
    receiving:          ['Connected',     'Receiving'   ],
    sending:            ['Connected',     'Transmitting'],
}
export const usePipe = async ({
                                  listenerName = 'anonymous',
                                  pipeName,
                                  onEvent, // onEvent(payload, eventName)
                                  onSocketReady,
                                  onSocketClose,
                                  onSocketError,
                                  onPipeError,
                              }) => {

    if (!onSocketError) {
        onSocketError = (err) => {
            console.error('usePipe Socket Error', err)
        }
    }
    if (!pipeName) return onSocketError('pipeName is required')

    const socketState = ref(states.initial[0])
    const pipeState = ref(states.initial[1])

    const handleState = state => {
        if (debug) console.log(924, state)
        const validState = states[state]
        if (!validState) throw new Error(`Invalid Socket state: ${state}`)

        socketState.value = validState[0]
        pipeState.value = validState[1]
    }

    let send

    const onBeforeSend = () => handleState('sending')
    const onAfterSent = () => handleState('pipeReady')
    const handleSocketReady = () => {
        handleState('socketReady')
        if (onSocketReady) {
            onSocketReady()
        }
    }
    const handleSocketClose = () => {
        handleState('socketDisconnected')
        if (onSocketClose) onSocketClose()
    }
    const handleSocketError = (err) => {
        handleState('socketError')
        onSocketError(err)
    }

    const handleEvent = (payload, eventName, targetPipeName) => {
        const isReceiver = pipeName === targetPipeName
        if (!isReceiver) return
        handleState('receiving')
        if (debug) console.log(422, pipeName, eventName, payload)
        onEvent(payload, eventName, targetPipeName)
        handleState('pipeReady')
    }
    try {
        const socket = await useWebSocket({
            channelName: pipeName,
            listenerName,
            onMessage: handleEvent,
            onBeforeSend,
            onAfterSent,
            onSocketOpen: handleSocketReady,
            onSocketClose: handleSocketClose,
            onSocketError: handleSocketError,
        })
        send = (data, eventName) => {
            handleState('sending')
            socket.send(data, eventName)
            handleState('pipeReady')
        }
    } catch (err) {
        if (debug) console.error(4293, 'usePipe Error', err)
        handleState('pipeError')
        onPipeError(err)
    }
    return {
        socketState,
        pipeState,
        send,
    }
}