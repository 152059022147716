<script>
import {computed} from "vue";

export default {
  name: "LThreadMessage",
  props: {
    invert: {
      type: Boolean,
      default: false,
    },
    showRole: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const style = computed(() => {
      return {
        marginLeft: props.invert ? 'auto' : '0',
        marginRight: props.invert ? '0' : 'auto',
        maxWidth: '800px',
        width: 'fit-content',
      }
    })
    const bgColorStyle = computed(() => ({

      backgroundColor: props.invert ? '#00ff0010' : '#0000ff10',
      marginLeft: props.invert ? '34px' : 'auto',
      marginRight: props.invert ? 'auto' : '34px',
      float: props.invert ? 'right' : 'left',
    }))
    const headerStyle = computed(() => ({
      flexDirection: props.invert ? 'row-reverse' : 'row',
    }))
    const avatarStyle = computed(() => ({
      flexDirection: props.invert ? 'row-reverse' : 'row',
    }))

    const imageSrc = computed(() => !props.invert ? require('../../assets/chatAvatar.png') : require('../../assets/userAvatar.png'))

    return {
      avatarStyle,
      imageSrc,
      headerStyle,
      style,
      bgColorStyle
    }
  }
}
</script>

<template>
  <div
      class="lThreadMessage"
      :style="style"
  >
    <div class="flex justify-start gap-1"
         :style="avatarStyle"
    >
      <div class="w-8">
        <div class="
            h-8 w-8
            rounded-full
        ">
          <img :src="imageSrc" class="object-contain" alt="">
          <slot name="avatar"></slot>
        </div>
      </div>
      <div class="group relative">
        <div
            class="header flex items-center leading-none"
            :style="headerStyle"
        >
          <div
              class="k1:h-8 h-auto flex k1:flex-row flex-col k1:items-center items-start k1:gap-2 gap-0"
              :class="{
                'ml-2': !invert,
                'mr-2': invert,
              }"
          >
            <h2>
              <slot name="name">Anonymous</slot>
            </h2>
            <h2 v-if="showRole">
              (
              <slot name="role"></slot>
              )
            </h2>
          </div>
          <p class=" text-[10px] absolute -bottom-2 whitespace-nowrap text-gray-500 px-2 self-end leading-none group-hover:opacity-100 opacity-0 transition-opacity duration-200 ">
            <slot name="time"></slot>
          </p>
        </div>
        <div
            class="
              w-fit
              rounded-lg
              my-1
              p-2 py-1
            "
            :style="bgColorStyle"
        >
          <slot name="text"></slot>
        </div>
      </div>
    </div>

  </div>
</template>
