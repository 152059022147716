<script>
import DelphiMenuBar from "../../MenuBar.vue";
import LView from "@/components/layout/LView.vue";
import IconHome from "../../icons/Home.vue";
import '../../../delphi.css'
import {useStore} from "vuex";
import {onBeforeUnmount, onMounted} from "vue";
import {useRoute} from "vue-router";
import {INIT_STUDY_SUITE, INIT_LOCAL_SUITE} from "../../../operations";
import {v4 as uuid} from 'uuid'

const isInDevelopment = process.env.NODE_ENV === 'development'

let debug = true

debug = debug && isInDevelopment

export default {
  name: "LHome",
  components: {
    DelphiMenuBar,
    IconHome,
    LView,
  },
  setup: function () {
    const store = useStore()
    const route = useRoute()

    const initDelphiSuite = () => {
      let {participant_id, origin, debug_reset_participant} = route.query
      let action

      store.state.delphi = store.state.delphi || {}

      const ctx = store.state.delphi
      ctx.parentWindowOrigin = origin || window.location.origin

      if (debug) ctx.parentWindowOrigin = window.location.origin

      const isInitiated = ctx._participantId && ctx._suiteId

      if (!isInitiated) {
        const params = {
          _participantId: ctx._participantId,
          parentOrigin: ctx.parentWindowOrigin,
        }
        if (participant_id) {
          ctx._participantId = participant_id
          params._participantId = participant_id
          action = INIT_STUDY_SUITE
          params.resetParticipantForTesting = debug_reset_participant
          ctx.suiteType = 'delphi'
        } else {
          ctx.suiteType = 'chi'
          ctx._participantId = uuid()
          params._participantId = ctx._participantId
          action = INIT_LOCAL_SUITE
        }
        store.dispatch(action, params)
            .then(({data}) => {
              const {Suite, _resumableGames} = data
              if (!Suite) throw new Error('No suite found')
              const {_id} = Suite[0]
              ctx._resumableGames = _resumableGames || []
              ctx._suiteId = _id

              const messageGamesRemaining = {
                name: 'games_remaining',
                payload: {
                  playableGamesRemaining: ctx._resumableGames.length
                },
              }
              parent.postMessage(
                  messageGamesRemaining,
                  '*', //ctx.parentWindowOrigin
              )
            })
            .catch(err => {
              console.error('Error initializing test suite', err)
            })
      }
    }

    const handleGameEvents = (event) => {
      const {data} = event
      const {name, payload} = data

      switch (name) {
        case 'games_remaining':
          console.log('Number of playable games remaining:', payload.playableGamesRemaining)
          break
        case 'game_score':
          const [gameName, score] = Object.entries(payload)[0]
          store.dispatch('updateGameScore', {gameName, score})
          break
      }
    }
    onMounted(() => {
      if (debug) window.addEventListener('message', handleGameEvents)
      initDelphiSuite()
    })
    onBeforeUnmount(() => {
      if (debug) window.removeEventListener('message', handleGameEvents)
    })
  }
}
</script>

<template>
  <div class="lHome delphi ">
    <LView :use-margins="false">
      <template #menubar>
        <DelphiMenuBar
            class="fixed w-12 h-fit  left-1 top-1 pointer-events-none"
            style="z-index: 1000;"
        >
          <template #logo>
            <div class="flex items-center clickable rounded-full    text-shadow-small">
              <IconHome :size="28"></IconHome>
            </div>
          </template>
          <template #name>
            <div class="clickable">
              <p class="k1:text-2xl text-xl font-extrabold text-shadow-small">CogniKit</p>
            </div>
          </template>
        </DelphiMenuBar>

      </template>
      <template #default>
        <div class="flex-grow flex flex-col h-full w-full ">
          <router-view></router-view>
        </div>
      </template>
    </LView>
  </div>
</template>
