import dayjs from 'dayjs'
import Duration from 'dayjs/plugin/duration'
import RelativeTime from 'dayjs/plugin/relativeTime'
import WeekYear from 'dayjs/plugin/weekYear'
import QuarterOfYear from 'dayjs/plugin/quarterOfYear'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'
import ObjectSupport from 'dayjs/plugin/objectSupport'
import ArraySupport from 'dayjs/plugin/arraySupport'
import IsToday from 'dayjs/plugin/isToday'
import UTC from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/en'

const strictThresholds = [
    { l: 's', r: 1 },
    { l: 'ss', r: 59, d: 'second' },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 29, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y', r: 1 },
    { l: 'yy', r: Infinity, d: 'year' }
];

dayjs.extend(WeekYear)
dayjs.extend(Duration)
dayjs.extend(RelativeTime, { thresholds: strictThresholds, rounding: Math.floor });
dayjs.extend(QuarterOfYear)
dayjs.extend(CustomParseFormat)
dayjs.extend(ObjectSupport)
dayjs.extend(ArraySupport)
dayjs.extend(IsToday)
dayjs.extend(UTC)
dayjs.extend(Timezone)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
    relativeTime: {
        ...dayjs.Ls['en'].relativeTime,
        'ss': '%d seconds',
        'mm': '%d minutes',
        'hh': '%d hours',
        'dd': '%d days',
        'MM': '%d months',
        'yy': '%d years',
    }
})

export default dayjs
