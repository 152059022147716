<script>
import LToolCall from "./layout/LToolCall.vue";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "ToolCall",
  components: {LToolCall},
  props: {
    stepId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const toolCall = computed(() => {
      const step = store.state.docs?.Step?.[props.stepId]
      let call
      if (step) call = step.step_details?.tool_calls?.find(c => c.id === props.id)
      return call
    })
    const name = computed(() => toolCall.value?.function.name)
    const args = computed(() => toolCall.value?.function.arguments)

    const lines = computed(() => {
      return props.item.output?.split('\n')
    })

    return {
      name,
      args,
      lines
    }
  }
}
</script>

<template>
<div class="toolCall">
  <LToolCall :title="`callId: ${id}`">
    <template #status>
    </template>
    <template #name>
      <div class="name">
        {{name}}({{args}})
      </div>
    </template>
    <template #output>
<!--    todo: aggregate forwarded step-delta events -->
    </template>
  </LToolCall>
</div>
</template>

<style scoped>

</style>