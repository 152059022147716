import {isWebSocketConnected, subscribeToWebSocket} from "@/composables/assetUpdateStream/lib/webSocket";
import {state} from "@/store/store";
import {WEBSOCKET} from "@/store/operations";
import {ref} from "vue";
import {packPipeMessage} from "@/composables/pipe/packPipeMessage";

const debug = false

export const useWebSocket = async ({
                                       channelName,
                                       listenerName,
                                       onMessage,
                                       onBeforeSend,
                                       onAfterSent,
                                       onSocketOpen,
                                       onSocketClose,
                                       onSocketError,
                                   }) => {
    if (!onSocketError) {
        state.status[WEBSOCKET] = {
            isError: true,
            message: `
useWebSocket ERROR: Cannot connect to webSocket. 
onSocketError is required.
        `
        }
        onSocketError = (err) => {
            //todo use App.vue global error handler
            console.error(9131, 'useWebSocket Socket Error', err)
        }
    }
    if (!channelName) return onSocketError('Cannot connect to webSocket. channelName is required')
    if (!listenerName) return onSocketError('Cannot connect to webSocket. listenerName is required')
    if (!onMessage) return onSocketError('Cannot connect to webSocket. onMessage is required')

    const ws = ref(null)
    try {
        ws.value = await subscribeToWebSocket({
            pipeName: channelName,
            listenerName,
            onMessage,
            onOpen: onSocketOpen,
            onClose: onSocketClose,
            onError: onSocketError,
        })
        if (debug) console.log(42, 'subscribed to webSocket', channelName, listenerName, ws.value)
    } catch (err) {
        onSocketError(err)
    }

    const send = (data, eventName) => {
        if (onBeforeSend) onBeforeSend()
        if (!isWebSocketConnected()) onSocketError('Cannot send message. WebSocket is not connected.')
        const message = packPipeMessage(
            channelName,
            data,
            eventName
        )
        ws.value.send(message)
        if (onAfterSent) onAfterSent()
    }

    return {
        send
    }
}