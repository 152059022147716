<script>
import LSection from "@/components/layout/LSection.vue";
import {useRouter} from "vue-router";
import {useUserPermissions} from "@/composables/useUserPermissions";
import {watch} from "vue";
import Button from "@/components/forms/Button.vue";

export default {
  name: "Home.vue",
  components: {
    Button,
    LSection,
  },
  setup() {
    /*
       setup experiment
       login
          - with token as player
          - with account as mads
     */
    const router = useRouter()
    const {isLoggedIn} = useUserPermissions()

    watch(isLoggedIn, (val) => {
          if (val) {
            router.push({name: 'lobby'})
          }
        },
        {immediate: true}
    )

    const onLogin = () => router.push({query: {modal: 'enterTokenWithUserId'}})

    return {
      onLogin,
      isLoggedIn,
    }
  }
}
</script>

<template>
  <div class="home view h-screen overflow-hidden">
    <LSection>
      <div class="flex flex-col h-full flex-grow overflow-y-clip  ">
        <div
            class="
                flex-grow
                flex flex-col
                justify-center
                items-center
            "
        >
          <Button
              v-if="!isLoggedIn"
              @click="onLogin"
          >
            Login
          </Button>
        </div>
      </div>
    </LSection>
  </div>
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>

