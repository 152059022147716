<script>
import LSection from "@/components/layout/LSection.vue";
import {useRouter} from "vue-router";
import {useUserPermissions} from "@/composables/useUserPermissions";
import {watch} from "vue";
import Button from "@/components/forms/Button.vue";

export default {
  name: "Home.vue",
  components: {
    Button,
    LSection,
  },
  setup() {

    return {

    }
  }
}
</script>

<template>
  <div class="home view h-screen overflow-hidden">
    <LSection class="
        px-2 k1:px-4
        py-8
      "
    >
      <div class="flex flex-col h-full flex-grow overflow-y-clip">
        <div class="heading1">CHI Application Showcase</div>
        <div class="flex flex-col flex-grow overflow-y-clip gap-6">
          <Button
              :is-alt="true"
              @click="$router.push('/APMM')"
          >
            APMM
          </Button>
          <Button
              :is-alt="true"
              @click="$router.push('/library')"
          >
            Library
          </Button>
        </div>
      </div>
    </LSection>
  </div>
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>

