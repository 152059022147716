<script>
import CreaImage from "./CreaImage.vue";
import { computed, ref } from "vue";
import ImageSlider from "../ImageSlider.vue";
import CloseIcon from "@/components/icons/Close.vue";
import { useStore } from "vuex";
import { LOG_CREA_VISIONS_ENTRY } from "../../operations";
import Priorities from "../Priorities.vue";

export default {
  name: "ImageGallery",
  components: {
    Priorities,
    CloseIcon,
    ImageSlider,
    CreaImage,
    Image,
  },
  emits: ["selected"],
  props: {
    images: {
      type: Array,
      required: true,
    },
    selectedSrc: {
      type: String,
      default: "",
    },
  },
  setup: (props, { emit }) => {
    const popup = ref(null);
    const store = useStore();
    const selectedImageSrc = computed(() => props.selectedSrc);
    const selectedImage = computed(() => {
      if (!props.selectedSrc) return null;
      return props.images.find((i) => i.imageUrl === selectedImageSrc.value);
    });

    const onSelectImage = (image) => {
      if (image) {
        emit("selected", image?.imageUrl ?? "");
        popup.value.style.width = popup.value.parentElement.clientWidth + "px";
        store.dispatch(LOG_CREA_VISIONS_ENTRY, {
          action: "view image",
          imageJSON: JSON.stringify(image),
        });
      }
    };

    return {
      popup,
      selectedImage,
      selectedImageSrc,

      onSelectImage,
    };
  },
};
</script>
<template>
  <div
    class="imageGallery grid grid-cols-1 k1:grid-cols-2 k15:grid-cols-3 k2:grid-cols-4 gap-4 k1:gap-6 w-full items-center"
    :class="{'overflow-hidden max-h-screen': selectedImageSrc}"
  >
    <div
      v-show="selectedImageSrc"
      ref="popup"
      class="flex flex-col absolute top-0 popup w-full h-full bg-white/50"
      style="z-index: 500"
      @click.stop="onSelectImage"
    >
      <div
        class="flex bg-white sticky top-0 w-full h-fit rounded-2xl shadow-2xl shadow-black p-4 k1:p-8 k15:p-12 mt-8 mb-8 overflow-scroll overscroll-contain"
        @click.stop
      >
        <div class="z-20 absolute top-0 right-0">
          <div
            class="flex text-red-800 rounded-full bg-white/50 cursor-pointer"
            @click.stop="onSelectImage"
          >
            <CloseIcon :size="48" title="close"></CloseIcon>
          </div>
        </div>
        <div v-if="selectedImage" class="flex flex-col justify-center">
          <ImageSlider v-bind="selectedImage"></ImageSlider>
        </div>
      </div>
    </div>

    <CreaImage
      v-for="image in images"
      :id="image.id"
      :key="image.id"
      :isSelected="selectedImageSrc === image.imageUrl"
      v-bind="image"
      @click.stop="onSelectImage(image)"
    ></CreaImage>
  </div>
</template>
