import {reactive} from "vue";
import blocks from './rounds.js'
import {LOG_USER_RESPONSE} from "../../../../operations";
import store from "@/store/store";

export const state = reactive({
    blockIds: blocks.map(b => b.blockId),
})
const generateScoreObject = () => {
    return Object.fromEntries(
        blocks.map(b =>
            [
                b.blockId,
                {
                    correctResponses: 0,
                    totalResponses: 0
                }
            ]
        )
    )
}

const resetState = () => {
    // state.currentBlockIndex is set by onPlayGame
    state.isRunning = false
    state.currentRoundIndex = 0
    state.block = blocks[state.currentBlockIndex]
    state.stimuli = state.block.stimuli
    state.stimulus = state.stimuli[state.currentRoundIndex]
    state.score = state.score || generateScoreObject()
}
const iterateGames = () => {
    state.currentRoundIndex = 0
    state.currentBlockIndex++
    state.block = blocks[state.currentBlockIndex]
    state.stimuli = state.block?.stimuli ?? []

    state.isRunning = false
}
const iterateRounds = () => {
    state.currentRoundIndex++
    const isEndOfBlock = !(state.currentRoundIndex < state.stimuli.length)
    if (isEndOfBlock) iterateGames()

    state.stimulus = state.stimuli[state.currentRoundIndex]
}

const scoreAnswer = (answer) => {
    const leftSide = state.stimulus.slice(0,3).join('')
    const rightSide = state.stimulus.slice(3,6).join('')
    const areEqual = leftSide === rightSide
    const isCorrect = answer === areEqual

    if (isCorrect) state.score[state.block.blockId].correctResponses++;

    state.score[state.block.blockId].totalResponses++;
    return isCorrect
}
const evaluateResponse = (response) => {
    if ([true, false].includes(response)) return scoreAnswer(response)
}
export const onAnswer = (response) => {
    const isCorrect = evaluateResponse(response)
    store.dispatch(LOG_USER_RESPONSE, {
        _suiteId: store.state.delphi._suiteId,
        _gameId: store.state.delphi._gameId,
        _participantId: store.state.delphi._participantId,
        _promptId: store.state.delphi._promptId,
        attributes: {
            respondedAt: Date.now(),
            response,
            isCorrect,
        }
    })
      .catch(err => console.log("ERROR LOGGING FLASH CATCH COMPARISON RESPONSE", err))
    store.state.delphi._promptId = 'pending...'
    iterateRounds()
}
const loadBlock = (blockId) => {
    if (!blockId) throw new Error("blockId is required.")
    state.currentBlockIndex = blocks.findIndex(b => b.blockId === blockId)
    if (state.currentBlockIndex === -1) throw new Error("Invalid blockId. Unknown Game.")
    resetState()
}
export const onResetGame = () => {
    loadBlock(blocks[0].blockId)
    delete state.score
    resetState()
}
export const onPlayGame = (blockId, resetScores) => {
    loadBlock(blockId)
    if (resetScores) delete state.score
    resetState()
    state.score[blockId].correctResponses = 0
    state.score[blockId].totalResponses = 0
    state.isRunning = true
}