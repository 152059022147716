<script>
export default {
  name: "Graph",
  props: {
    title: {
      type: String,
      default: "Graph icon"
    },
    decorative: {
      type: Boolean,
      default: false
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 24
    }
  }
}
</script>
<template>
  <span :aria-hidden="decorative"
        v-bind="$attrs"
        :aria-label="title"
        class="material-design-icon graph-icon"
        role="img">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :fill="fillColor"
        class="material-design-icon__svg"
        :width="size"
        :height="size"
        viewBox="0 0 24 24"
    >
      <defs>
        <linearGradient id="speaker" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stop-color="#d1fae5" /> <!-- Start color -->
      <stop offset="50%" stop-color="#A9C2FF" /> <!-- Middle color -->
      <stop offset="100%" stop-color="#D3E2F2" /> <!-- End color -->
        </linearGradient>
      </defs>
  <path fill="url(#speaker)" d="M8,7A2,2 0 0,1 10,9V14A2,2 0 0,1 8,16A2,2 0 0,1 6,14V9A2,2 0 0,1 8,7M14,14C14,16.97 11.84,19.44 9,19.92V22H7V19.92C4.16,19.44 2,16.97 2,14H4A4,4 0 0,0 8,18A4,4 0 0,0 12,14H14M21.41,9.41L17.17,13.66L18.18,10H14A2,2 0 0,1 12,8V4A2,2 0 0,1 14,2H20A2,2 0 0,1 22,4V8C22,8.55 21.78,9.05 21.41,9.41Z">
    <title>{{ title }}</title>
      </path>
    </svg>
  </span>
</template>








