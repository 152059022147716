
export const capitalize = string => {
  let capitalized = ''
  string = string ?? ''
  string = string.toString()
  if (string) {
    return `${ string.charAt(0).toUpperCase() }${ string.substring(1) }`
  }
  return capitalized
}
export const decapitalize = (string = '') => {
  let decapitalized = ''
  string = string ?? ''
  string = string.toString()
  if (string) {
    return string.charAt(0).toLowerCase() + string.substring(1)
  }
  return decapitalized
}
