<script>
import {computed, onMounted, onUnmounted, ref} from "vue";
import {withPostfix} from "@/lib/prefixPostfix";
import QuizIcon from "./icons/QuizIcon.vue";
import GraphIcon from "./icons/GraphIcon.vue";
import GridIcon from "./icons/GridIcon.vue";
import ArrowsIcon from "./icons/ArrowsIcon.vue";
import CircleIcon from "./icons/CircleIcon.vue";
import SpeakerIcon from "./icons/SpeakerIcon.vue";

const icons = {
  QuizIcon,
  GraphIcon,
  GridIcon,
  ArrowsIcon,
  CircleIcon,
  SpeakerIcon
};

export default {
  name: "GameCard",
  props: {
    isClosed: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Game Name'
    },
    iconComponentName: {
      type: String,
      default: ""
    },
  },
  setup(props) {
    const componentSize = ref(250);

    const iconComponent = computed(() => icons?.[withPostfix(props.iconComponentName, 'Icon')]);
    const updateComponentSize = () => {
      const width = window.innerWidth;
      if (width < 600) {
        componentSize.value = 100;
      } else if (width < 1024) {
        componentSize.value = 150;
      } else {
        componentSize.value = 200;
      }
    };

    onMounted(() => {
      updateComponentSize(); // Set initial size based on current window size
      window.addEventListener('resize', updateComponentSize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateComponentSize);
    });

    return {
      iconComponent,
      componentSize
    }
  }
}
</script>

<template>
  <div
      class="
        gameCard
        h-full w-full
        k1:px-10 px-2
        k1:py-4 py-0
        flex items-center justify-center
        border border-gray-200
        rounded-2xl
        shadow-lg
      "
      :class="{
        'opacity-30': isClosed,
        'hover:bg-gray-200 cursor-pointer': !isClosed
      }"
  >
    <div class="flex flex-col items-center w-full k1:space-y-5 ">
      <component :is="iconComponent" :size="componentSize"/>
      <p class="font-semibold k1:text-2xl text-md">{{ title }}</p>
    </div>
  </div>
</template>
