<template>
  <LSection class="menuBar bg-white">
    <nav
        :key="showMenu"
        class="
              menuBar
              w-full
              h-20
              text-gray-700
              text-base font-medium
              dark:bg-gray-900
          "
    >
      <div class="flex flex-wrap justify-between mt-3 h6:mt-0 items-center z-10">
        <div
            class="flex gap-12 items-center"
            @click="$router.push({name: 'home'})"
        >
          <slot name="logo"></slot>
          <slot name="name"></slot>
        </div>
        <div class="hidden k1:visible k1:flex k1:gap-4 text-lg">
          <router-link
              :to="{name: 'home'}"
              :class="{'text-black font-bold': $route.name === 'home'}"
          >
            App Home
          </router-link>
        </div>
        <div class="k1:ml-8 gap-2 items-center flex k1:gap-4">
          <button
              type="button"
              class="bg-transparent inline-flex items-center p-2 ml-0 text-sm text-gray-500 rounded-lg h8:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 k1:hidden"
              @click="showMenu = !showMenu"
          >
            <span class="sr-only">Open navigation menu</span>
            <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!--  Burger Menu Routes  -->
        <div
            v-if="showMenu"
            id="navbar-default"
            class="w-full k1:hidden k1:w-auto"
            :class="{ hidden: !showMenu }"
            @click="() => showMenu = false"
        >
          <div
              class="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-200 h8:flex-row k1:space-x-8 h8:mt-0 h8:text-sm h8:font-medium h8:border-0 h8:bg-white dark:bg-gray-800 h8:dark:bg-gray-900 dark:border-gray-700"
          >
            <router-link
                to="/"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 h8:hover:bg-transparent h8:border-0 h8:hover:text-blue-700 h8:p-0 dark:text-gray-400 h8:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white h8:dark:hover:bg-transparent"
                :class="{
                  'text-white bg-blue-700 hover:bg-gray-600':
                    $route.name === 'home',
                  'hover:bg-gray-200 hover:text-gray-700':
                    $route.name !== 'home',
                }"
            >
              Home
            </router-link>


          </div>
        </div>
      </div>
    </nav>
  </LSection>
</template>
<script>
import LSection from "@/components/layout/LSection.vue";
import {ref, onMounted, onBeforeUnmount, computed} from "vue";
import {useUserPermissions} from "@/composables/useUserPermissions";
import {useStore} from "vuex";
import {LOGOUT} from "@/store/operations";
import {capitalize} from "@/lib/typeHelpers";
import {isArray, isObject} from "@/lib/getVariableType";
import {availableBreakpoints} from "@/lib/tailwind/tailwindBreakpoints";

const isValidLink = link =>
    !link ||
    isArray(link) &&
    link.reduce((pass, item) => {
      pass = pass && isObject(item) && item.name && item.route
      if (item.breakpoint) pass = pass && availableBreakpoints.includes(item.breakpoint)
      return pass
    }, true)

export default {
  components: {
    LSection,
  },
  props: {
    links: {
      type: Array,
      default: () => [{
        name: "Home",
        route: "/",
        breakpoint: 'h4'
      }],
      validator: isValidLink,
    },
  },
  name: "MenuBar",
  setup() {
    const {isLoggedIn, account, user} = useUserPermissions();
    const showMenu = ref(false);
    const store = useStore();
    const onLogout = () => {
      store.dispatch(LOGOUT)
    };
    // if the screen width is less than 1000px, show the menu
    const checkScreenWidth = () => {
      return window.innerWidth < 1000;
    }
    // Add closeMenu method
    const closeMenu = () => {
      showMenu.value = false;
    };
    // Split first word of account.name, but only when account.name exists
    const splitName = computed(() => capitalize(account.value?.name?.split(" ")[0] ?? ""));

    // Add onClickOutside method
    const onClickOutside = (event) => {
      const menuElement = event.target.closest(".menuBar");
      if (!menuElement) {
        closeMenu();
      }
    };

    // Add and remove the onClickOutside listener
    onMounted(() => {
      document.addEventListener("click", onClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", onClickOutside);
    });

    return {
      checkScreenWidth,
      isLoggedIn,
      onLogout,
      showMenu,
      account,
      splitName,
      user,
    };
  },
};
</script>

<style scoped></style>
