<script>
import LSection from "@/components/layout/LSection.vue";
import {computed, onMounted, onUpdated} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import GameCard from "../GameCard.vue";
import {INIT_LOCAL_SUITE, INIT_STUDY_SUITE} from "../../operations";

export default {
  name: "Home.vue",
  components: {
    GameCard,
    LSection,
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const goToLinks = () => {
      window.open("https://links.scienceathome.org/index", "_blank");
    };
    const goToGame = routeName => {
      const isClosed = store.state.delphi?._resumableGames?.includes(routeName)
      if (isClosed) return
      // if (routeName === 'links') return goToLinks()
      router.push({name: routeName})
    }

    const cards = computed(() => {
      const resumableGames = computed(() => store.state.delphi?._resumableGames || [])

      const cards = [
        {
          routeName: 'quiz-time',
          iconComponent: 'Quiz',
          label: 'Quiz Time',
          isClosed: !resumableGames.value.includes('QuizTime'),
        }, {
          routeName: 'flash-match',
          iconComponent: 'Arrows',
          label: 'Flash Match',
          isClosed: !resumableGames.value.includes('FlashMatch'),
        }, {
          routeName: 'links',
          iconComponent: 'Graph',
          isClosed: !resumableGames.value.includes('Links'),
          label: 'Links'
        }, {
          routeName: 'color-catch',
          iconComponent: 'Grid',
          isClosed: !resumableGames.value.includes('ColorCatch'),
          label: 'Color Catch'
        }, {
          routeName: 'mime-mix',
          iconComponent: 'Circle',
          isClosed: !resumableGames.value.includes('MimeMix'),
          label: 'Mime Mix'
        }, {
          routeName: 'morning-memo',
          iconComponent: 'Speaker',
          isClosed: !resumableGames.value.includes('MorningMemo'),
          label: 'Morning Memo'
        }
      ]
      return cards
    })
    const action = computed(() => store.state.delphi?.suiteType === 'delphi' ? INIT_STUDY_SUITE : INIT_LOCAL_SUITE)

    const syncSuite = () => {
      if (!store.state.delphi?._suiteId) return
      store.dispatch(action.value, {
        _participantId: store.state.delphi._participantId,
        parentOrigin: store.state.delphi.parentWindowOrigin,
      })
          .then(({data}) => {
            const {Suite, _resumableGames} = data
            if (!Suite) throw new Error('No suite found')
            store.state.delphi._resumableGames = _resumableGames || []

            const messageGamesRemaining = {
              name: 'games_remaining',
              payload: {
                playableGamesRemaining: store.state.delphi._resumableGames.length ?? 0
              },
            }
            parent.postMessage(
                messageGamesRemaining,
                '*',
            )
          })
          .catch(err => {
            console.error('Error Synchronizing test suite', err)
          })
    }
    onMounted(syncSuite)

    return {
      cards,
      goToGame,
    }
  }
}
</script>

<template>
  <div class="home view h-screen overflow-hidden">
    <LSection>
      <div class="flex flex-col h-full flex-grow overflow-y-clip  ">
        <div
            class="
                flex-grow
                grid h8:grid-cols-3 grid-cols-2 px-6 py-6 k12:px-44 k12:py-10 k1:gap-4 gap-4
                place-items-center
        ">
          <GameCard
              v-for="card in cards"
              :key="card.routeName"
              :isClosed="card.isClosed"
              :title="card.label"
              :iconComponentName="card.iconComponent"
              @click="goToGame(card.routeName)"
          ></GameCard>
        </div>
      </div>
    </LSection>
  </div>
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>

