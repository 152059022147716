<script>
import {computed, onMounted, ref, watch} from "vue";
import IconConnection from "@/components/icons/Connection.vue";
import IconLANDisconnect from "@/components/icons/LANDisconnect.vue";
import {usePipe} from "../../../chi-assistants/composables/usePipe";

const debug = true

export default {
  /*
      listens and streams an established pipe to the parent component
   */
  name: "Pipe",
  components: {
    IconLANDisconnect,
    IconConnection
  },
  emits: [
    'message',
    'ready',
    'disconnect',
    'socket_close',
    'socket_error',
    'pipe_error',
    'socket_status',
    'pipe_status',
  ],
  props: {
    pipeName: {
      type: String,
      required: true,
    },
    listenerName: { // todo: prevent identity theft (shadowing) it will multiply events
      type: String,
      default: 'Pipe',
    },
  },
  setup(props, {emit}) {
    const onSocketReady = () => {
      if (debug) console.log(399, 'pipe established. successfully upgraded to webSocket')
      if (sendPipeMessage) emit('ready', sendPipeMessage)
    }
    const onSocketError = (err) => {
      console.log(313, 'Socket Error', err)
      emit('socket_error', err)
    }
    const onSocketClose = () => {
      if (debug) console.log(318, 'Socket Closed')
      emit('socket_close')
    }
    const onMessage = (payload, eventName, targetPipeName) => {
      if (targetPipeName === props.pipeName) {
        // if (debug) console.log(319, 'pipe message', payload, eventName, targetPipeName, props.pipeName, props.listenerName)
        emit('message', {data: payload, eventName})
      } else {
        console.warn(320, 'untargeted pipe message', payload, eventName, targetPipeName, props.pipeName, props.listenerName)
      }
    }

    let sendPipeMessage
    const socketState = ref('')
    const pipeState = ref('')

    const socketTitle = computed(() => `Web Socket: ${socketState.value || 'Disconnected'}`)
    const pipeTitle = computed(() => `Stream: ${pipeState.value || 'Unavailable'}`)

    onMounted(async () => {
      const result = await usePipe({
        pipeName: props.pipeName,
        listenerName: props.listenerName,
        onEvent: onMessage,
        onSocketReady,
        onSocketClose,
        onSocketError,
        onPipeError: (err) => {
          console.log(317, err)
          emit('pipe_error', err)
        },
      })

      socketState.value = result.socketState.value
      pipeState.value = result.pipeState.value

      sendPipeMessage = result.send

      watch(result.socketState, (newVal) => {
        emit('socket_status', newVal)
        socketState.value = newVal
      }, {immediate: true})

      watch(result.pipeState, (newVal) => {
        emit('pipe_status', newVal)
        pipeState.value = newVal
      }, {immediate: true})
    })

    return {
      socketState,
      pipeState,

      socketTitle,
      pipeTitle,
    }
  }
}
</script>

<template>
  <div class="
      pipe
      w-full
  ">
    <div
        class="
          w-full
          relative
          pr-12
          pt-8
          k15:pt-0
        "
    >
      <div
          class="
            indicators
            absolute
            px-1
            py-1
            -top-1 right-1
            z-10
            text-slate-500
            flex items-start
            k15:-top-1
            k15:-right-8
          "
      >
        <div
            class="
              bg-black
              rounded-b-lg
              flex
              px-1
              py-0.5
              k15:flex-col
              k15:gap-1
              k15:rounded-bl-none
              k15:rounded-r-lg
              k15:pt-1
              k15:px-0.5
            "
        >
          <div
              :class="{
                'text-slate-500': socketState === '',
                'text-yellow-400': socketState === 'Ready',
                'text-green-500': socketState === 'Connected',
                'text-red-500': socketState === 'Disconnected',
                'bg-red-500 text-black': socketState === 'Error',
              }"
              class="socket"
              :title="socketTitle"
          >
            <IconConnection :title="socketTitle"></IconConnection>
          </div>
          <div
              :class="{
                'text-slate-500': pipeState === '',
                'text-green-500': pipeState === 'Ready',
                'text-blue-400': pipeState === 'Transmitting',
                'text-yellow-400': pipeState === 'Receiving',
                'bg-red-500 text-black': pipeState === 'Error',
              }"
              class="pipe"
              :title="pipeTitle"
          >
            <IconLANDisconnect :title="pipeTitle"></IconLANDisconnect>
          </div>
          <slot name="indicators"></slot>
        </div>
      </div>
    </div>
    <slot name="default"></slot>
  </div>
</template>

