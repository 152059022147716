<script>
import LView from "@/components/layout/LView.vue";
import IconHome from "../../icons/Home.vue";
import '../../../ccf.css'
import Pipe from "@/components/pipe/Pipe.vue";
import IconCheck from "@/components/icons/Check.vue";
import {useStore} from "vuex";
import {computed, ref, watchEffect} from "vue";
import {SYNC_ASSETS} from "@/store/operations";
import {useUserPermissions} from "@/composables/useUserPermissions";
import {useRoute, useRouter} from "vue-router";

const isInDevelopment = process.env.NODE_ENV === 'development'

let debug = {
  pipe: false
}

debug = debug && isInDevelopment

const states = {
  initial: 'Connecting...',
  streaming: 'Streaming',
  error: 'Error',
  disconnected: 'Disconnected',
  done: 'Done',
  exit: 'Exit'
}

export default {
  name: "LHome",
  components: {
    IconCheck,
    Pipe,
    IconHome,
    LView,
  },
  setup: function () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const {isLoggedIn} = useUserPermissions()

    const pipeName = ref('null')
    const progress = ref(null)

    const socketState = ref('')
    const pipeState = ref('')

    const streamTitle = ref(states.initial)

    const onMessage = (message) => { // client synchronization
      if (debug.pipe) {
        console.log(111, 'onMessage', message)
      }
      const {status, delta} = message
      streamTitle.value = status
      if (delta) store.commit(SYNC_ASSETS, delta)
    }

    const onSocketState = (state) => {
      if (debug.pipe) {
        console.log(112, 'onSocketState', state)
      }
      socketState.value = state
    }
    const onPipeState = (state) => {
      if (debug.pipe) {
        console.log(113, 'onPipeState', state)
      }
      pipeState.value = state
    }

    // session
    const sessionId = computed(() => store.state.sessionKey)

    watchEffect(() => {

      if (!isLoggedIn.value) { // show begin session dialog

        const {
          treatment_id,
          user_id,
          prolific_id,
          mturk_id
        } = route.query

        let userIdLabel
        if (mturk_id) userIdLabel = 'MTurk ID'
        else if (prolific_id) userIdLabel = 'Prolific ID'
        else userIdLabel = 'User Id'

        router.push({
          query:
              {
                modal: 'enterTokenWithUserId',
                sessionKey: treatment_id,
                userIdLabel: userIdLabel,
                userId: user_id || prolific_id || mturk_id,
              }
        })
      }
    })
    return {
      pipeName,
      progress,
      streamTitle,
      isLoggedIn,

      sessionId,

      onMessage,
      onSocketState,
      onPipeState,
    }
  }
}
</script>

<template>
  <div class="lHome ccf ">
    <LView>
      <template #default>
        <div class="flex-grow flex flex-col h-full w-full ">
          <router-view></router-view>
          <Pipe
              class="absolute top-3 right-3"
              :pipeName="pipeName"
              listenerName="createCHIV2Files"
              @message="onMessage"
              @socket_status="onSocketState"
              @pipe_status="onPipeState"
          >
            <template #indicators>
              <div
                  class="spawn text-gray-50"
                  title="0 of 0 CHI files created"
              >
                <IconCheck
                    v-if="progress === 'done'"
                ></IconCheck>
                <span
                    v-else
                    :title="streamTitle"
                >
                {{ progress }}
              </span>
              </div>
            </template>
            <template #default>
              <div v-if="isLoggedIn">
                <div class="flex flex-col items-center justify-center h-full">
                  <IconHome class="text-9xl text-gray-50"></IconHome>
                  <div class="text-4xl text-gray-50">Configure Experiment</div>
                </div>
              </div>
              <div v-else-if="sessionId">
                <div class="flex flex-col items-center justify-center h-full">
                  <IconHome class="text-9xl text-gray-50"></IconHome>
                  <div class="text-4xl text-gray-50">Session</div>
                </div>
              </div>
            </template>
          </Pipe>
        </div>
      </template>
    </LView>
  </div>
</template>
