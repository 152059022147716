<script>
import LListItemAssistant from "../layout/LListItemAssistant.vue";
import {useStore} from "vuex";
import {computed} from "vue";
import EditButton from "../Button/EditButton.vue";
import Button from "@/components/forms/Button.vue";
import StatusMessage from "@/components/status/StatusMessage.vue";
import {useRouter} from "vue-router";
import {LIST_SHARED_DOCUMENTS} from "@/store/operations/sharedDocumentOperations";
import {LOAD_CHI_VECTOR_STORE} from "../../operations";

export default {
  name: "ListItemAssistant",
  components: {
    StatusMessage,
    Button,
    EditButton,
    LListItemAssistant,
  },
  props: {
    _id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const assistant = computed(() => store.state.docs.Assistant?.[props._id])
    const avatarSrc = computed(() => assistant.value?.avatarSrc ?? require('@/assets/images/Logo_CHI.png'))
    const isPrivate = computed(() => assistant.value?._isPrivate !== undefined)

    const canEdit = computed(() => assistant.value?._permissions > 1)
    const router = useRouter()

    const onEdit = () => router.push({name: 'assistant', params: {_assistantId: props._id}})
    const onChat = () => router.push({name: 'chat', params: {_assistantId: props._id, thread_id: 'new'}})

    return {
      avatarSrc,
      assistant,
      canEdit,
      isPrivate,

      onEdit,
      onChat,
    }
  }
}
</script>

<template>
  <div class="listItemAssistant">

    <LListItemAssistant
        :imageSrc="avatarSrc"
        :isPrivate="isPrivate"
        @click="onEdit"
    >
      <template #name>{{ assistant?.name }}</template>
      <template #default>
        <p class="line-clamp-3">
          {{ assistant?.instructions }}
        </p>
      </template>
      <template #publicActions>
        <Button
            class="blackButtonSmall"
            @click.stop="onChat"
        >
          Chat
          <span class="!text-[10px]">
          -->
          </span>
        </Button>
      </template>
      <template #actions>
        <EditButton
            :assistant="assistant"
            :can-edit="canEdit"
        />
      </template>
    </LListItemAssistant>
  </div>
</template>