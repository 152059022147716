<script>
import IconPowershell from "@/components/icons/Powershell.vue";
import AlertStatus from "@/components/AlertStatus.vue";

export default {
  name: "SpawnStatus",
  components: {
    AlertStatus,
    IconPowershell
  },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <div class="spawnStatus">
    <AlertStatus
        name="Spawn"
        :message="message"
    >
      <template #icon>
        <IconPowershell></IconPowershell>
      </template>
    </AlertStatus>
  </div>
</template>

