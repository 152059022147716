import {state} from "@/store/store";
import {computed, ref, watch} from "vue";
import {useStore} from "vuex";
import {_PUT_STATUS, GET_ACCOUNT_ROLES, LOGOUT} from "../store/operations";
import {permissions} from "@/lib/permissionThresholds";

export const useUserPermissions = () => {
    const user = computed(() => state.user)
    const isLoggedIn = ref(!!state.user?._id)
    const account = computed(() => state.account);
    const store = useStore()
    const userPermission = computed(() => {
        let permission = 0
        if (user.value?.['\u00A9isDevOp']) permission = permissions.devOp
        else if (user.value?.['\u00A9isAppAdmin']) permission = permissions.manageApp
        else if (isLoggedIn.value) permission = permissions.read
        return permission
    })

    const roles = computed(() => {
        const user = state.user.value ?? {}
        const roles = []
        if (user['\u00A9isDevOp']) roles.push('devOp')
        if (user['\u00A9isAppAdmin']) roles.push('appAdmin')
        return roles
    })
    const role = computed(() => roles.value[0])
    const fullName = ref('')

    store.dispatch(GET_ACCOUNT_ROLES)
        .then((result) => {
            const isLoggedIn = !result.Error
            if (isLoggedIn) store.commit(_PUT_STATUS, {event: GET_ACCOUNT_ROLES, result})
            return result
        })
        .catch(err => {
            store.commit(_PUT_STATUS, {event: GET_ACCOUNT_ROLES, err})
        })


    watch(user, value => {
        isLoggedIn.value = !!value?._id
        fullName.value = `${state.account?._firstName} ${state.account?._lastName}`
    }, {immediate: true})

    // watch(() => state.account, value => {
    //     account.value = value
    // }, {immediate: true})

    const onLogout = () => {
        store.dispatch(LOGOUT)
    }

    return {
        account,
        fullName,
        isLoggedIn,
        onLogout,
        role,
        roles,
        user,
        userPermission,
    }
}
