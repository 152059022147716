import {
    GET_SESSION
} from "./operations";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import {handleResponse} from "@/store/actions/lib/handleResponse";
import axios from "@/plugins/axios";
import {getCCFUrl} from "@/store/lib/apiUrls";

export default {
    [GET_SESSION](context, {sessionKey, userId}) {
        const url = getCCFUrl(`getSession/${sessionKey}/${userId}`)
        return throttleBufferedAction({
            action: axios.get,
            url: url,
        })
            .then(handleResponse(context, GET_SESSION))
    },

}