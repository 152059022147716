<script>
import {useSharedDocument} from "@/composables/document/shared/useSharedDocument";
import {useSharedDocumentAttribute} from "@/composables/document/shared/useSharedDocumentAttribute";
import Markdown from "@/components/forms/Markdown.vue";

export default {
  name: "LibraryEntry",
  components: {Markdown},
  props: {
    _dataType: {
      type: String,
      required: true,
    },
    _id: {
      type: String,
      required: true
    }
  },
  setup (props) {

    const {
      chiComponent
    } = useSharedDocument({
      alias: 'chiComponent',
      filter: {
        _dataType: props._dataType,
        _id: props._id
      },
      options: {
        autoLoad: true
      }
    })
    const {source} = useSharedDocumentAttribute({
      document: chiComponent,
      attributeName: 'source'
    })
    const {documentation} = useSharedDocumentAttribute({
      document: chiComponent,
      attributeName: 'documentation'
    })
    const {defaults} = useSharedDocumentAttribute({
      document: chiComponent,
      attributeName: 'defaults'
    })

    return {
      documentation,
      source,
      defaults
    }
  }
}
</script>

<template>
<div class="LibraryEntry">
<!--
  todo:
    - views: source, documentation, defaults, live-demo, chat

-->
  <div class="w-full flex gap-2 k1:gap-4 k15:gap-6">
    <div class="documentation">
      <Markdown
          :modelValue="documentation"
      ></Markdown>
    </div>
    <div class="preview"></div>
  </div>
</div>
</template>

