import assistantActions from "./assistantActions";
import pipeActions from "./pipeActions";
import fileActions from "./fileActions";
import vectorStoreActions from "./vectorStoreActions";

export default {
    ...assistantActions,
    ...vectorStoreActions,
    ...pipeActions,
    ...fileActions,
}