<script>
import {ref} from 'vue';
import LSurvey from "./LSurvey.vue";
import {surveyData} from "./data/SurveyData";
import TextArea from "@/components/forms/TextArea.vue";

export default {
  name: "AllSurveys",
  emits: ['complete'],
  components: {
    TextArea,
    LSurvey
  },
  setup(props, {emit}) {
    const currentIndex = ref(0);
    const responses = ref([]);

    const nextQuestion = (answer) => {
      if (surveyData[currentIndex.value].options.length === 0) {
        surveyData[currentIndex.value].answer = answer;
      }
      responses.value.push({question: surveyData[currentIndex.value].question, answer});
      if (currentIndex.value < surveyData.length - 1) {
        currentIndex.value++;
      } else {
        emit('complete', responses.value);
      }
    };

    const onChange = (value) => {
      surveyData[currentIndex.value].answer = value;
    };
    return {
      currentIndex,
      surveyData,
      onChange,

      nextQuestion,
    };
  }
};

</script>

<template>
  <div class="AllSurveys">
    <LSurvey>
      <template #progress>
        <div class="flex gap-1 w-fit">
          <div
              v-for="index in surveyData.length"
              :key="index"
              :class="[
            'rounded-full h-3.5 w-3.5',
            index <= currentIndex + 1 ? 'bg-[#2D9BF0]' : 'bg-gray-300'
          ]">
          </div>
        </div>
      </template>

      <template #question>
        {{ currentIndex + 1 }}. {{ surveyData[currentIndex]?.question }}
      </template>

      <template #buttons>
        <div v-if="surveyData[currentIndex]?.options.length"
             class="flex flex-col gap-4 h8:flex-row"

        >
          <button
              v-for="(option, index) in surveyData[currentIndex]?.options"
              :key="index"
              class=" px-4 py-1.5 border-2 min-w-[80px] text-center cursor-pointer hover:bg-white hover:text-black duration-300"
              @click="nextQuestion(option)">
            {{ option }}
          </button>
        </div>
        <div
            v-else
            class="w-full text-gray-900 flex flex-col items-center gap-4"
        >
          <TextArea
              :value="surveyData[currentIndex].answer"
              class="w-96 mx-10 looksEditable"
              placeholder="Enter your feedback here..."
              @change="onChange"
          ></TextArea>
          <button
              class="px-4 py-2 mt-2 bg-blue-500 text-white rounded"
              @click="nextQuestion(surveyData[currentIndex].answer)">
            Submit
          </button>
        </div>

      </template>
    </LSurvey>
  </div>
</template>

<style scoped>
/* Scoped styles for this component */
</style>
