<script>
import LSection from "@/components/layout/LSection.vue";
import {computed, ref} from "vue";
import ImageGallery from "../gallery/ImageGallery.vue";
import {useStore} from "vuex";
import {FIND_CREA_VISIONS_SIMILAR_GALLERY_ENTRIES, LOG_CREA_VISIONS_ENTRY} from "../../operations";
import Priorities from "../Priorities.vue";
import Spinner from "../../../chi-ccf/components/Spinner.vue";
import CreaImage from "../gallery/CreaImage.vue";
import AllSurveys from "../survey/AllSurveys.vue";

export default {
  name: "Home",
  components: {
    AllSurveys,
    CreaImage,
    Spinner,
    Priorities,
    LSection,
    ImageGallery,
  },
  setup() {
    const store = useStore()
    store.state.creavisions = store.state.creavisions || {}
    const hasSubmittedPriorities = computed(() => store.state.UI.hasSubmittedPriorities)
    const showSurvey = ref(false)
    const showSpinner = ref(false)
    const showPrioritiesPrompt = computed(() => (showSpinner.value && showSurvey.value) || !hasSubmittedPriorities.value)
    const showPrioritiesInputs = computed(() => (!showSpinner.value && !showSurvey.value) && !hasSubmittedPriorities.value)

    const showPrioritySlidersInGallery = computed(() => store.state.UI.showPrioritySlidersInGallery)

    const images = computed(() => store.state.creavisions.myImages ?? [])

    const selectedSrc = ref('')
    const selectedImage = computed(() => {
      if (selectedSrc.value === '') return null
      return images.value.find(i => i.imageUrl === selectedSrc.value)
    })

    const searchQuery = ref('')

    const onUpdateSelectedImage = (src) => {
      selectedSrc.value = src || ''
    }
    const onSurveyCompleted = (results) => {

      store.dispatch(LOG_CREA_VISIONS_ENTRY, {
        action: 'august survey',
        result: JSON.stringify(results)
      })

      showSurvey.value = false
    }

    const onPriorities = (query) => {
      if (hasSubmittedPriorities.value) return
      store.state.UI.hasSubmittedPriorities = true
      showSpinner.value = true
      showSurvey.value = true

      store.dispatch(FIND_CREA_VISIONS_SIMILAR_GALLERY_ENTRIES, {
        query,
        amount: 24
      })
          .then(({data}) => {
            const {_stdout} = data
            const repo = _stdout?.[0] || {}
            store.state.creavisions.myImages = Object.values(repo || {})
            store.state.UI.showPrioritySlidersInGallery = true
          })
          .finally(() => {
            showSpinner.value = false
          })
    }
    const onFilterByPriorities = (query) => {
      store.dispatch(FIND_CREA_VISIONS_SIMILAR_GALLERY_ENTRIES, {
        query,
        amount: 48
      })
          .then(({data}) => {
            const {_stdout} = data
            const repo = _stdout?.[0] || {}
            images.value = Object.values(repo || {})
          })
    }


    return {
      hasSubmittedPriorities,
      images,
      searchQuery,
      selectedImage,
      selectedSrc,
      showSurvey,
      showPrioritiesPrompt,
      showPrioritiesInputs,
      showSpinner,
      showPrioritySlidersInGallery,
      onSurveyCompleted,
      onPriorities,
      onFilterByPriorities,
      onUpdateSelectedImage,
    }
  }
}
</script>

<template>
  <div class="
        home view
        relative
        flex flex-col flex-grow
    "
  >
    <div
        v-if="showPrioritiesPrompt || showSurvey"
        class="
          absolute
          z-30
          w-screen
          flex flex-col items-center justify-center
      "
    >
      <LSection :max-width="600">
        <div class="shadow-2xl p-8">
          <div v-if="!hasSubmittedPriorities">
            <div class="heading1 mb-4">AARHUS 6 WICKED PROBLEM Prioritization</div>
            <div class="mb-4">
              Imagine you have 100 points to dedicate to different causes. Distribute them across the Municipality’s 6 wicked
              problems:
            </div>
            <Priorities
                v-show="showPrioritiesInputs"
                @submit="onPriorities"
            ></Priorities>
          </div>
        </div>
      </LSection>
      <LSection
          v-if="showSurvey"
          class="relative"
          :max-width="600"
      >
        <AllSurveys
            @complete="onSurveyCompleted"
        />
        <div
            v-if="showSpinner || showSurvey"
            class="flex items-center gap-4"
        >
          Creating your gallery...
          <Spinner :is-visible="true"></Spinner>
        </div>
      </LSection>
    </div>
    <LSection class="px-8">
      <div class="k1:p-2 k2:p-4 flex flex-col items-center">
        <div
            v-if="hasSubmittedPriorities"
            class="text-center text-2xl font-semibold my-10"
        >
          Here is your gallery, showing the images that are the closest to your priorities.
        </div>
        <ImageGallery
            :images="images"
            :selectedSrc="selectedSrc"
            @selected="onUpdateSelectedImage"
        />
      </div>
    </LSection>
  </div>
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>

