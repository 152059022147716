<script>
import LSection from "@/components/layout/LSection.vue";
import {computed} from "vue";

export default {
  name: "LGamePage",
  components: {
    LSection
  },
  props: {
    noTitle: {
      default: false
    },
    isBlank: {
      default: false
    }
  }, setup(props) {
    const showContent = computed(() => {
      return !props.isBlank
    })
    return {
      showContent,
    }
  }

}
</script>

<template>
  <LSection :no-margin="true" class="flex-grow h-full">
    <div class="
      lGamePage
      layout
      flex-grow
      flex flex-col
      fadeInAnimation
      text-wai-900
    ">
      <div
          v-show="isBlank"
          class="
            flex-grow
            flex flex-col justify-center items-center
          "
      >
        <slot name="blankContent"></slot>
      </div>
      <div v-show="!isBlank" class=" h-full flex flex-col justify-between my-6 flex-grow">
        <div class="
                flex-grow
                flex flex-col justify-center items-center k1:gap-0
                max-w-3xl
                !mx-auto
            ">
          <div
              v-if="!noTitle"
              class="k1:text-5xl text-3xl text-center font-bold !mx-auto
              ">
            <slot name="title"></slot>
          </div>
          <div class="
                  text-xl
                  flex flex-col justify-start text-center items-center gap-1 pt-6"
               style="margin-left: auto!important; margin-right: auto!important;"
          >
            <slot></slot>
          </div>
          <div class="flex flex-grow space-x-8 mt-8   justify-center">
            <slot name="actions"></slot>
          </div>
        </div>
      </div>
    </div>
  </LSection>
</template>
