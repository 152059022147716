<script>
import ImageGallery from "../gallery/ImageGallery.vue";
import LSection from "@/components/layout/LSection.vue";
import Priorities from "../Priorities.vue";
import Spinner from "../../../chi-ccf/components/Spinner.vue";
import {FIND_CREA_VISIONS_SIMILAR_GALLERY_ENTRIES} from "../../operations";
import {useStore} from "vuex";
import {ref} from "vue";

export default {
  name: "Gallery",
  components: {
    Spinner,
    Priorities,
    LSection,
    ImageGallery
  },
  setup() {
    const store = useStore()
    const images = ref([])
    const selectedSrc = ref('')

    const onUpdateSelectedImage = (src) => {
      selectedSrc.value = src || ''
    }

    const onFilterByPriorities = (query) => {
      store.dispatch(FIND_CREA_VISIONS_SIMILAR_GALLERY_ENTRIES, {
        query,
        amount: 48
      })
          .then(({data}) => {
            const {_stdout} = data
            const repo = _stdout?.[0] || {}
            images.value = Object.values(repo || {})
          })
    }

    return {
      images,
      selectedSrc,
      onUpdateSelectedImage,
      onFilterByPriorities
    }

  }
}
</script>

<template>
  <div class="gallery view relative min-h-screen">
    <LSection :max-width="600">
      <div class="k1:p-2 k2:p-4 mt-10 flex flex-col items-center">

        <div class="text-center text-2xl font-semibold ">
          Feel free to explore the gallery of images with other priorities.
        </div>
        <Priorities
            class="py-10 px-2"
            @submit="onFilterByPriorities"
        ></Priorities>
        <ImageGallery
            :images="images"
            :selectedSrc="selectedSrc"
            @selected="onUpdateSelectedImage"
        />
      </div>
    </LSection>
  </div>
</template>

