import {getSystemDocumentUrl} from "@/store/lib/apiUrls";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import axios from "@/plugins/axios";
import {handleResponse} from "@/store/actions/lib/handleResponse";
import {
    CREATE_SYSTEM_DOCUMENT,
    DELETE_SYSTEM_DOCUMENT,
    GET_SYSTEM_DOCUMENT,
    LIST_SYSTEM_DOCUMENTS,
    PATCH_SYSTEM_DOCUMENT
} from "@/store/operations/systemDocumentOperations";

export default {
    [LIST_SYSTEM_DOCUMENTS](context,
                  {
                      _appId,
                      _dataType,
                      filter,
                      offset,
                      limit,
                      sorting,
                  }) {
        const options = {
            _appId,
            _dataType,
            offset,
            limit,
            sorting,
            filter: JSON.stringify(filter)
        }
        let url = getSystemDocumentUrl({
            _appId,
            _dataType,
            postfix: 'list'
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: options,
        })
            .then(handleResponse(context, LIST_SYSTEM_DOCUMENTS))
    },
    [CREATE_SYSTEM_DOCUMENT](context, {
        _appId,
        _dataType,
        _permissionHostId,
        attributes
    }) {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')

        const url = getSystemDocumentUrl({
            _appId,
            _dataType,
        })
        const params = {
            ...attributes,
        }

        if (_permissionHostId) params._permissionHostId = _permissionHostId

        return throttleBufferedAction({
            action: axios.post,
            url,
            params
        })
            .then(handleResponse(context, CREATE_SYSTEM_DOCUMENT))
    },
    [GET_SYSTEM_DOCUMENT](context,
                {
                    _appId,
                    _dataType,
                    _id,
                    _permissionHostId
                }) {
        const params = {}
        if (_permissionHostId) params._permissionHostId = _permissionHostId
        return throttleBufferedAction({
            action: axios.get,
            url: getSystemDocumentUrl({_appId, _dataType, postfix: _id}),
            params
        })
            .then(handleResponse(context, GET_SYSTEM_DOCUMENT))
    },
    [PATCH_SYSTEM_DOCUMENT](context,
                 {
                     _appId,
                     _dataType,
                     _id,
                     _permissionHostId,
                     attributes,
                 }) {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')
        if (!_id) throw new Error('_id is required')

        const url = getSystemDocumentUrl({
            _appId,
            _dataType,
            postfix: _id
        })
        const params = {
            attributes,
        }
        if (_permissionHostId) params._permissionHostId = _permissionHostId

        return throttleBufferedAction({
            action: axios.patch,
            url,
            params,
        })
            .then(handleResponse(context, PATCH_SYSTEM_DOCUMENT))
    },
    [DELETE_SYSTEM_DOCUMENT](context, {_appId, _dataType, _id}) {
        return throttleBufferedAction({
            action: axios.delete,
            url: getSystemDocumentUrl({
                _appId,
                _dataType,
                postfix: _id
            })
        })
            .then(handleResponse(context, DELETE_SYSTEM_DOCUMENT))
    },
}
