<script>
import {useSharedDocument} from "@/composables/document/shared/useSharedDocument";
import {computed, inject, ref, watch, watchEffect} from "vue";
import StreamItemStatusBar from "./statusBar/StreamItemStatusBar.vue";
import {useTimeTexts} from "../../../../../chi-assistants/composables/useTimeTexts";

/*
    {
      "id": "step_abc123",
      "object": "thread.run.step",
      "created_at": 1699063291,
      "run_id": "run_abc123",
      "assistant_id": "asst_abc123",
      "thread_id": "thread_abc123",
      "type": "message_creation",
      "status": "completed",
      "cancelled_at": null,
      "completed_at": 1699063291,
      "expired_at": null,
      "failed_at": null,
      "last_error": null,
      "step_details": {
        "type": "message_creation",
        "message_creation": {
          "message_id": "msg_abc123"
        }
      },
      "usage": {
        "prompt_tokens": 123,
        "completion_tokens": 456,
        "total_tokens": 579
      }
    }
  ]

 */

export default {
  name: "OpenAIThreadRunStep",
  components: {
    StreamItemStatusBar,
  },
  props: {
    id: {
      type: String,
    },
    showCall: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {

    const {step} = useSharedDocument({
      alias: 'step',
      filter: {
        _dataType: 'Step',
        _id: props.id,
      },
    })

    let details = {...step.value?.step_details}
    const toolCalls = computed(() =>
        details?.tool_calls?.map(s => {
          const f = {...s.function}
          let text = `name: ${f?.name ?? s?.type ?? '<unkown>'}`
          if (f?.arguments) {
            text += ` arguments: ${f.arguments}`
          }
          return text
        }) ?? []
    )
    const hasCall = computed(() => toolCalls.value.length > 0)

    return {
      hasCall,
      toolCalls,
    }
  }
}
</script>

<template>
  <div class="OpenAIThreadRunStep w-full bg-yellow-100">
    <div v-if="id && hasCall && showCall">
      <div
          class="w-full"
          :title="id"
      >
        <div
            class="w-full flex-grow px-1"
        >
          <em>Tool Call</em>
          <div class="w-full flex flex-col gap-1">
            <div
                class="whitespace-pre-wrap"
                v-for="call in toolCalls"
            >{{ call }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
