import {getHostUrl} from "@/lib/getHostUrl";
import {getApiUrl} from "@/store/lib/apiUrls";
import axios from "@/plugins/axios";
import {unpackPipeMessage} from "@/composables/pipe/unpackPipeMessage";
import {WEBSOCKET} from "@/store/operations";
import {state} from "@/store/store";

const debug = false

let ws = null
let lastUsedPort = null

const agents = {
    // pipeName: {onError, onMessage, onExit}
}
let reconnectInterval = 1000;
const maxReconnectInterval = 20000;
export const isWebSocketConnected = () => ws && ws.readyState === WebSocket.OPEN
const handleIncomingMessage = (data) => {
    const {
        pipeName,
        eventName,
        payload
    } = unpackPipeMessage(data)

    if (!pipeName) {
        state.status[WEBSOCKET] = {isError: true, body: 'Websocket Error: Message is missing pipeName.'}
        console.log('websocket message is missing pipename', data)
        throw new Error('Message is missing pipeName.', data)
    }

    const agent = agents[pipeName]
    if (!agent) {
        state.status[WEBSOCKET] = {
            isError: true,
            body: 'WebSocket Error: No subscribed agent found for pipeName ' + pipeName
        }
        throw new Error(`No subscribed agent found for pipe '${pipeName}'.`)
    }

    if (!eventName) {
        state.status[WEBSOCKET] = {isError: true, body: 'WebSocket Error: Missing eventName'}
        throw new Error('Message is missing eventName.', eventName)
    }

    if (debug) {
        state.status[WEBSOCKET] = {body: 'Received Websocket Message: ' + pipeName + '.' + eventName + ': ' + payload}
        console.log(82.1, 'Received:', pipeName, eventName, payload);
    }

    agent.onMessage(payload, eventName, pipeName)
}
const getWSSUrl = (options) => {
    const usingTLS = location.protocol === 'https:'
    let {_port} = options || {}
    _port = _port || lastUsedPort // on reconnect
    lastUsedPort = _port
    return getHostUrl({protocol: usingTLS ? 'wss' : 'ws', port: _port})
}
const connectToSocket = (options) => {
    const wsUrl = getWSSUrl(options)
    if (debug) {
        console.log(82.2, 'connecting to', wsUrl)
        state.status[WEBSOCKET] = {body: 'Connecting to WebSocket server at ' + wsUrl + '...'}
    }

    try {
        ws = new WebSocket(wsUrl)
    } catch (err) {
        console.warn(3234, err)
        state.status[WEBSOCKET] = {isError: true, body: err.message}
    }

    ws.onopen = () => {
        state.status[WEBSOCKET] = {isSuccess: true, body: 'Connected to WebSocket server'}
        Object.values(agents).forEach(({onOpen}) => {
            if (onOpen) onOpen()
        })
        reconnectInterval = 1000;
        if (debug) console.log(82.7, 'WebSocket open')
    }
    ws.onerror = (error) => {
        state.status[WEBSOCKET] = {isError: true, body: 'WebSocket error: ' + error.message}

        console.error(3191, 'web socket disconnected. trying to reconnect.', error)
        // throw new Error('WebSocket error:', error)
    };
    ws.onmessage = (event) => {
        const {data} = event
        if (debug) console.log(82.3, 'WebSocket message:', data)
        if (data === 'ping') return ws.send('pong')
        try {
            handleIncomingMessage(data)
            state.status[WEBSOCKET] = {isSuccess: true, body: 'Connected to WebSocket server'}
        } catch (err) {
            console.error('WebSocket message error.', err)
        }
    };
    ws.onclose = () => {
        Object.values(agents).forEach(({onClose}) => {
            if (onClose) onClose()
        })
        if (debug) console.log(82.4, 'WebSocket connection closed. Attempting to reconnect in ' + Math.round(reconnectInterval / 1000) + ' s...');
        setTimeout(connectToSocket, 1000);
        reconnectInterval = Math.min(2 * reconnectInterval, maxReconnectInterval);
    }

    return ws
}

export const terminate = (
    code = 1000,
    reason = 'done'
) =>
    ws.close(code, reason);

const connectToWebSocketServer = () =>
    new Promise((resolve) => {
        if (isWebSocketConnected()) resolve(ws)
        else {
            const url = `${getApiUrl()}/pipe/port`

            axios.get(url)
                .then(({data}) => connectToSocket(data))
                .then(ws => {
                    reconnectInterval = 1000;
                    resolve(ws)
                })
                .catch(err => {
                    console.warn(454, 'WebSocket Error:', err)
                    state.status[WEBSOCKET] = {
                        isError: true,
                        body: 'Failed to connect to webSocket. Trying again in ' + reconnectInterval / 1000 + ' seconds.'
                    }
                    setTimeout(connectToWebSocketServer, reconnectInterval)
                    reconnectInterval = Math.min(2 * reconnectInterval, maxReconnectInterval);
                })

        }
    })
export const subscribeToWebSocket = async ({
                                               pipeName,
                                               listenerName,
                                               onError,
                                               onOpen,
                                               onMessage,
                                               onClose,
                                           }) => {
    agents[pipeName] = {
        listenerName,
        onError,
        onOpen,
        onMessage,
        onClose
    }
    return await connectToWebSocketServer()
        .then(ws => ws)
        .then(ws => {
            if (debug) {
                console.log(82.5, 'Successfully connected to webSocket. listening to channel', pipeName, 'as', listenerName)
                state.status[WEBSOCKET] = {body: 'Successfully connected to webSocket. Listening to channel ' + pipeName + ' as ' + listenerName}
            }
            return ws
        })
        .catch(err => {
            console.log(429, err)
            onError(err.message)
        })
}
export const send = (message) => {
    if (!isWebSocketConnected()) {
        if (debug) console.error('cannot send message. webSocket is not connected.')
        state.status[WEBSOCKET] = {isError: true, body: 'Cannot send message. WebSocket connection is not open.'}
        return
    }
    ws.send(message);
}

