import {
    _RESET_STATUS,
    OPEN_PIPE_WITH_MESSAGE
} from "@/store/operations";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import {handleResponse} from "@/store/actions/lib/handleResponse";
import axios from "@/plugins/axios";

export default {
    [OPEN_PIPE_WITH_MESSAGE](context, {pipeName, listenerName, url, params}) {
        context.commit(_RESET_STATUS, OPEN_PIPE_WITH_MESSAGE)
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                ...params,
                pipeName,
                listenerName,
            }
        })
            .then(handleResponse(context, OPEN_PIPE_WITH_MESSAGE))
    }
}