<script>
import SideBarListOwnAssistantItem from "./SideBarListOwnAssistantItem.vue";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "SideBarListOwnAssistants",
  components: {
    SideBarListOwnAssistantItem
  },
  props: {
    _appId: {
      type: String,
      required: true
    },
    _dataType: {
      type: String,
      default: 'Assistant'
    },
  },
  setup(props) {
    const store = useStore()
    const documents = computed(() => (Object.values(store.state.docs?.[props._dataType] ?? {}) ?? [])
        .filter(doc => !doc._workshopId)
    )

    return {
      documents
    }
  }
}
</script>

<template>
  <div class="SideBarListOwnAssistants space-y-2 font-extrabold">
    <SideBarListOwnAssistantItem
        class=" "
        v-for="assistant in documents"
        :key="assistant._id"
        :_appId="_appId"
        :_dataType="_dataType"
        :_id="assistant._id"
    ></SideBarListOwnAssistantItem>
  </div>
</template>

<style scoped>

</style>