import config from "../../../config";
import {withPrefix} from "@/lib/prefixPostfix";

const {appPort, apiPath} = config

export const getBaseUrl = () => {
    const {origin, host, hostname} = location
    let backendHost = hostname
    const inProduction = !(hostname.includes('localhost') || hostname.includes('127.0.0.1'))
    if (appPort && !inProduction) backendHost += ':' + appPort
    // if (inProduction) backendHost += basePath // todo?: only for static files
    return origin.replace(host, backendHost)
}
export const getApiUrl = () => `${getBaseUrl()}${apiPath}`
export const getAssetsUrl = ({_appId, _dataType}) => {
    // /documents/:appId/:dataType
    if (!_appId) throw '_appId is required'
    if (!_dataType) throw '_dataType is required'
    if (_dataType === 'UserAccount') throw 'use dedicated generator for UserAccount urls'
    return `${getApiUrl()}/documents/${_appId}/${_dataType}`
}
export const getCCFUrl = (postfix = '') => {
    // /ccf/:postfix
    postfix = postfix || ''
    if (postfix) postfix = withPrefix(postfix, '/')
    return `${getApiUrl()}/ccf${postfix}`
}
export const getDelphiUrl = (postfix = '') => {
    // /delphiStudy/:postfix
    postfix = postfix || ''
    if (postfix) postfix = withPrefix(postfix, '/')
    return `${getApiUrl()}/delphiStudy${postfix}`
}
export const getVectorStoresUrl = (postfix = '') => {
    // /vectorStores/:postfix
    postfix = postfix || ''
    if (postfix) postfix = withPrefix(postfix, '/')
    return `${getApiUrl()}/vectorStores${postfix}`
}
export const getAssistantsUrl = (postfix = '') => {
    // /assistants/:postfix
    postfix = postfix || ''
    if (postfix) postfix = withPrefix(postfix, '/')
    return `${getApiUrl()}/assistants${postfix}`
}
export const getTeachingAssistantsUrl = (postfix = '') => {
    // /assistants/teaching/:postfix
    postfix = postfix || ''
    if (postfix) postfix = withPrefix(postfix, '/')
    return `${getApiUrl()}/assistants/teaching${postfix}`
}
export const getAgentsUrl = (postfix = '') => {
    // /agents/:postfix
    postfix = postfix || ''
    if (postfix) postfix = withPrefix(postfix, '/')
    return `${getApiUrl()}/agents${postfix}`
}

export const getUploadUrl = ({path, postfix}) => {
    // /upload/:path/:postfix?
    let url = `${getApiUrl()}/upload/${path}`
    if (postfix) url += `${withPrefix(postfix, '/')}`
    return url
}

export const getAssistantsUploadUrl = ({_appId, postfix}) => {
    // /upload/:appId/:postfix?
    if (!_appId) throw 'appId is required'
    let url = `${getApiUrl()}/upload/${_appId}`
    if (postfix) url += `${withPrefix(postfix, '/')}`
    return url
}
export const getFreeDocumentUrl = ({_appId, _dataType, postfix}) => {
    // /documents/free/:appId/:dataType
    if (!_appId) throw '_appId is required'
    if (!_dataType) throw '_dataType is required'

    postfix = postfix || ''

    if (postfix) postfix = withPrefix(postfix, '/')

    return `${getApiUrl()}/documents/free/${_appId}/${_dataType}${postfix}`}
export const getOwnedDocumentUrl = ({_appId, _dataType, postfix}) => {
    // /documents/owned/:appId/:dataType
    if (!_appId) throw '_appId is required'
    if (!_dataType) throw '_dataType is required'

    postfix = postfix || ''

    if (postfix) postfix = withPrefix(postfix, '/')

    return `${getApiUrl()}/documents/owned/${_appId}/${_dataType}${postfix}`
}
export const getSharedDocumentUrl = ({_appId, _dataType, postfix}) => {
    // /documents/shared/:appId/:dataType
    if (!_appId) throw '_appId is required'
    if (!_dataType) throw '_dataType is required'

    postfix = postfix || ''

    if (postfix) postfix = withPrefix(postfix, '/')

    return `${getApiUrl()}/documents/shared/${_appId}/${_dataType}${postfix}`}
export const getSystemDocumentUrl = ({_appId, _dataType, postfix}) => {
    // /documents/system/:appId/:dataType
    if (!_appId) throw '_appId is required'
    if (!_dataType) throw '_dataType is required'

    postfix = postfix || ''

    if (postfix) postfix = withPrefix(postfix, '/')

    return `${getApiUrl()}/documents/system/${_appId}/${_dataType}${postfix}`}
export const getAssetUrl = ({_appId, _dataType, postfix}) => {
    // /documents/:appId/:dataType/:id
    if (!_appId) throw '_appId is required'
    if (!_dataType) throw '_dataType is required'
    if (_dataType === 'UserAccount') throw 'use dedicated generator for UserAccount urls'
    if (!postfix) throw 'postfix is required (typically asset _id)'

    if (postfix) postfix = withPrefix(postfix, '/')

    return `${getAssetsUrl({_appId, _dataType})}${postfix}`
}
export const getAssetActionUrl = ({_appId, _dataType, postfix, actionName}) => {
    // /documents/:appId/:dataType/:id/:actionName
    if (!_appId) throw '_appId is required'
    if (!_dataType) throw '_dataType is required'
    if (_dataType === 'UserAccount') throw 'use dedicated generator for UserAccount urls'
    if (!postfix) throw 'postfix is required (typically asset _id)'
    if (!actionName) throw 'actionName is required'

    if (postfix) postfix = withPrefix(postfix, '/')
    if (actionName) actionName = withPrefix(actionName, '/')

    return `${getAssetsUrl({_appId, _dataType})}${postfix}${actionName}`
}

export const getUserAccountsUrl = () => {
    // /Account
    return `${getApiUrl()}/Account`
}
export const getUserAccountUrl = handle => {
    // /Account/:handle
    if (!handle) throw 'handle is required'
    return `${getUserAccountsUrl()}/${handle}`
}
export const getCreaVisionsUrl = (postfix) => {
    // /creavisions/:postfix
    postfix = postfix || ''
    if (postfix) postfix = withPrefix(postfix, '/')
    return `${getApiUrl()}/creavisions${postfix}`
}
export const getUserAccountActionUrl = (_id, actionName) => {
    // /Account/:_id/:actionName
    if (!_id) throw '_id is required'
    if (!actionName) throw 'actionName is required'
    return `${getUserAccountUrl(_id)}/${actionName}`
}
export const getUserAccountVerificationUrl = (tokenName, token) => {
    // /Account/verify/:actionName/:token
    if (!token) throw 'token is required'
    if (!tokenName) throw 'tokenName is required'

    token = withPrefix(token, '/')
    tokenName = withPrefix(tokenName, '/')

    return `${getUserAccountUrl('verify')}${tokenName}${token}`
}
export const getUserAccountConfirmationUrl = (actionName, token) => {
    // /Account/confirm/:actionName/:token
    if (!token) throw 'token is required'
    if (!actionName) throw 'actionName is required'

    token = withPrefix(token, '/')
    actionName = withPrefix(actionName, '/')

    return `${getUserAccountUrl('confirm')}${actionName}${token}`
}

export const getAssetUserActionUrl = (_dataType, _id, actionName) => `${getUserAccountUrl(_dataType)}/${_id}/${actionName}`

