<script>
import {ref, computed} from 'vue';
import Progressbar from "../Progressbar.vue";
import {useRoute} from "vue-router";
import LSection from "@/components/layout/LSection.vue";

export default {
  name: "MimeMix",
  components: {
    Progressbar,
    LSection,
  },
  setup() {
    const route = useRoute();
    const level = ref(1)
    const stepsWithIcons = [
      {label: 'Welcome', icon: 'QuestionMarkIcon'},
      {label: 'Tutorial', icon: 'TutorialIcon'},
      {label: 'Game', icon: 'PlayIcon'},
      {label: 'Feedback', icon: 'StarIcon'},
    ];

    const steps = stepsWithIcons.map(step => step.label);


    const score = ref(0);
    const step = computed(() => {
      let label = route.meta?.stepLabel ?? 'Welcome'
      return steps.indexOf(label)
    })

    const onNewScore = newScore => score.value = newScore

    return {
      level,
      step,
      stepsWithIcons,
      score,
      onNewScore
    }
  }
};
</script>

<template>
  <div class="mimeMix view">
    <LSection>
      <div class="
        w-full
        fadeInAnimation
        flex flex-col items-center
    ">
        <Progressbar
            :current="step"
            :stepsWithIcons="stepsWithIcons"
        />
      </div>
      <div class="flex-grow flex flex-col ">
        <router-view :score="score" @score="onNewScore"
        ></router-view>
      </div>
    </LSection>
  </div>
</template>

<style scoped>

</style>