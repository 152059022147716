<script>
import IconLANDisconnect from "@/components/icons/LANDisconnect.vue";
import AlertError from "@/components/AlertError.vue";

export default {
  name: "PipeError",
  components: {
    AlertError,
    IconLANDisconnect
  },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <div class="pipeError">
    <AlertError
        name="Pipe Error"
        :message="message"
    >
      <template #icon>
        <IconLANDisconnect></IconLANDisconnect>
      </template>
    </AlertError>
  </div>
</template>

