// sync with file server/router/documents/lib/expandMatchFilterExpression.js
import {isRef} from "vue";

const filterAttributes = [
    '_none',
    '_any',
    '_contains'
]
const ingestSorting = (sorting) => {
    // todo: support multiple sort attributes
    sorting = sorting || []
    const [attributeName, direction] = (sorting[0] ?? '')
        .split(',')
        .map(e => e.trim())
    if (!attributeName) return {}
    return {attributeName, direction}
}

const rejectExcludedAttributes = filter => {
    const excludingAttributes = filter._none?._attributes || []
    return (doc) =>
        excludingAttributes.reduce((pass, attribute) => {
            return pass && doc[attribute] === undefined
        }, true)
}

const requireExplicitAttribute = filter => {
    const filteredAttributes = Object.keys(filter)
        .filter(name =>
            !filterAttributes.includes(name)
        )
    return (doc) =>
        filteredAttributes.reduce((pass, attribute) => {
            const value = filter[attribute]
            if (Array.isArray(value)) {
                pass = pass && value.includes(doc[attribute])
            } else {
                pass = pass && doc[attribute] == value // wanted ambiguity
            }
            return pass
        }, true)
}

const requireContainedText = filter => {
    const {value, _attributes} = filter?._contains ?? {}
    return (doc) =>
        !_attributes?.reduce((fail, attribute) => {
            return fail && !doc[attribute].includes(value) // !( && !) instead of || allowing to default to true
        }, true) // default to true if no _attributes

}
const requireAnyEntries = filter => {
    const anyEntries = filter._any || [] // [{attribute, values: [...values]}, ...]
    return (doc) =>
        anyEntries.reduce((pass, {attribute, value}) => {
            if (Array.isArray(value)) {
                pass = pass && value.includes(doc[attribute])
            } else {
                pass = pass && doc[attribute] == value // wanted ambiguity
            }
            return pass
        }, true)
}

const sort_ascending = (name, direction) =>
    (a, b) => {
        const isGreater =
            a[name] !== undefined && a[name] !== null &&
            b[name] !== undefined && b[name] !== null &&
            a[name] > b[name]
        return (isGreater && direction === 'asc') ? 1 : -1
    }

export const filterDocuments = (listRef, repo, filterRef, sortingRef) => {
    const sorting = isRef(sortingRef) ? sortingRef.value : sortingRef
    const filter = isRef(filterRef) ? filterRef.value : filterRef

    let docs = Object.values(repo ?? {})
        .filter(rejectExcludedAttributes(filter))

    let docs2 = docs
        .filter(requireExplicitAttribute(filter))

    let docs3 = docs2
        .filter(requireContainedText(filter))

    let docs4 = docs3
        .filter(requireAnyEntries(filter))

    const {attributeName, direction} = ingestSorting(sorting)
    if (attributeName) {
        docs3
            .sort(sort_ascending(attributeName, direction))
    }

    listRef.value = docs4
}
