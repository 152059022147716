<script>
export default {
  name: 'BurgerMenu',
}
</script>

<template>
  <div class="burgerMenu rounded-md">
    <button
        class="p-1 rounded-md border overflow-hidden border-gray-300 hover:!bg-white z-40"
    >
      <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
           fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h14"/>
      </svg>
    </button>
  </div>
</template>

<style scoped>

</style>