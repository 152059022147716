<script>
import Button from "@/components/forms/Button.vue";
import LSection from "@/components/layout/LSection.vue";
import TextInput from "@/components/forms/TextInput.vue";
import StatusMessage from "@/components/status/StatusMessage.vue";
import {REQUEST_PASSWORD_RESET} from "@/store/operations";
import {reactive, ref} from "vue";
import {useStore} from "vuex";
import LFieldError from "@/components/layout/LFieldError.vue";
import {useStatusMessage} from "@/composables/useStatusMessage";
import {useRoute} from "vue-router";
import {useEmailField} from "@/composables/form/useEmailField";
import {normalizeRedirect} from "@/lib/normalizeRedirect";

export default {
  name: "ForgotPassword",
  components: {
    Button,
    LFieldError,
    StatusMessage,
    LSection,
    TextInput,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const form = reactive({
      email: '',
    })
    const isSent = ref(false)
    const {value, error, isValid, revertValue} = useEmailField('email', form)

    const {setMessage, deleteMessage} = useStatusMessage(REQUEST_PASSWORD_RESET)

    const onUpdate = newValue => {
      form.email = newValue
    }
    const redirect = ref(null)

    const onRequestPasswordReset = () => {
      if (!isValid.value || isSent.value) return;

      deleteMessage()

      store.dispatch(REQUEST_PASSWORD_RESET, {
        _appId: 'app',
        _email: value.value,
        _redirectTo: route.query.redirect ?? '',
      })
          .then(response => {
            redirect.value = normalizeRedirect(route.query.redirect)
            const {Error, _status} = response.data
            if (Error) throw Error
            console.log(32, _status)
            setMessage({isSuccess: true, body: _status})
            return response
          })
          .catch(err => {
            setMessage({isError: true, body: err.message})
          })

      revertValue()
      isSent.value = true
    }

    return {
      error,
      form,
      isSent,
      isValid,
      onRequestPasswordReset,
      onUpdate,
      value,
    }
  }
}
</script>
<template>
  <div class="
      forgotPassword
      view flex-grow
      w-full
      flex items-center justify-center
  ">
    <LSection>
      <div class="w-full flex flex-col items-center">
        <form
            @submit.prevent=""
            class="form"
        >
          <h1 class="heading1">
            Reset password
            <hr class="gradientLine"/>
          </h1>
          <StatusMessage
              operation="REQUEST_PASSWORD_RESET"
              :isVerbose="true"
              :is-persistent="true"
          ></StatusMessage>
          <div class="flex flex-wrap">
            <div class="w-full mb-6">
              <label for="email">
                Account email address
              </label>
              <TextInput
                  name="email"
                  :value="value"
                  placeholder="Email address of your account"
                  @change="onUpdate"
                  @input="onUpdate"
              ></TextInput>
              <LFieldError :message="error" class="w-full"></LFieldError>
            </div>
            <div class="w-full flex flex-column gap-4">
              <Button
                  :isPrimary="isValid"
                  :isDisabled="!isValid || isSent"
                  @click="onRequestPasswordReset"
              >
                Reset password
              </Button>
            </div>
          </div>
        </form>
      </div>
    </LSection>
  </div>
</template>

<style scoped></style>
