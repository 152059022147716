import {
    INIT_STUDY_SUITE,
    INIT_LOCAL_SUITE,
    START_GAME,
    START_GAME_ROUND,
    LOG_USER_PROMPT, LOG_USER_RESPONSE, END_GAME, GENERATE_DATA_FILES, LIST_DATA_FILES
} from "./operations";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import {getDelphiUrl} from "@/store/lib/apiUrls";
import {handleResponse} from "@/store/actions/lib/handleResponse";
import axios from "@/plugins/axios";

export default {
    [INIT_STUDY_SUITE](context, {_participantId, parentOrigin, resetParticipantForTesting}) { // do not touch before 2050
        const url = getDelphiUrl(`initStudySuite/${_participantId}`)
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                parentOrigin,
                resetParticipantForTesting
            }
        })
            .then(handleResponse(context, INIT_STUDY_SUITE))
    },
    [INIT_LOCAL_SUITE](context, {_participantId}) {
        const url = getDelphiUrl(`initLocalSuite/${_participantId}`)
        return throttleBufferedAction({
            action: axios.get,
            url: url,
            params: {
                _participantId
            }
        })
            .then(handleResponse(context, INIT_LOCAL_SUITE))
    },
    [START_GAME](context, {_suiteId, gameName, _participantId, attributes}) {
        const url = getDelphiUrl(`${_suiteId}/startGame/${gameName}/${_participantId}`)
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                _suiteId,
                gameName,
                _participantId,
                attributes
            }
        })
            .then(response => {
                const resumables = context.state.delphi._resumableGames
                resumables.splice(resumables.indexOf(gameName), 1) // make game non-resumable
                console.log(gameName, resumables)
                return handleResponse(context, START_GAME)(response)
            })
    },
    [START_GAME_ROUND](context, {_suiteId, _gameId, _participantId, attributes}) {
        const url = getDelphiUrl(`${_suiteId}/${_gameId}/startRound/${_participantId}`)
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                _gameId,
                _participantId,
                attributes
            }
        })
            .then(handleResponse(context, START_GAME_ROUND))
    },
    [LOG_USER_PROMPT](context, {_suiteId, _gameId, _participantId, attributes}) {
        const url = getDelphiUrl(`${_suiteId}/${_gameId}/prompt/${_participantId}`)
        return axios.post(url, {
                _gameId,
                _participantId,
                attributes
        })
            .then(handleResponse(context, LOG_USER_PROMPT))
    },
    [LOG_USER_RESPONSE](context, {_suiteId, _gameId, _promptId, _participantId, attributes}) {
        const url = getDelphiUrl(`${_suiteId}/${_gameId}/response/${_participantId}/${_promptId}`)
        return axios.post(url, {
                _gameId,
                _participantId,
                _promptId,
                attributes
        })
            .then(handleResponse(context, LOG_USER_RESPONSE))
    },
    [END_GAME](context, {_suiteId, _gameId, attributes}) {
        const url = getDelphiUrl(`${_suiteId}/${_gameId}/endGame`)
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                _gameId,
                attributes,
            }
        })
            .then(handleResponse(context, END_GAME))
    },
    [LIST_DATA_FILES](context) {
        const url = getDelphiUrl(`dataFiles`)
        return throttleBufferedAction({
            action: axios.get,
            url
        })
            .then(handleResponse(context, LIST_DATA_FILES))
    },
    [GENERATE_DATA_FILES](context) {
        const url = getDelphiUrl(`generateDataFiles`)
        return throttleBufferedAction({
            action: axios.post,
            url
        })
            .then(handleResponse(context, GENERATE_DATA_FILES))
    }
}