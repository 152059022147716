<script>
import {useSharedDocument} from "@/composables/document/shared/useSharedDocument";
import {computed, inject, isRef, ref, watch} from "vue";
import {isString} from "@/lib/getVariableType";
import {useUserPermissions} from "@/composables/useUserPermissions";
import IconCogOutline from "@/components/icons/CogOutline.vue";
import LThreadMessage from "../../../../../chi-assistants/components/layout/LThreadMessage.vue";
import OpenAIMarkdown from "../../../../../chi-assistants/components/Assistant/Markdown/OpenAIMarkdown.vue";
import {useStore} from "vuex";
import {LOAD_CHI_ASSISTANT} from "../../../../../chi-assistants/operations";

/*
  {
    "id": "msg_abc123",
    "object": "thread.message",
    "created_at": 1713226573,
    "assistant_id": null,
    "thread_id": "thread_abc123",
    "run_id": null,
    "role": "user",
    "content": [
      {
        "type": "text",
        "text": {
          "value": "How does AI work? Explain it in simple terms.",
          "annotations": [
            {
              "type": "file_citation",
              "text": "【27:2†source】",
              "start_index":602,
              "end_index":615,
              "file_citation": {
                "file_id": "file_abc123"
              }
            }
          ]
        }
      }
    ],
    "attachments": [],
    "metadata": {}
  }
 */


const getMessageAuthor = (message) => {
  const {name = 'Anonymous'} = message?.metadata ?? {}
  let author = name.substring(name.indexOf('_') + 1)
  return author
}

export default {
  name: "OpenAIThreadMessage",
  components: {
    OpenAIMarkdown,
    LThreadMessage,
    IconCogOutline
  },
  props: {
    _assistantId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dayjs = inject('DAYJS')
    const store = useStore()
    const {account} = useUserPermissions()

    const {assistant} = useSharedDocument({
      alias: 'assistant',
      operations: {
        getSharedDocument: LOAD_CHI_ASSISTANT
      },
      filter: {
        _appId: 'assistants',
        _dataType: 'Assistant',
        _id: props._assistantId,
      },
      options: {
        autoLoad: true,
      }
    })
    const {message} = useSharedDocument({
      alias: 'message',
      filter: {
        _appId: 'assistants',
        _dataType: 'Message',
        _id: props.id,
      },
      options: {
        decode: value => {
          const {
            content,
            metadata
          } = value
          if (isString(content) && content.length) {
            try {
              value.content = JSON.parse(content)
            } catch (err) {
              console.log(341, err, content, value)
            }
          }
          if (isString(metadata) && metadata.length) {
            try {
              value.metadata = JSON.parse(metadata)
            } catch (err) {
              console.log(348, err, metadata, value)
            }
          }
          return value
        }
      }
    })

    const isSelf = computed(() => account.value && message.value?.metadata?.name?.split('_')?.[0] === account.value?._id)
    const isSystemMessage = computed(() => message.value?.metadata?.name === 'CHI_CHI')
    const timeSince = computed(() => {
      let date = ''
      const timestamp = message.value?.created_at
      if (timestamp) {
        date = dayjs.unix(timestamp).fromNow()
      }
      return date
    })
    const role = computed(() => message.value?.role ?? '')
    let name = computed(() => {
      let name
      if (message.value?.role === 'user') {
        name = getMessageAuthor(message.value)
        if (isSelf.value) name = 'You'
      } else {
        name = assistant.value?.name
      }
      return name
    })

    const fileReferences = ref({})
    const selectedReference = ref('')
    const html = ref('')

    const onDeselect = function () {
      selectedReference.value = ''
    }

    const annotationFileName = computed(() => {
      if (!selectedReference.value) return ''
      return store.state.docs.File?.[selectedReference.value.file_id]?.filename
    })

    const getFileReferences = annotations => {
      const references = {}
      annotations.map(a => {
        const {text, file_citation} = a
        if (file_citation) {
          const {file_id} = file_citation
          references[file_id] = references[file_id] ?? {texts: []}
          const reference = references[file_id]
          reference.id = file_id
          reference.texts.push(text)
          reference.filename = store.state.docs.File?.[file_citation.file_id]?.filename ?? ''
        }
      })
      return references
    }

    const augmentFileReferences = (value, annotations) => {
      let html = value ?? ''
      if (annotations?.length) {
        const references = getFileReferences(annotations)
        const replacedAliases = []
        html = Object.values(references)
            .reduce((html, {texts, id, filename}) => {
              for (let text of texts) {
                if (!replacedAliases.includes(text)) {
                  replacedAliases.push(text)
                  const textPattern = text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
                  const safeTextRegex = new RegExp(textPattern, 'g');
                  // remove duplicate references :(
                  html = html
                      .replace(textPattern + textPattern, textPattern)
                  // render reference as span with file id title
                  html = html
                      .replace(safeTextRegex, `<span class="openAIFileReference" data-id="${id}" data-text="${text}">${filename || id}</span>`);
                }
              }
              return html
            }, html);
      }
      return html
    }

    watch(message, newValue => {
      if (!newValue) return

      const {content} = newValue
      const {text} = content?.[0] ?? {}
      const {annotations, value} = text ?? {}

      html.value = augmentFileReferences(value, annotations)
    }, {
      immediate: true,
      deep: true
    })

    return {
      isSelf,
      isSystemMessage,
      name,
      role,
      html,
      timeSince,
      fileReferences,
      selectedReference,
      annotationFileName,

      onDeselect,

      message
    }
  }
}
</script>

<template>
  <div class="OpenAIThreadMessage">
    <div
        v-if="isSystemMessage"
        class="systemMessage w-full flex items-center justify-center gap-4 my-4 p-4 bg-amber-50"
    >
      <div class="icon">
        <IconCogOutline></IconCogOutline>
      </div>
      <div class="body">
        <OpenAIMarkdown :modelValue="html"></OpenAIMarkdown>
      </div>
    </div>
    <!--    uses vue directive openAIFileReference -->
    <LThreadMessage
        v-else
        :invert="role === 'user'"
    >
      <template #avatar></template>
      <template #time>{{ timeSince }}</template>
      <template #name>{{ name }}</template>
      <template #text>
        <OpenAIMarkdown :modelValue="html"></OpenAIMarkdown>
      </template>
    </LThreadMessage>
  </div>
</template>

<style scoped>

</style>